import React, { useEffect, useState } from 'react';
import styles from './Service.module.css';

const Service = () => {
    return (
        <>
            <p className={styles.text}>
                위즈덤센터 이용약관<br/>
                회원 약관 <br/> <br/>
                제1조 (목적) <br/>
                이 약관은 전기통신사업법(령) 및 정보통신망 이용촉진 등에 관한 법령에 의하여 주식회사 위즈덤센터(이하 “센터”라 한다)가 제공하는 모든(www.wisdomlsi.com)서비스 (이하 “서비스”라 한다)의 이용조건 및 절차, 이용자와 센터의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.  <br/> <br/>
                제2조 (약관의 효력 및 변경)<br/>
                센터는 귀하가 본 약관 내용에 동의하는 것을 조건으로 귀하에게 서비스를 제공할 것이며, 귀하가 본 약관의 내용에 동의하는 경우, 센터의 서비스 제공 행위 및 귀하의 서비스 사용 행위에는 본 약관이 우선적으로 적용될 것입니다. <br/>
                센터는 본 약관의 개정 시에는 개정 약관 적용일 7일 전(약관의 변경이 소비자에게 불리할 경우에는 30일 전)부터 공지하여 이용자가 확인 하도록 합니다. 이용자가 변경된 약관에 동의하지 않을 경우 이용자는 본인의 회원등록을 취소(회원탈퇴)할 수 있으며, 계속 사용의 경우는 약관 변경에 대한 동의로 간주됩니다. 변경된 약관은 사이트 내 게시함과 동시에 그 효력이 발생됩니다. <br/> <br/>
                제3조 (약관 외 준칙) <br/>
                본 약관은 센터가 제공하는 서비스에 관한 이용규정 및 별도 약관과 함께 적용됩니다. <br/>
                본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신 윤리위원회 심의규정, 정보통신 윤리강령, 프로그램 보호법 및 기타관련 법령의 규정에 의합니다. <br/> <br/>
                제4조 (개인정보의 보호 및 사용) <br/>
                센터는 관계법령이 정하는 바에 따라 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관련법령 및 센터의 개인정보보호정책이 적용됩니다. <br/>
                센터는 다음과 같은 경우에 법에서 허용하는 범위 내에서 이용자의 성명, 전자우편주소 등 이용자의 개인정보를 제3자에게 제공할 수 있습니다. <br/>
                ①이용자가 서비스를 이용함에 있어 관련 법률을 위반하여 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받는 경우 
                ②이용자의 법률위반, 본 서비스 약관위반을 포함하여 부정행위 확인 등의 정보보호업무를 위해 필요한 경우 <br/>
                ③기타 법률에 의해 요구되는 경우 <br/>
                센터의 공식 사이트 이외의 웹 등에서 링크된 사이트에서는 센터의 개인정보 보호정책이 적용되지 않습니다. 또한 센터는 이용자의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다. <br/><br/> 
                제5조 (용어의 정의)<br/>
                본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                - 이용자: 회원 및 회원이 아니면서 센터가 제공하는 서비스를 받는 자. <br/>
                - 가  입: 센터가 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료 시키는 행위<br/>
                - 회  원: 센터 사이트에 접속하여 개인 정보를 제공하여 회원 등록을 한 자로서, 센터와 서비스 이용계약을 체결한 자<br/>
                - 임시 회원: 센터 사이트에 접속하여 이메일 주소와 핸드폰 번호만의 개인 정보를 제공하고 회원 인증을 하지 않은 상태에서 서비스를 이용하는 자.<br/>
                - ID: 회원식별과 회원의 서비스 이용을 위하여 회원이 신청하고 센터가 승인하는 문자 및 숫자의 조합. 임시 회원은 이메일 주소를 ID로 사용한다.<br/>
                - 비밀번호: 이용자와 회원ID가 일치하는지를 확인하고 통신상의 자신의 비밀보호를 위하여 이용자 자신이 선정한 문자와 숫자의 조합<br/>
                - 운영자(관리자): 서비스의 전반적인 관리와 원활한 운영을 위하여 센터에서 선정한 사람 <br/>
                - 해지: 센터 또는 회원이 서비스 개통 후 이용계약을 해약 (탈퇴) 하는 것 <br/>
                본 약관에서 정의하지 않은 용어는 개별서비스에 대한 별도 약관 및 이용규정에서 정의합니다.<br/><br/> 
                제6조 (이용 계약의 성립 및 가입 조건)<br/>
                "위의 이용약관에 동의하십니까?" 라는 이용 신청시의 물음에 고객이 "동의" 버튼을 누르면 약관에 동의하는 것으로 간주됩니다. <br/>
                이용 계약은 고객의 이용 신청에 대하여 센터가 승낙함으로써 성립합니다. <br/>
                이용 신청은 서비스의 회원가입 화면에서 이용자가 다음 사항을 가입신청 양식에 기록하는 방식으로 행합니다.<br/>
                ① ID<br/>
                ② 이름 <br/>
                ③ 성별<br/>
                ④ 생년월일<br/>
                ⑤ 휴대전화번호<br/>
                ⑥ 거주지역<br/>
                ⑦ 기타 센터가 필요하다고 인정하는 사항 <br/><br/>
                제7조 (이용신청에 대한 승낙의 제한)<br/>
                센터는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.<br/>
                ① 기술상 서비스 제공이 불가능한 경우<br/>
                ② 회원 가입시 성명이나 이메일를 허위로 작성하여 신청하는 경우 <br/>
                ③ 이용 계약 신청서의 내용을 누락하거나 오기하여 신청하는 경우<br/>
                ④ 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우 <br/>
                ⑤ 다른 사람의 센터 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 한 경우 <br/>
                ⑥ 고객의 귀책사유에 의하여 회원 자격을 상실한 적이 있는 경우 다만, 동 자격 상실 이후, 센터의 회원 재가입 승낙을 받은 경우는 예외 <br/>
                ⑦ 전기통신기본법, 전기통신사업법, 정보통신 윤리위원회 심의규정, 정보통신 윤리강령, 프로그램 보호법 및 기타관련 법령과 본 약관이 금지하는 행위를 하는 경우 <br/>
                ⑧ 기타 센터가 정한 이용신청 요건이 만족되지 않았을 경우 <br/>
                회원의 자격에 따라 서비스 이용의 일부가 제한될 수 있습니다. <br/><br/> 
                제8조 (계약 사항의 변경)<br/>
                회원은 가입신청 시 기재한 사항이 변경되었을 경우 센터가 정한 별도의 이용 방법으로 정해진 양식 및 방법에 의하여 수정하여야 합니다. <br/>
                제9조 (서비스의 이용 개시) <br/>
                센터는 회원의 이용 신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.<br/>
                센터의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다. <br/><br/> 
                제 10조 (서비스의 이용시간)<br/>
                서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 센터의 업무상이나 기술상의 이유로 서비스가 일시 중지될 수 있습니다. 이러한 경우 센터는 사전 또는 사후에 이를 공지합니다. <br/>
                센터는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다. <br/><br/> 
                제 11조 (서비스의 변경 및 중지)<br/>
                센터는 변경될 서비스의 내용 및 제공일자를 제19조에서 정한 방법으로 회원에게 통지하고 서비스를 변경하여 제공할 수 있습니다. <br/>
                센터는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있으며 제 18조에서 정한 방법으로 회원에게 통지할 수 있습니다.<br/>
                ① 서비스용 설비의 보수 등 공사로 인한 부득이한 경우 <br/>
                ② 회원이 센터의 영업활동을 방해하는 경우 <br/>
                ③ 정전, 제반 설비의 장애 또는 이용 량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 <br/>
                ④ 서비스 제공업자와의 계약 종료 등과 같은 센터의 제반 사정으로 서비스를 유지할 수 없는 경우 <br/>
                ⑤ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우 <br/>
                센터는 만 14세 미만 회원, 청소년보호법상의 보호 대상인 회원, 외국인 회원, 법인 회원에 대하여 일정한 서비스의 제공을 제한할 수 있습니다. <br/><br/>
                제 12 조 (정보의 제공 및 광고의 게재)<br/>
                센터는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재할 수 있으며 회원의 사전선택(동의)에 따라 매체별로(e-mail 및 서신우편, SMS 등) 정보를 제공할 수 있습니다. 회원은 원치 않을 경우 정보수신 거부를 할 수 있습니다. <br/>
                센터는 서비스의 운영과 관련하여 홈페이지, 서비스 화면 등에 광고를 게재할 수 있으며 회원의 사전선택(동의)에 따라 매체별 (e-mail 및 서신우편, SMS 등) 광고를 제공 할 수 있습니다. 회원은 원치 않을 경우 정보수신 거부를 할 수 있습니다. <br/>
                고급 정보 및 서비스, 유료 콘텐츠에 한해서는 별도의 신청을 한 회원에 대하여 유료로 제공할 수 있습니다. <br/>
                회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 센터는 어떠한 책임도 지지 않습니다. <br/><br/>
                제 13조 (게시물의 저작권)<br/>
                회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 권리와 책임은 각 회원에게 있으며 센터는 그 정보관리를 위해 노력합니다. 센터는 서비스 내에 이를 게시할 수 있는 권리를 갖습니다. <br/>
                센터는 게시한 회원의 동의 없이 게시물을 다른 목적으로 사용할 수 없습니다. 단, 센터의 합병, 영업양도, 센터가 운영하는 사이트간의 통합 등의 사유로 원래의 게시물의 내용을 변경하지 않고 게시물의 게시 위치를 변경할 수는 있습니다. <br/>
                회원은 서비스를 이용하여 얻은 정보를 센터의 사전 승낙 없이 임의 가공, 판매, 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다. 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다. <br/>
                게시물은 게시자의 책임 하에 게시된 것으로서 센터는 그 게시물의 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임이 일체 없으며, 관련 법에서 특정한 경우 이외에 센터는 정기적인 모니터링을 통해 이를 검사하여야 할 의무를 부담하지 않습니다. <br/>
                센터는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않습니다. <br/>
                센터는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램 저작권 등을 침해하더라도 이에 대한 민, 형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로 센터가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 센터의 면책을 위하여 노력하여야 하며, 센터가 면책되지 못한 경우 회원은 그로 인해 센터에 발생한 모든 손해를 부담하여야 합니다. <br/>
                센터는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각종 게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다. <br/>
                센터는 필요에 따라 회원이 게시한 게시물을 사전 통지 없이 편집, 이동할 수 있습니다. <br/>
                센터는 회원이 해지하거나 적법한 사유로 해지 된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다. <br/>
                센터가 작성한 저작물에 대한 저작권은 센터에 귀속합니다. <br/><br/>
                제14조 (서비스의 제공 및 변경)<br/>
                센터는 이용자에게 아래와 같은 서비스를 제공합니다.<br/>
                - 위즈덤센터 평생교육원 모든 Check List 서비스<br/>
                - 그룹/회원 관리 서비스(그룹 생성자 및 코치 이용)<br/>
                - 검사관련 서비스<br/>
                - 마이페이지 서비스<br/>
                - 기타 센터가 자체 개발하거나 다른 센터와의 협력계약 등을 통해 회원들에게 제공할 일체의 서비스 <br/>
                센터는 그 변경될 서비스의 내용 및 제공일자를 제11조 제1항에서 정한 방법으로 이용자에게 통지하고, 제1항에 정한 서비스를 변경하여 제공할 수 있습니다.<br/><br/>
                제 15조 (게시판 서비스 이용관련)<br/>
                회원은 게시물 쓰기 및 읽기, 저장 등에 대하여 자유로운 이용이 가능합니다. 정상이용자가 아닌 사용자에 대해서는 게시판의 일부 또는 전부기능을 제한할 수 있습니다. 또한 게시판은 그 성격에 따라 일부 기능이 제한 받을 수도 있습니다. <br/>
                센터는 회원이 게시하거나 등록하는 서비스 내의 내용물이 다음 사항에 해당하는 경우 사전 통지 없이 해당 내용물을 삭제할 수 있고, 해당 이용자의 회원 자격을 제한, 정지 또는 상실시킬 수 있습니다.<br/>
                게시판 게시물 이용제한<br/>
                ① 현행법과 공공질서, 미풍양속을 저해하는 경우<br/>
                - 국가이념과 국가의 존립을 훼손하는 내용과 국가의 정상적인 활동을 저해할 우려가 있는 내용인 경우<br/>
                - 특정인 또는 단체를 비방, 중상 모략하여 명예를 손상시키는 내용인 경우<br/>
                - 범죄적 행위에 결부된다고 인정되는 내용일 경우<br/>
                - 심한 욕설과 비어, 속어를 담은 내용인 경우<br/>
                - 건전한 풍속과 사회질서를 부정하거나 조롱하는 내용인 경우<br/>
                - 피라미드식, 추천식 가입을 권유하는 돈벌이 내용인 경우<br/>
                - 회원이 자사의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우 <br/>
                ② 게시판 특성상 제한되는 내용<br/>
                - 게시물의 내용이 게시판 성격과 맞지 않거나 동일한 게시물을 여러 곳에 등록한 경우<br/>
                - 무의미한 내용, 성의 없이 올려진 창작물로 판단되는 경우<br/>
                - 특정 이용자/개인에 대한 비난, 일방적인 비판의 내용인 경우<br/>
                - 자신이 아닌 타인의 ID로 게재된 내용물인 경우<br/>
                - 토론형태와 유사하나 그 전개가 싸움/비방이 주된 내용일 경우 <br/>
                ③ 저작권에 위반되는 내용<br/>
                - 센터의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우 <br/>
                - 상용 프로그램, 크랙 파일 등의 등록과 게재, 배포를 안내하는 내용인 경우<br/>
                - 정품임을 확인할 수 없는 소프트웨어, 영상 저작물의 유통을 담은 내용인 경우<br/>
                ④ 온라인 판매, 구매와 관련한 게재 금지 내용<br/>
                - 불법 게임/SW/음반/영상의 복사 판매의 내용인 경우<br/>
                - 관련업체 또는 개인의 신고로 해당 업체 또는 개인에 손해를 입힌 사실이 확인된 경우<br/>
                - 일반 상품 판매업체에서 올린 광고성 내용인 경우<br/>
                - 판매자의 연락처가 없거나 불분명하여 신뢰할 수 없는 경우 <br/>
                ⑤ 구인과 관련한 게재 금지 내용<br/>
                - 관련업체 또는 회원의 신고로 손해를 입혔거나 신용할 수 없는 내용인 경우<br/>
                - 다단계 판매원 모집의 내용인 경우 <br/><br/>
                제16조 (센터의 의무)<br/>
                센터는 서비스 제공과 관련하여 알고 있는 회원의 신상정보를 철저히 보안유지하며, 양질의 서비스를 유지하거나 개선하는 데에만 사용하고, 본인의 승낙 없이 타 기관 및 제3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 정보통신윤리위원회의 요청이 있는 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다. <br/>
                센터는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있고, 이를 위하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의해 서비스 이용이 변경되는 것은 회원의 책임입니다. <br/>
                센터는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 e-mail 등을 통하여 동 회원에게 통지합니다. <br/>
                센터는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다. <br/>
                센터는 서비스 향상을 위하여 노력할 의무가 있습니다. <br/><br/>
                제17조 (회원의 의무)<br/>
                회원은 본 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다. <br/>
                회원은 센터 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.<br/>
                ① 회원가입 신청 또는 변경시 허위내용을 등록하는 행위 <br/>
                ② 다른 회원의 ID 및 비밀번호를 도용하거나 부정 사용하는 행위 <br/>
                ③ 센터의 서비스 정보를 이용하여 얻은 정보를 센터의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위 <br/>
                ④ 같은 사용자가 다른 ID로 이중 등록을 하는 행위 <br/>
                ⑤ 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위 <br/>
                ⑥ 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위 <br/>
                ⑦ 타인의 명예를 손상시키거나 불이익을 주는 행위 <br/>
                ⑧ 센터, 기타 제3자의 인격권, 지적재산권, 저작권 또는 초상권을 침해하거나 업무를 방해하는 행위 <br/>
                ⑨ 센터의 직원이나 서비스의 운영자를 가장하거나 사칭하여, 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위 <br/>
                ⑩ 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위 <br/>
                ⑪ 센터 사이트에 게시된 정보를 변경하는 행위 <br/>
                ⑫ 해킹행위 또는 컴퓨터바이러스의 유포행위 <br/>
                ⑬ 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위 <br/>
                ⑭ 기타 본 약관을 비롯, 전기 통신법 제53조와 전기통신사업법 시행령 16조(불온통신), 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 센터가 통지하는 사항 등을 준수하여야 하며, 기타 센터의 업무에 방해되는 행위를 하여서는 아니 됩니다. <br/>
                회원은 센터에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 센터가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 센터에 대하여 손해배상 의무를 집니다. <br/>
                회원은 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 완전한 정보(이하 "등록정보")를 제공하여야 합니다. <br/>
                회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 합니다. 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우, 기타 회원이 본 조 제1항에 명시된 행위를 한 경우에 센터는 회원의 서비스 이용제한 또는 회원자격 박탈 처분을 내릴 수 있습니다. 이 경우 이용자인 회원에게 이용제한 또는 회원자격 박탈 전에 이를 통지하고, 통지를 받은 날로부터 30일 이내에 소명할 기회를 부여합니다. <br/><br/>
                제18조 회원 ID(고유번호)와 Password(비밀번호) 관리에 대한 의무와 책임<br/>
                센터는 사이트 내에서 일부 서비스 신청 시 이용요금을 부과할 수 있으므로, 회원은 회원 ID(고유번호) 및Password(비밀번호) 관리를 철저히 하여야 합니다. <br/>
                회원 ID(고유번호)와 Password(비밀번호)에 관한 모든 관리의 책임은 회원에게 있으며, 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.<br/>
                단, 센터의 시스템 고장 등 센터의 책임 있는 사유로 발생하는 문제에 대해서는 센터가 책임을 집니다.<br/>
                회원은 본인의 ID(고유번호) 및 Password(비밀번호)를 제3자에게 이용하게 해서는 안되며, 회원 본인의 ID(고유번호) 및 Password(비밀번호)를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 바로 센터에 통보하고 센터의 안내가 있는 경우 그에 따라야 합니다. <br/>
                사용하고 있는 회원의 ID(고유번호)는 센터의 사전 동의 없이 변경할 수 없습니다. <br/><br/> 
                제19조 (회원에 대한 통지)<br/>
                회원에 대한 통지를 하는 경우 센터는 센터가 발급한 e-mail 주소 또는 회원이 등록한 e-mail 주소 또는 전화, SMS 등을 이용할 수 있습니다. <br/>
                센터는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다. <br/><br/>
                제20조 (계약해지 및 이용제한)<br/>
                회원이 사이트 서비스 이용계약을 해지하고자 하는 경우에는 회원 본인이 사이트 상에서 또는 센터가 정한 별도의 이용방법으로 센터에 해지신청을 하여야 합니다. 단, 센터의 경영정책상의 사유로 서비스를 계속 제공하지 못할 경우에는 사전공지를 한 후 서비스를 중지할 수 있습니다. <br/>
                센터는 회원이 제 17조에 규정한 회원의 의무를 이행하지 않을 경우 사전 통지 없이 즉시 이용계약을 해지하거나 또는 서비스 이용을 중지할 수 있습니다. <br/>
                센터는 회원이 이용계약을 체결하여 ID(고유번호)와 Password(비밀번호)를 부여 받은 후에라도 회원의 자격에 따른 서비스 이용을 제한할 수 있습니다 <br/>
                가입 해지 여부는 기존의 ID, 비밀번호로 로그인이 되지 않으면 해지된 것이며, 한번 해지된 ID는 기존 사용자라도 영구적으로 재사용할 수 없습니다. <br/>
                센터는 회원이 제 15조, 제 17조 2항에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 게시물을 삭제 할 수 있습니다.<br/>
                위반 사항이 거듭되거나 위반으로 인한 센터 또는 타 이용자의 피해가 극심하다고 판단될 경우 기간과 절차를 정하여 서비스 이용을 중지할 수 있습니다.<br/>
                ① 경고조치 : 그 사유, 일시 및 기간을 정하여 메일 또는 전화 등의 방법을 이용하여 해당 회원 또는 대리인에게 통지합니다. <br/>
                ② 사용정지 : 약관에 위배되는 행위가 2회 발생시 1개월간 위즈덤센터 평생교육원 회원 자격 및 인터넷 접속 서비스 정지되며, 3회 이상 발생시엔 해당 ID 및 인터넷 접속 서비스를 영구 중지합니다. <br/><br/>
                제21조 (양도 금지)<br/>
                회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며 게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다. <br/>
                센터가 제3자에게 합병 또는 분할 합병되거나 서비스를 제3자에게 양도함으로써 서비스의 제공 주체가 변경되는 경우, 센터는 사전에 제24조의 통지방법으로 회원에게 통지합니다. 이 경우 합병, 분할합병, 서비스 양도에 반대하는 회원은 서비스 이용계약을 해지할 수 있습니다. <br/><br/>
                제22조 (손해배상) <br/>
                센터는 서비스 요금이 무료인 동안의 서비스 이용과 관련하여 센터의 고의, 과실에 의한 것이 아닌 한 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다. <br/>
                회원이 본 약관의 규정을 위반함으로 인하여 센터에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 센터에 발생하는 모든 손해를 배상하여야 합니다. <br/>
                회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 센터가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 센터를 면책 시켜야 하며, 센터가 면책되지 못한 경우 당해 회원은 그로 인하여 센터에 발생한 모든 손해를 배상하여야 합니다. <br/><br/>
                제23조 (면책조항) <br/>
                센터는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. <br/>
                센터는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다. <br/>
                센터는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다. <br/>
                센터는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다. <br/>
                센터는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다. <br/><br/>
                제24조 (관할법원)<br/>
                요금 등 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 센터의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다. <br/>
                서비스 이용과 관련하여 센터와 회원 사이에 분쟁이 발생한 경우, 센터와 회원은 분쟁의 해결을 위해 성실히 협의합니다. <br/>
                본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 센터자는 소비자의 주소지를 관할 하는 법원에 소를 제기할 수 있습니다. <br/><br/><br/>
                [부칙] (시행일)<br/>
                이 약관은 2023년 5월 15일부터 시행합니다.<br/><br/><br/>
                서비스 이용 약관<br/><br/> 
                제1조 (목적)<br/>
                본 약관은 주식회사 위즈덤센터(이하 "센터"라 합니다.)가 제공하는 www.wisdomlsi.com 홈페이지 서비스(이하 "서비스"라 합니다)의 이용조건 및 절차, 기타 필요한 사항을 규정함을 목적으로 합니다. <br/><br/> 
                제2조 (약관의 효력)<br/>
                이 약관은 이용자가 소정의 등록절차를 거쳐 회원가입을 함으로써 효력을 발생하며 동의하신 이용자는 유무선 인터넷 환경하의 서비스를 제공받습니다. <br/>
                임시 회원은 회원 가입의 절차 없이 이메일 주소, 핸드폰 번호를 제공하고 비밀번호를 설정하고 약관에 동의함으로써 유무선 인터넷 환경하에서 제한된 서비스를 제공받습니다.<br/><br/>
                제3조 (회원의 의무)<br/>
                이용자는 회원 가입 시에 필요한 정보를 진실하고 정확하게 센터에 제공하여야 합니다. 만약, 회원이 제공한 정보가 허위로 밝혀지거나, 그러하다고 의심할 만한 이유가 발생할 경우, 센터는 허위정보를 기재한 회원의 서비스 이용을 일부 또는 전부 중지할 수 있습니다. 자세한 내용은 아래 회원행동규범을 참조하시기 바랍니다. <br/>
                □ 회원 행동규범 □<br/>
                센터는 회원이 다음의 경우에 해당할 때 서비스 이용의 전부 또는 일부의 이용을 제한하거나 정지할 수 있습니다.<br/>
                ① 타인의 아이디(ID)와 비밀번호(PASSWORD)를 이용하는 경우<br/>
                ② 다른 회원의 개인정보를 수집 또는 저장하는 경우<br/>
                ③ 서비스를 통해 전송된 내용의 출처를 위장하는 경우<br/>
                ④ 센터의 승인을 받지 않고 광고, 판촉물, 정크메일, 스팸, 행운의 편지 등 기타 다른 형태의 권유를 게시, 게재, 전자메일 또는 기타 방법으로 전송하는 경우<br/><br/>
                제4조 (센터의 의무)<br/>
                회원정보는 check.wisdomcenter.co.kr 홈페이지 서비스 제공만을 목적으로 하며, 센터는 회원이 서비스를 이용함과 동시에 회원의 개인정보보호를 위하여 다음 원칙에 따라 최선을 다하겠습니다.<br/>
                ① 센터는 회원의 사전동의 없이는 회원의 개인정보를 공개하지 않습니다. 다만, 다음 각 호의 1에 해당하는 경우는 그러하지 아니합니다.<br/>
                1. 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구 받은 경우<br/>
                2. 정보통신윤리위원회의 요청이 있는 경우 <br/>
                3. 기타 관계법령에 의한 경우<br/>
                ② 센터는 회원의 개인정보를 공개하지 않음을 원칙으로 하면서 회원이 제공한 정보에 대하여는 더 좋은 서비스의 개발과 운영을 위하여 사용할 수 있습니다. <br/>
                ③ 등록한 개인정보의 수정이 필요할 경우나, 회원의 개인보호정책에 관련한 요청이 있을 경우, 즉시 반영하여 고쳐 나가겠습니다. <br/><br/> 
                제5조 (약관의 수정)<br/>
                센터는 합리적 사유가 발생했을 경우 서비스 약관의 내용을 변경할 수 있습니다. <br/>
                다만, 변경된 내용은 회원에게 공지함으로써 효력을 발생하며 센터는 변경된 약관을 초기화면에 공지함으로써 회원이 직접 확인할 수 있습니다. 회원은 변경된 약관에 동의하지 않을 경우 등록을 취소할 수 있으며, 서비스를 계속 이용할 경우 약관변경에 대한 동의로 간주합니다. <br/><br/> 
                제6조 (서비스의 중단)<br/>
                센터의 서비스는 불가피한 사정으로 인하여 중단하거나 수정할 수 있습니다. 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 센터의 관리 범위 이외의 서비스 설비장애 및 기타 불가항력에 의해 보관되지 못하거나, 전송되지 못한 경우 및 기타 통신데이터의 손실이 있는 경우에 대해 센터는 책임을 지지 않습니다. <br/><br/> 
                제7조 (손해배상)<br/>
                센터는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 동 손해가 센터의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부담하지 아니합니다. 만약 서비스이용으로 발생한 분쟁에 대해 소송이 제기될 경우 동 소송은 센터의 본사 소재지를 관할하는 법원을 관할법원으로 합니다. <br/><br/> 
                제8조 (약관 외 준칙)<br/>
                이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 서비스별 안내의 취지에 따라 적용할 수 있습니다. <br/><br/> 
                이 약관은 2023년 5월1일부터 시행합니다. <br/>
                위즈덤센터 이용약관에 동의함(필수)<br/>
            </p>
        </>
    );
}

export default Service;