import React from 'react';
import styles from './SupervisorCoachingCell.module.css'
import { Link } from 'react-router-dom';

const SupervisorCoachingCell = (props) => {
    const coaching = props.coaching.coaching;
    const coachingStatus = props.coaching.coaching_status;
    const expert = props.coaching.expert;
    const student = props.coaching.student;

	return (
		<div className={styles.container}>
            <div className={styles.title_group}>
                <div>
                    <div className={styles.main_group}>
                        <div className={styles.tag_box}>
                            <p className={styles.main_tag}>회원</p>
                        </div>
                        <div className={styles.info_group}>
                            <p className={styles.name}>{student.info.name}</p>
                            <p className={styles.email}>{student.email}</p>
                        </div>
                    </div>
                    <div className={styles.main_group}>
                        <div className={styles.tag_box}>
                            <p className={styles.main_tag}>검사</p>
                        </div>
                        <p className={styles.name}>{props.coaching.date_text}</p>
                    </div>
                    <div className={styles.main_group}>
                        <div className={styles.tag_box}>
                            <p className={styles.main_tag}>전문가</p>
                        </div>
                        <div className={styles.info_group}>
                            <p className={styles.name}>{expert.name}</p>
                            <p className={styles.email}>{expert.email}</p>
                        </div>
                    </div>
                </div>
                { coachingStatus === "coaching_waiting_approval" ?
                    <button className={styles.action_btn} onClick={()=>props.action(props.coaching)}>검토하기</button> : null
                }
                { coachingStatus === "coaching_rejected" ?
                    <button className={styles.action_btn_done} onClick={()=>props.action(props.coaching)}>분석 재작성중</button> : null
                }
                { coachingStatus === "coaching_approved" ?
                    <button className={styles.action_btn_done} onClick={()=>props.action(props.coaching)}>검토완료</button> : null
                }
            </div>
			<hr className={styles.devider} />
		</div>
	);
};

export default SupervisorCoachingCell;