import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GroupStudentCell from './GroupStudentCell';
import styles from "./GroupStudentList.module.css"
import InviteUserModal from "./InviteUserModal"
import axios from 'axios';
import DeleteInvitationModal from './DeleteInvitationModal';

const GroupStudentList = (props) => {
    const STUDENT_LIST_URL = "https://api.wisdomlsi.com/api/v1/group/students/"
    const [isInviteModalShow, setIsInviteModalShow] = useState(false)
    const [isDeleteUserModalShow, setDeleteUserModalShow] = useState(false)
    const [willBeDeletedStudent, setWillBeDeletedStudent] = useState(null);
    const [studentList, setStudentList] = useState([]);

    const navigate = useNavigate()

    const showInviteModal = () => {
        if (isInviteModalShow) {
            fetchStudentList();
        }

        return (
            setIsInviteModalShow(!isInviteModalShow)
        )
    }

    const showDeleteUserModal = () => {
        if (isDeleteUserModalShow) {
            fetchStudentList();
        }
        
        return (
            setDeleteUserModalShow(!isDeleteUserModalShow)
        )
    }

    function deleteUser(student) {
        setWillBeDeletedStudent(student);
        showDeleteUserModal();
    }

    function fetchStudentList() {
		axios({
			method: 'get',
			url: STUDENT_LIST_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setStudentList(response.data["data"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

	useEffect(() => {
        fetchStudentList();
	}, []);

	function moveToStudent(student) {
		navigate("/student", { state: {student: student, from: "group"}})
	}

	return (
        <div className={styles.container}>
            <div className={styles.group_top}>
                <p className={styles.title}>일반회원 {studentList.length}명</p>
                <button className={styles.action_btn} onClick={showInviteModal}>초대하기</button>
            </div>
            <p className={styles.teacher_hint}>
                회원의 정보를 열람하시려면 지도자 계정을 생성후 이용해주세요.<br/>
                1. 로그아웃 후 일반회원가입을 통해 새로운 계정 생성<br/>
                2. 생성한 계정을 본 그룹계정에서 지도자로 추가 (지도자목록 탭)
            </p>
            { studentList.length == 0 ?
                <p className={styles.default_msg}>아직 그룹에 초대된 회원이 없어요</p> :
                null
            }
            {studentList && studentList.map((student) => (
                <GroupStudentCell key={student.student.id} student={student.student} email={student.email} status={student.status} clickAction={(student)=>moveToStudent(student)} deleteAction={deleteUser} />
            ))}
            { isInviteModalShow ? <InviteUserModal modalControl={showInviteModal} for="student" memberTitle="회원" actionTitle="초대" /> : null }
            { isDeleteUserModalShow ? <DeleteInvitationModal student={willBeDeletedStudent} modalControl={showDeleteUserModal} /> : null }
        </div>
	);
};

export default GroupStudentList;