import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ApexChart from 'react-apexcharts'
import styles from "./WisdomGraph.module.css"

const WisdomGraph = (props) => {
    return (
        <div>
            <ApexChart
				className={styles.graph}
				type="line"
				series={props.dataList} 
				options={{    
					chart : {
                        toolbar: false,
						height: 700,
						width: 700,                    
                    }, 
                    dataLabels : {
                        enabled: true
                    },
                    xaxis: {
                        categories: props.categoryList,
                        title: {
                            text: props.graphTitle 
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: 100
                    },
                    colors: props.colors
                }}>
            </ApexChart>
        </div>
    );
}

export default WisdomGraph;