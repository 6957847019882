import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SelectSignUpType.module.css'


const SelectSignUpType = (props) => {
	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
                <p className={styles.title}>가입하실 회원유형을 선택해주세요</p>
                <div className={styles.option_group}>
                    <Link to='/normal_signup' className={styles.option}>일반회원</Link>
                    <Link to='/group_signup' className={styles.option}>그룹회원</Link>
                </div>
            </div>
		</div>
	);
};

export default SelectSignUpType;