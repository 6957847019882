import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./GroupStudentCell.module.css"

const GroupStudentCell = (props) => {
    function getStatusKor() {
        if (props.status === "prepare") {
            return "초대됨"
        } else if (props.status === "active") {
            return "활성"
        }
    }

	return (
        <div className={styles.container}>
            <div className={styles.container_inside}>
                <div className={styles.info_box_out}>
                    <div className={styles.info_box}>
                        <p className={styles.name}>{props.student.name}</p>
                        <p className={styles.email}>{props.email}</p>
                    </div>
                    <p className={styles.status}>{getStatusKor()}</p>
                </div>
                <div className={styles.status_box}>
                    <button className={styles.click_action_btn} onClick={()=>props.clickAction(props.student)}>검사내용 보기</button>
                    <button className={styles.action_btn} onClick={()=>props.deleteAction(props.student)}>삭제</button>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default GroupStudentCell;