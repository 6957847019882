import React, { useRef } from 'react';
import Header from './Header';
import styles from './RequestCoaching.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

const RequestCoaching = (props) => {
	const location = useLocation();
    const navigate = useNavigate();

    const coachingRef = useRef(null);
    const COACHING_URL = "https://api.wisdomlsi.com/api/v1/request_coaching/"

    function requestCoaching() {
        if (coachingRef.current.value === "") {
            alert("내용을 입력해주세요.");
        } else {
            const param = {
                "story": coachingRef.current.value,
                "payment_history_id": location.state.paymentHistoryId,
            }

            axios({
                method: 'post',
                url: COACHING_URL,
                data: JSON.stringify(param),
                headers: {
                    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type": "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                const code = response.data["code"];

                if (code === "already_requested") {
                    alert("이미 처리된 요청입니다.");
                } else if (code === "success") {
                    alert("분석요청이 완료되었습니다.");
                }

                navigate('/subscribe_test', { state: {paymentHistoryId: location.state.paymentHistoryId, from: "student"}});
            })
            .catch(function(error) {
                if (error.response.status === 401) {
                    alert("세션이 만료되었습니다.\n" + "로그인후 다시 이용해주세요.");
                }
            });
        }
    }

	return (
		<div>
            <Header account={"일반회원"} />
            <div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.title_box}>
                        <p className={styles.title}>{location.state.dateString} 분석신청</p>
                        <p className={styles.subtitle}>전문가 분석을 위해서는 해당 <span>필수 검사</span>와<br/>아래 <span>필수 질문</span> 내용을 반드시 적어주세요.<br/><br/>(*필수검사 항목)<br/>공부분석 = LSI공부, WPI현실, WPI이상<br/>운동분석 = LSI운동, WPI현실, WPI이상<br/><br/>(*필수질문)<br/>1. 성별/나이/학년<br/>2. 현재 공부(운동)하는 방법 (스케줄)<br/>3. 지난 일주일 중 전형적인 하루일과<br/>4. 공부(운동)하면서 힘든점<br/>5. 그 이외의 고민, 질문, 힘든점 등</p>
                    </div>
                    <div className={styles.input_box}>
                        <textarea className={styles.input_text} ref={coachingRef}></textarea>
                        <div className={styles.done_btn_box}>
                            <button className={styles.done_btn} onClick={requestCoaching}>작성완료</button>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
};

export default RequestCoaching;