import React, { useState } from 'react';
import styles from './RequestConsulting.module.css'
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';

const RequestConsulting = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/request_consulting/"

    const navigate = useNavigate();
	const [selectedCounselor, setSelectedCounselor] = useState("");
	const [selectedMethod, setSelectedMethod] = useState("");

	function selectCounselor(code) {
		if (selectedCounselor === code) {
			setSelectedCounselor("");
		} else {
			setSelectedCounselor(code);
		}
	}

	function selectMethod(code) {
		if (selectedMethod === code) {
			setSelectedMethod("");
		} else {
			setSelectedMethod(code);
		}
	}

	function requestConsulting() {
		if (selectedCounselor === "") {
			alert("희망하시는 상담가를 선택해주세요.");
		} else if (selectedMethod === "") {
			alert("희망하시는 상담방식을 선택해주세요.");
		} else {
			const param = {
				"counselor": selectedCounselor,
				"method": selectedMethod
			}

			axios({
				method: 'post',
				url: URL,
                data: JSON.stringify(param),
				headers: {
					"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
					"Content-Type": "application/json; charset=utf-8"
				}
			})
			.then(function(response) {
				alert("상담신청이 완료되었습니다.");
				navigate(-1);
			})
			.catch(function(error) {
				if (error.response.status == 401) {
					navigate('/');
				}
			});
		}
	}

	return (
		<div>
			<Header from={"student"} account={"일반회원"} />
			<div className={styles.parent}>
				<div className={styles.parent_inside}>
					<p className={styles.title}>상담신청</p>
					<p className={styles.hint}>아래정보를 선택해주세요</p>
					<p className={styles.subtitle}>희망하는 상담가</p>
					<div className={styles.option_box}>
						<p>1. 내 분석지 작성 상담가</p>
						{ selectedCounselor === "" || selectedCounselor === "my_expert" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectCounselor("my_expert")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<div className={styles.option_box}>
						<p>2. 1급 상담가</p>
						{ selectedCounselor === "" || selectedCounselor === "counselor_1" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectCounselor("counselor_1")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<div className={styles.option_box}>
						<p>3. 2급 상담가</p>
						{ selectedCounselor === "" || selectedCounselor === "counselor_2" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectCounselor("counselor_2")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<div className={styles.option_box}>
						<p>4. 이은주 박사님</p>
						{ selectedCounselor === "" || selectedCounselor === "doc_lee" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectCounselor("doc_lee")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<hr className={styles.devider} />
					<p className={styles.subtitle}>희망하는 상담방식</p>
					<div className={styles.option_box}>
						<p>1. 센터 방문</p>
						{ selectedMethod === "" || selectedMethod === "center" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectMethod("center")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<div className={styles.option_box}>
						<p>2. 화상 상담</p>
						{ selectedMethod === "" || selectedMethod === "screen" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectMethod("screen")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<div className={styles.option_box}>
						<p>3. 전화 상담</p>
						{ selectedMethod === "" || selectedMethod === "phone" ?
							<input type="checkbox" className={styles.check} onClick={()=>selectMethod("phone")}></input> :
							<input type="checkbox" className={styles.check} disabled="disabled"></input>
						}
					</div>
					<div className={styles.request_parent}>
						<button className={styles.request_btn} onClick={requestConsulting}>신청하기</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RequestConsulting;