import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './ChangePw.module.css';
import Header from "./Header";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from "axios";

const ChangePw = () => {
    const EDIT_ACCOUNT_INFO_URL = "https://api.wisdomlsi.com/api/v1/change_pw/";

    const navigate = useNavigate();
    const location = useLocation();

    const pwRef = useRef("");
    const newPwRef = useRef("");
    const newPwConfirmRef = useRef("");

    const saveEditAccountInfo = () => {
        if (!validate()) {
            return;
        }

        const param = {
            "original_pw": pwRef.current.value,
            "new_pw": newPwRef.current.value
        }

        axios({
            method: 'post',
            url: EDIT_ACCOUNT_INFO_URL,
            data: JSON.stringify(param),
            headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];
            console.log(response.data);
            
            if (code === "success") {
                alert("비밀번호가 변경되었습니다");
                moveBack();
            } else if (code === "error") {
                alert(response.data["message"]);
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function validate() {
        if (pwRef.current.value === "") {
            alert("비밀번호를 입력해주세요");
            return false;
        } else if (newPwRef.current.value === "") {
            alert("새로운 비밀번호를 입력해주세요");
            return false;
        } else if (newPwConfirmRef.current.value === "") {
            alert("새로운 비밀번호를 한번 더 입력해주세요");
            return false;
        } else if (newPwRef.current.value !== newPwConfirmRef.current.value) {
            alert("새로운 비밀번호가 일치하지 않습니다");
            return false;
        } else if (pwRef.current.value === newPwRef.current.value) {
            alert("기존과 다른 비밀번호를 입력해주세요");
            return false;
        } else {
            return true;
        }
    }

    const moveBack = () => {
        navigate(-1);
    }

    return (
        <div>
			<Header from={location.state.from} />
            <div className={styles.container}>
                <div className={styles.input_container}>
                    <div className={styles.title_box} onClick={moveBack}>
				        <ArrowBackIosIcon className={styles.back_arrow} />
                        <h2 className={styles.title}>비밀번호 변경</h2>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>기존 비밀번호</p>
                        <input className={styles.input_box} type="password" ref={pwRef}></input><br/>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>새로운 비밀번호</p>
                        <input className={styles.input_box} type="password" ref={newPwRef}></input><br/>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>새로운 비밀번호 확인</p>
                        <input className={styles.input_box} type="password" ref={newPwConfirmRef}></input><br/>
                    </div>
                    <button className={styles.signup} onClick={()=>saveEditAccountInfo()}>변경하기</button>
                </div>
            </div>
        </div>
    );
}

export default ChangePw;