import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./ChangeGroupPwModal.module.css"
import axios from 'axios';

const ChangeGroupPwModal = (props) => {
    const pwRef = useRef("");
    const pwConfirmRef = useRef("");
    const URL = "https://api.wisdomlsi.com/api/v1/group/change_password/";

    const navigate = useNavigate();

    function changePassword() {
        if (validate()) {
            const param = {
                "new_password": pwRef.current.value,
                "group_id": props.groupId
            }

            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                console.log(response.data);
                
                if (response.data["code"] === "success") {
                    alert("비밀번호가 변경되었습니다.");
                    props.changePwDoneAction(pwRef.current.value);
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

    function validate() {
        if (pwRef.current.value === "") {
            alert("비밀번호를 입력해주세요.");
            return false;
        } else if (pwConfirmRef.current.value === "") {
            alert("비밀번호 확인을 입력해주세요.");
            return false;
        } else if (pwRef.current.value !== pwConfirmRef.current.value) {
            alert("비밀번호가 일치하지 않습니다.");
            return false;
        } else {
            return true;
        }
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>변경하실 비밀번호를 적어주세요</p>
				<p className={styles.modal_desc}>그룹회원은 최초 로그인시<br />비밀번호를 설정하셔야합니다</p>
                <div className={styles.feedback_group}>
                    <input placeholder="비밀번호" type='password' className={styles.password} ref={pwRef}></input>
                    <input placeholder='비밀번호 확인' type='password' className={styles.password} ref={pwConfirmRef}></input>
                    <div>
                        <button className={styles.add_btn} onClick={changePassword}>변경하기</button>
                    </div>
                </div>
			</div>
		</div>
	);
};

export default ChangeGroupPwModal;