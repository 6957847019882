import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Supervisor.module.css';
import Header from './Header';
import SupervisorCoachingCell from './SupervisorCoachingCell';
import axios from 'axios';

const Supervisor = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/supervisor/home/";
	const navigate = useNavigate();
	const [expertList, setExpertList] = useState([]);
	const [coachingList, setCoachingList] = useState([]);
	const [numTodoCoaching, setNumTodoCoaching] = useState(null);

	useEffect(() => {
		fetchCoachingList();
	}, []);

	function fetchCoachingList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setExpertList(response.data["expert_list"]);
			setCoachingList(response.data["coaching_list"]);

			calculateNumTodoCoaching(response.data["coaching_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}

	function calculateNumTodoCoaching(coachingList) {
		let num = 0;

		for (let i=0; i<coachingList.length; i++) {
			if (!coachingList[i].coaching.is_approved) { num++; }
		}

		setNumTodoCoaching(num);
	}

	function moveToTestResult(coaching) {
		console.log(coaching);
		navigate("/test_result", {state: {from: "supervisor", coachingId: coaching.coaching.id, paymentHistoryId: coaching.payment_history_id, testClassId: coaching.test_class_id}});
	}

	function moveToExpertStudent(coaching) {
        navigate('/expert/student', { state: {paymentHistoryId: coaching.payment_history_id, from: "supervisor"}});
	}

	return (
		<div>
			<Header from={"supervisor"} account={"슈퍼바이저"} />
			<div className={styles.container}>
				<div className={styles.container_inside}>
					<div className={styles.top_group}>
						<p className={styles.num_coaching}>미해결 요청분석수 {numTodoCoaching}개</p>
						<p className={styles.title}>연결된 지도자 {expertList.length}명</p>
					</div>
                    { coachingList && coachingList.map((coaching) => (
						<SupervisorCoachingCell key={coaching.id} coaching={coaching} action={moveToExpertStudent} />
					))}
                    {/* { studentList && studentList.map((student) => (
						<StudentCell key={student.info.id} student={student} action={moveToStudent} />
					))}
					{ studentList.length == 0 ? <p className={styles.empty_msg}>연결된 회원이 없습니다.</p> : null } */}
				</div>
			</div>
		</div>
	);
};

export default Supervisor;