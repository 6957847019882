import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./GroupTeacherCell.module.css"

const GroupTeacherCell = (props) => {
	return (
        <div className={styles.container}>
            <div className={styles.container_inside}>
                <div className={styles.info_box}>
                    <p className={styles.name}>{props.teacher.info.name}</p>
                    <p className={styles.email}>{props.teacher.email}</p>
                </div>
                <div className={styles.status_box}>
                    <p className={styles.num_student}>{props.teacher.num_student}명</p>
                    <button className={styles.edit_btn} onClick={()=>props.modifyAction(props.teacher)}>수정</button>
                    <button className={styles.delete_btn} onClick={()=>props.deleteAction(props.teacher)}>삭제</button>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default GroupTeacherCell;