import React, { useEffect, useState } from 'react';
import Header from './Header';
import ExpertStudentCell from './ExpertStudentCell';
import styles from './Expert.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

let nowYear = (new Date()).getFullYear().toString();
let nowMonth= (new Date()).getMonth().toString();

const Expert = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/expert/home/";
	const navigate = useNavigate();
	const [hasNoStudents, setHasNoStudents] = useState(false);
	const [studentList, setStudentList] = useState([]);

	const monthOptionList = [
		{ value: 1, label: '1월' }, 
		{ value: 2, label: '2월' }, 
		{ value: 3, label: '3월' },
		{ value: 4, label: "4월" },
		{ value: 5, label: "5월" },
		{ value: 6, label: "6월" },
		{ value: 7, label: "7월" },
		{ value: 8, label: "8월" },
		{ value: 9, label: "9월" },
		{ value: 10, label: "10월" },
		{ value: 11, label: "11월" },
		{ value: 12, label: "12월" }];
	const yearOptionList = [
		{ value: 2021, label: '2021년' }, 
		{ value: 2022, label: '2022년' },
		{ value: 2023, label: "2023년" },
		{ value: 2024, label: "2024년" },
		{ value: 2025, label: "2025년" }];

	useEffect(() => {
		getSelectedDateStudentList();
	}, []);

	function selectYear(year) {
		nowYear = year.value.toString();
		getSelectedDateStudentList();
	}

	function selectMonth(month) {
		nowMonth = month.value.toString();
		getSelectedDateStudentList();
	}

	function getSelectedDateStudentList() {
		axios({
			method: 'get',
			url: URL + nowYear + "/" + nowMonth + "/",
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setStudentList(response.data["student_list"]);

			if (response.data["code"] === "empty") {
				setHasNoStudents(true);
			}
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}

	function moveToExpertStudent(student) {
        navigate('/expert/student', { state: {paymentHistoryId: student.payment_history_id, from: "expert"}});
	}

	return (
		<div>
			<Header account={"전문가"} />
			<div className={styles.container}>
				<div className={styles.container_inside}>
					<div className={styles.date_group}>
						<Dropdown className={styles.year_selector} onChange={value => selectYear(value)} options={yearOptionList} value={nowYear+"년"} />
						<Dropdown className={styles.month_selector} onChange={value => selectMonth(value)} options={monthOptionList} value={nowMonth+"월"} />
					</div>
                    { studentList && studentList.map((student) => (
						<ExpertStudentCell key={student.student.student_id} student={student} action={moveToExpertStudent} />
					))}
					{ studentList !== undefined && studentList.length === 0 ? <p className={styles.empty_msg}>선택된 기간에는 분석요청이 없습니다.</p> : null }
					{ hasNoStudents ? <p className={styles.empty_msg}>아직 연결된 회원이 없습니다.</p> : null }
				</div>
			</div>
		</div>
	);
};

export default Expert;