import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./GroupTeacherStudentList.module.css"
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TeacherStudentCell from './TeacherStudentCell';
import DisconnectStudentWithTeacherModal from './DisconnectStudentWithTeacherModal'
import ConnectStudentWithTeacherModal from './ConnectStudentWithTeacherModal';

const GroupTeacherStudentList = (props) => {
    const TEACHER_STUDENT_LIST_URL = "https://api.wisdomlsi.com/api/v1/group/teacher/students/"
    const STUDENT_LIST_URL = "https://api.wisdomlsi.com/api/v1/group/students/"

    const [isConnectModalShow, setIsConnectModalShow] = useState(false);
    const [isDisconnectModalShow, setIsDisconnectModalShow] = useState(false);
    const [willBeDisconnectedStudent, setWillBeDisconnectedStudent] = useState(null);
    const [studentList, setStudentList] = useState([]);
    const [teacherStudentList, setTeacherStudentList] = useState([]);

    const navigate = useNavigate()

    const showConnectModal = () => {
        if (isConnectModalShow) {
            fetchTeacherStudentList();
        } else {
            if (studentList.length === 0) {
                alert("아직 그룹에 추가된 회원이 없습니다");
                return
            }
        }

        return (
            setIsConnectModalShow(!isConnectModalShow)
        )
    }

    const showDisconnectModal = () => {
        if (isDisconnectModalShow) {
            fetchTeacherStudentList();
        }

        return (
            setIsDisconnectModalShow(!isDisconnectModalShow)
        )
    }

    function disconnectStudent(student) {
        setWillBeDisconnectedStudent(student);
        showDisconnectModal();
    }

    function fetchStudentList(refList) {
		axios({
			method: 'get',
			url: STUDENT_LIST_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            const resultList = [];

            for(let i=0; i<response.data["data"].length; i++) {
                if (response.data["data"][i].status === "active") {
                    if (checkNotContains(refList, response.data["data"][i])) {
                        resultList.push(response.data["data"][i]);
                    }
                }
            }

            setStudentList(resultList);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function fetchTeacherStudentList() {
        const params = {
            "teacher_id": props.teacher.teacher.id
        }

		axios({
			method: 'get',
			url: TEACHER_STUDENT_LIST_URL,
            params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setTeacherStudentList(response.data["student_list"]);
            fetchStudentList(response.data["student_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function checkNotContains(myList, stud) {
        for (let i=0; i<myList.length; i++) {
            if (myList[i].info.id == stud.student.id) {
                return false;
            }
        }

        return true;
    }

	useEffect(() => {
        fetchTeacherStudentList();
	}, []);

    function goBack() {
        props.indexAction(1);
    }

	return (
        <div className={styles.container}>
            <div className={styles.group_top}>
                <div className={styles.back_group} onClick={()=>goBack()}>
				    <ArrowBackIosIcon className={styles.back_arrow} />
                    <div>
                        <p className={styles.title}>{props.teacher.info.name} 지도자님</p>
                        <p className={styles.subtitle}>매칭된 회원 {teacherStudentList.length}명</p>
                    </div>
                </div>
                <button className={styles.action_btn} onClick={showConnectModal}>회원 매칭하기</button>
            </div>
            { teacherStudentList.length == 0 ?
                <p className={styles.default_msg}>아직 매칭된 회원이 없어요</p> :
                null
            }
            {teacherStudentList && teacherStudentList.map((student) => (
                <TeacherStudentCell key={student.info.id} student={student} name={student.info.name} email={student.email} disconnectAction={disconnectStudent} />
            ))}
            { isConnectModalShow ? <ConnectStudentWithTeacherModal modalControl={showConnectModal} teacher={props.teacher} studentList={studentList} /> : null }
            { isDisconnectModalShow ? <DisconnectStudentWithTeacherModal modalControl={showDisconnectModal} student={willBeDisconnectedStudent} teacher={props.teacher} /> : null }
        </div>
	);
};

export default GroupTeacherStudentList;