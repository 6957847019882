import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./CouponModal.module.css"
import axios from 'axios';

const CouponModal = (props) => {
    const couponRef = useRef("");
    const URL = "https://api.wisdomlsi.com/api/v1/use_coupon/";

    const navigate = useNavigate();

    function useCoupon() {
        if (couponRef.current.value === "") {
            alert("쿠폰번호를 입력해주세요.");
        } else {
            const param = {
                "coupon_code": couponRef.current.value,
                "test_class_code": props.testClassCode
            }

            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                console.log(response.data);
                
                if (response.data["code"] === "success") {
                    props.useCouponDone();
                } else {
                    alert(response.data["message"]);
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>쿠폰번호를 작성해주세요</p>
                <div className={styles.feedback_group}>
                    <input className={styles.feedback} type='text' ref={couponRef}></input>
                    <div className={styles.btn_group}>
                        <button className={styles.add_btn} onClick={useCoupon}>쿠폰 사용하기</button>
                        <button className={styles.cancel_btn} onClick={()=>props.modalAction()}>취소</button>
                    </div>
                </div>
			</div>
		</div>
	);
};

export default CouponModal;