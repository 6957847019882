import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ExpertStudentCell.module.css'

const ExpertStudentCell = (props) => {
    const student = props.student

	return (
		<div className={styles.container}>
            <div className={styles.title_group}>
                <div className={styles.info_group}>
                    <p className={styles.name}>{student.student.name}</p>
                    <p className={styles.email}>{student.student.email}</p>
                    { student.group === "empty" ?
                        <p className={styles.group_name}>그룹없음</p> :
                        <p className={styles.group_name}>{student.group}</p>
                    }
                </div>
                <div className={styles.coaching_group}>
                    { student.coaching_status === "coaching_in_progress" ?
                        <button className={styles.show_info} onClick={()=>props.action(props.student)}>분석하기</button> : null
                    }
                    { student.coaching_status === "coaching_waiting_approval" ?
                        <button className={styles.show_info} onClick={()=>props.action(props.student)}>분석 승인대기</button> : null
                    }
                    { student.coaching_status === "coaching_approved" ?
                        <button className={styles.show_info} onClick={()=>props.action(props.student)}>분석 승인됨</button> : null
                    }
                    { student.coaching_status === "coaching_rejected" ?
                        <button className={styles.show_info} onClick={()=>props.action(props.student)}>분석 반려됨</button> : null
                    }
                </div>
            </div>
			<hr className={styles.devider} />
		</div>
	);
};

export default ExpertStudentCell;