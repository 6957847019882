import React, { useEffect, useState } from 'react';
import TestList from "./TestList"
import Header from './Header';
import styles from './Student.module.css'
import TaskIcon from '@mui/icons-material/Task';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';

const Student = (props) => {
	const STUDENT_URL = "https://api.wisdomlsi.com/api/v1/student/home/"
	const TEACHER_URL = "https://api.wisdomlsi.com/api/v1/teacher/student/home/"
	const INVITATION_URL = "https://api.wisdomlsi.com/api/v1/student/invitation/"
	const REPLY_INVITATION_URL = "https://api.wisdomlsi.com/api/v1/student/reply_invitation/"

    const navigate = useNavigate();
	const location = useLocation();

	const [historyEmpty, setHistoryEmpty] = useState(null);
	const [monthList, setMonthList] = useState(null);
	const [historyList, setHistoryList] = useState(null);
	const [invitationList, setInvitationList] = useState([]);

	useEffect(() => {
		let url = STUDENT_URL;

		if (location.state.from && (location.state.from === "teacher") || (location.state.from === "group")) {
			url = TEACHER_URL + location.state.student.id + "/";
		}

		axios({
			method: 'get',
			url: url,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

			if (response.data["code"] === "empty") { 
				setHistoryEmpty(true);
			}

			setMonthList(response.data["payment_history"]);
			setHistoryList(response.data["test_history"]);
			
			if (location.state.from === "student") {
				fetchInvitationList();
			}
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}, []);

	function fetchInvitationList() {
		axios({
			method: 'get',
			url: INVITATION_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			console.log(res.data);
			setInvitationList(res.data["data"]);
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	function replyInvitation(reply, invitationId) {
		const params = {
			"reply": reply,
			"invitation_id": invitationId
		}

		axios({
			method: 'post',
			url: REPLY_INVITATION_URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
				if (reply === "accept") {
					alert("그룹가입 요청을 수락하였습니다");
				} else {
					alert("그룹가입 요청을 거절하였습니다");
				}

				fetchInvitationList();
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	function moveToPaySubscribe() {
		navigate('/pay_subscribe');
	}

	return (
		<div className={styles.container}>
			{ location.state.from === "group" ? <Header isGroup={true} /> : null }
			{ location.state.from === "student" ? <Header from={"student"} account={"일반회원"} /> : null }
			{ location.state.from === "teacher" ? <Header from={"teacher"} account={"지도자"} /> : null }
			{ location.state.from === "student" && invitationList && invitationList.map((invitation) =>(
				<div>
					{ invitation.invitation.status === "prepare" && props.from === undefined ?
						<div className={styles.invite_group}>
							<p className={styles.invite_title}>{invitation.group.name}에서<br/> 그룹가입 요청이 왔습니다</p>
							<div className={styles.invite_action_group}>
								<button className={styles.invite_action} onClick={()=>replyInvitation("accept", invitation.invitation.id)}>수락</button>
								<button className={styles.invite_action} id={styles.reject} onClick={()=>replyInvitation("reject", invitation.invitation.id)}>거절</button>
							</div>
						</div> : null
					}
				</div>
			))}
			{ location.state.from === "student" ?
				<div className={styles.top}>
					<Link to='/only_test_list' className={styles.student_group}>
						<p className={styles.student_btn}>추가 검사진행</p>
						<div className={styles.task_container}>
							<TaskIcon />
						</div>
					</Link>
					<Link to='/request_consulting' className={styles.student_group}>
					<p className={styles.student_btn}>상담신청</p>
						<div className={styles.task_container}>
							<ContactEmergencyIcon />
						</div>
					</Link>
				</div> : null
			}
			{ location.state.from === "student" ?
				<p className={styles.test_title_group}>내 검사목록</p> :
				<div className={styles.test_title_group} onClick={()=>navigate(-1)}>
					<ArrowBackIosIcon />
					<p className={styles.test_title}>{location.state.student.name}님 검사목록</p>
				</div>
			}
			<TestList monthList={monthList} historyList={historyList} from={location.state.from} />
			{ historyEmpty && historyEmpty === true && props.from === undefined ?
				<div className={styles.payment_group}>
					<p className={styles.payment_hint}>구독결제 후 이용해주세요</p>
					<button className={styles.payment_btn} onClick={moveToPaySubscribe}>결제하기</button>
				</div> : null
			}
			<p className={styles.test_bottom}> </p>
		</div>
	);
};

export default Student;