import React, { useState, useEffect } from 'react';
import Header from './Header'
import styles from './ExpertStudent.module.css'
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ExpertStudent = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/subscribe_month/"

    const navigate = useNavigate();
	const location = useLocation();
	const [studentName, setStudentName] = useState(null);
	const [coachingStatus, setCoachingStatus] = useState(null);
	const [coachingHint, setCoachingHint] = useState(null);
	const [coachingBtnText, setCoachingBtnText] = useState(null);
	const [dateString, setDateString] = useState(null);
	const [story, setStory] = useState(null);
	const [btnStatus1, setBtnStatus1] = useState(null);
	const [btnStatus2, setBtnStatus2] = useState(null);
	const [btnStatus3, setBtnStatus3] = useState(null);
	const [btnStatus4, setBtnStatus4] = useState(null);
	const [btnString1, setBtnString1] = useState(null);
	const [btnString2, setBtnString2] = useState(null);
	const [btnString3, setBtnString3] = useState(null);
	const [btnString4, setBtnString4] = useState(null);

	useEffect(() => {
		const params = {
			"payment_history_id": location.state.paymentHistoryId,
			"for": "expert"
		}

		axios({
			method: 'get',
			url: URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setStudentName(response.data["info"]["student_name"]);

			const year = response.data["info"]["year"];
			const now = new Date();

			if (now.getFullYear() === year) {
				setDateString(response.data["info"]["month"] + "월");
			} else {
				setDateString(year + "년 " + response.data["info"]["month"] + "월");
			}

			const testClass = response.data["test_class"];

			setBtnStatus1(testClass["1"]["status"]);
			setBtnString1(testClass["1"]["btn_text"]);
			setBtnStatus2(testClass["2"]["status"]);
			setBtnString2(testClass["2"]["btn_text"]);
			setBtnStatus3(testClass["3"]["status"]);
			setBtnString3(testClass["3"]["btn_text"]);
			setBtnStatus4(testClass["4"]["status"]);
			setBtnString4(testClass["4"]["btn_text"]);

			const coachingStatus = response.data["coaching_status"];
			setCoachingStatus(coachingStatus);
			setStory(response.data["story"]);

			if (coachingStatus === "coaching_in_progress") {
				if (location.state.from === "expert") {
					setCoachingHint("확인해야할 분석요청이 있습니다.");
					setCoachingBtnText("분석하기");
				} else if (location.state.from === "supervisor") {
					setCoachingHint("전문가 분석이 진행중 입니다.");
					setCoachingBtnText("진행중");
				}
			} else if (coachingStatus === "coaching_approved") {
				setCoachingHint("분석이 승인되었습니다");
				setCoachingBtnText("확인하기");
			} else if (coachingStatus === "coaching_waiting_approval") {
				setCoachingHint("슈퍼바이저의 승인을 대기중입니다");

				if (location.state.from === "expert") {
					setCoachingBtnText("대기중");
				} else if (location.state.from === "supervisor") {
					setCoachingBtnText("검토하기");
				}
			} else if (coachingStatus === "coaching_rejected") {
				setCoachingHint("분석이 반려되었습니다");

				if (location.state.from === "expert") {
					setCoachingBtnText("다시 분석하기");
				} else if (location.state.from === "supervisor") {
					setCoachingBtnText("재작성중");
				}
			}
		})
		.catch(function(error) {
			if (error.response.status === 401) {
				navigate('/');
			}
		});
	}, [btnString3]);

	function goBack() {
		navigate(-1);
	}

	function moveToAddCoaching() {
        navigate('/add_coaching', { state: {paymentHistoryId: location.state.paymentHistoryId, story: story, studentName: studentName, from: location.state.from}});
	}

	function moveToCoaching() {
        navigate('/coaching', { state: {paymentHistoryId: location.state.paymentHistoryId, story: story, studentName: studentName, from: location.state.from}});
	}

	return (
		<div className={styles.container}>
			{ location.state.from === "expert" ? <Header from="expert" account={"전문가"} /> : null }
			{ location.state.from === "supervisor" ? <Header from="supervisor" account={"슈퍼바이저"} /> : null }
			<div className={styles.top_menu} onClick={goBack} >
				<ArrowBackIosIcon className={styles.back_arrow}/>
				<p className={styles.month}>{studentName}님 {dateString}</p>
			</div>
			{ coachingStatus && coachingStatus !== "test_in_progress" ?
				<div className={styles.coaching_outside}>
					<div className={styles.coaching_inside}>
						<p className={styles.request_coaching_text}>{coachingHint}</p>
						<div>
							{ coachingStatus === "coaching_in_progress" ? 
								<button className={styles.request_coaching_btn} onClick={moveToAddCoaching}>{coachingBtnText}</button> : null
							}
							{ coachingStatus === "coaching_approved" ?
								<button className={styles.request_coaching_btn} onClick={moveToCoaching}>{coachingBtnText}</button> : null
							}
							{ coachingStatus === "coaching_rejected" ?
								<button className={styles.request_coaching_btn} onClick={moveToAddCoaching}>{coachingBtnText}</button> : null
							}
							{ coachingStatus === "coaching_waiting_approval" ?
								<button className={styles.coaching_process_btn} onClick={moveToCoaching}>{coachingBtnText}</button> : null
							}
						</div>
					</div>
				</div> : null
			}
			<div className={styles.top}>
				<div className={styles.title_box}>
					<p className={styles.title}>WPI</p>
					<p className={styles.subtitle}>Whang's Personality Inventory</p>
				</div>
				<div className={styles.card_container}>
					<div className={styles.card}>
						<p className={styles.card_title}>현실</p>
						<p className={styles.card_subtitle}>한 사람의 성격특성과 라이프스타일까지<br/>정확하게 알려주는 마음의 MRI</p>
						{btnStatus1 === "before_test" ?
							<p state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 1}} className={styles.card_action_test}>{btnString1}</p> :
							<Link target={"_blank"} to={'/expert/test_result/' + location.state.paymentHistoryId + "/1"} className={styles.card_action}>{btnString1}</Link>
						}
					</div>
					<div className={styles.card}>
						<p className={styles.card_title}>이상</p>
						<p className={styles.card_subtitle}>내가 되고싶은 사람의 성격특성과<br/>라이프스타일까지 알려주는 MRI</p>
						{btnStatus2 === "before_test" ?
							<p state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 2}} className={styles.card_action_test}>{btnString2}</p> :
							<Link target={"_blank"} to={'/expert/test_result/' + location.state.paymentHistoryId + "/2"} className={styles.card_action}>{btnString2}</Link>
						}
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.title_box}>
					<p className={styles.title}>LSI</p>
					<p className={styles.subtitle}>Learning Style Indicator</p>
				</div>
				<div className={styles.card_container}>
					<div className={styles.card}>
						<p className={styles.card_title}>공부법</p>
						<p className={styles.card_subtitle}>나의 공부법 유형은<br/>무엇일까?</p>
						{btnStatus3 === "before_test" ?
							<p state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 3}} className={styles.card_action_test}>{btnString3}</p> :
							<Link target={"_blank"} to={'/expert/test_result/' + location.state.paymentHistoryId + "/3"} className={styles.card_action}>{btnString3}</Link>
						}
					</div>
					<div className={styles.card}>
						<p className={styles.card_title}>운동법</p>
						<p className={styles.card_subtitle}>나의 운동법 유형은<br/>무엇일까?</p>
						{btnStatus4 === "before_test" ?
							<p state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 4}} className={styles.card_action_test}>{btnString4}</p> :
							<Link target={"_blank"} to={'/expert/test_result/' + location.state.paymentHistoryId + "/4"} className={styles.card_action}>{btnString4}</Link>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExpertStudent;