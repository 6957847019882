import React, { useEffect, useState } from 'react';
import Header from './Header';
import styles from './MyOnlyTestList.module.css'
import TaskIcon from '@mui/icons-material/Task';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';
import OnlyTestCell from './OnlyTestCell';

const MyOnlyTestList = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/student/only_test_list/"

    const navigate = useNavigate();
	const location = useLocation();

	const [testList, setTestList] = useState([]);

	useEffect(() => {
        fetchMyTestList();
	}, []);

	function fetchMyTestList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			console.log(res.data);
			 
			let tempList = res.data["test_list"];
			tempList = tempList.sort( (a,b) => b.id - a.id );
		
			setTestList(tempList);
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

    function moveToTestResult(test) {
        navigate('/test_result', { state: {paymentHistoryId: test.payment_history, testClassId: test.test_class, from: "student"}});
    }

	return (
        <div>
            <Header account={"일반회원"} />
            <div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.test_title_group} onClick={()=>navigate(-1)}>
                        <ArrowBackIosIcon />
                        <p className={styles.test_title}>검사목록</p>
                    </div>
					{ testList && testList.map((test) => (
						<OnlyTestCell key={test.id} test={test} clickAction={moveToTestResult} />
					))}
                    { testList && testList.length === 0 ?
                        <p className={styles.hint}>진행하신 검사가 없습니다.<br/>구독결제로 진행한 검사는 홈화면에서 해당월을 선택하신 후 이용해주세요.</p> : null
                    }
                </div>
            </div>
        </div>
	);
};

export default MyOnlyTestList;