import React, { useRef } from 'react';
import styles from "./GroupSignUp.module.css"
import Header from './Header';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const GroupSignUp = (props) => {
      const EMPTY_MSG = "모든 항목을 입력해주세요";
      const SUCCESS_MSG = "가입신청이 완료되었습니다!";
      const URL = "https://api.wisdomlsi.com/api/v1/group_signup/";

      const navigate = useNavigate();

      const emailRef = useRef(null);
      const phoneNumberRef = useRef(null);
      const nameRef = useRef(null);
      const subjectRef = useRef(null);
      const numPeopleRef = useRef(null);
      const addressRef = useRef(null);
      const memoRef = useRef(null);

      function signUp() {
            if (!validate()) {
                  alert(EMPTY_MSG)
            } else {
                  const param = {
                        "email": emailRef.current.value,
                        "phone_number": phoneNumberRef.current.value,
                        "name": nameRef.current.value,
                        "subjects": subjectRef.current.value,
                        "num_people": numPeopleRef.current.value,
                        "address": addressRef.current.value,
                        "memo": memoRef.current.value,
                        "status": "requested"
                  }

                  axios({
                        method: 'post',
                        url: URL,
                        data: JSON.stringify(param),
                        headers: {
                              "Content-Type" : "application/json; charset=utf-8"
                        }
                  })
                  .then(function(response) {
                        alert(SUCCESS_MSG);
                        navigate('/');
                  })
                  .catch(function(error) {
                        console.log("");
                        console.log("ERROR : " + JSON.stringify(error));
                        console.log("");
                  });
            }
      }

      function validate() {
            if (
                  emailRef.current.value === "" || 
                  phoneNumberRef.current.value === "" || 
                  nameRef.current.value === "" || 
                  subjectRef.current.value === "" ||
                  numPeopleRef.current.value === "" ||
                  addressRef.current.value === "" ||
                  memoRef.current.value === "") {
                  return false;
            } else {
                  return true;
            }
      }

	return (
            <div>
                  <Header from="" />
                  <div className={styles.container}>
                        <div className={styles.container_inside}>
                              <div>
                                    <p className={styles.title}>그룹회원은 상담이후에 가입이 가능합니다</p>
                                    <p className={styles.subtitle}>하단의 정보를 입력하시거나<br/>센터로 문의주세요</p>
                              </div>
                              <div className={styles.input_group}>
                                    <input className={styles.input_box} type='text' placeholder='이메일' ref={emailRef}></input>
                                    <input className={styles.input_box} type='text' placeholder='전화번호' ref={phoneNumberRef}></input>
                                    <input className={styles.input_box} type='text' placeholder='업체명' ref={nameRef}></input>
                                    <input className={styles.input_box} type='text' placeholder='지도과목' ref={subjectRef}></input>
                                    <input className={styles.input_box} type='text' placeholder='정원' ref={numPeopleRef}></input>
                                    <input className={styles.input_box} type='text' placeholder='주소' ref={addressRef}></input>
                                    <textarea className={styles.input_big} placeholder='문의사항' ref={memoRef}></textarea>
                              </div>
                              <button className={styles.done_btn} id={styles.request_join} onClick={() => signUp()}>가입신청</button>
                              <hr className={styles.devider}></hr>
                              <div className={styles.info_box}>
                                    <div className={styles.info_option}>
                                          <LocalPhoneIcon />
                                          <div className={styles.info_option_inside}>
                                                <p className={styles.info_title}>02-6207-7430</p>
                                                <p className={styles.info_content}>09:00 ~ 18:00, 주말(토,일) 및 공휴일은 휴무<br/>
                                                02-6207-7431 (팩스번호)</p>
                                          </div>
                                    </div>
                                    <div className={styles.info_option}>
                                          <EmailIcon />
                                          <div className={styles.info_option_inside}>
                                                <p className={styles.info_title}>Email</p>
                                                <p className={styles.info_content}>wisdomcenter@naver.com</p>
                                          </div>
                                    </div>
                                    <div className={styles.info_option}>
                                          <LocationOnIcon />
                                          <div className={styles.info_option_inside}>
                                                <p className={styles.info_title}>Address</p>
                                                <p className={styles.info_content}>서울특별시 종로구 창덕궁 3가길 9</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
	);
};

export default GroupSignUp;