import React from 'react';
import styles from './StudentCell.module.css'
import { Link } from 'react-router-dom';

const StudentCell = (props) => {
	return (
		<div className={styles.container}>
            <div className={styles.title_group}>
                <div className={styles.info_group}>
                    <p className={styles.name}>{props.student.info.name}</p>
                    <p className={styles.email}>{props.student.email}</p>
                </div>
                <button className={styles.show_info} onClick={()=>props.action(props.student)}>정보보기</button>
            </div>
			<hr className={styles.devider} />
		</div>
	);
};

export default StudentCell;