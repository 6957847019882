import React, { useState, useEffect } from 'react';
import styles from './TestMonthCard.module.css';
import { Link } from 'react-router-dom';

const TestMonthCard = (props) => {
	const [paymentHistoryId, setPaymentHistoryId] = useState(null);
	const [dateString, setDateString] = useState([]);
	const [isDone, setIsDone] = useState(null);

    useEffect(() => {
		const now = new Date();
		const date = new Date(Date.parse(props.date));

		if (date.getFullYear() === now.getFullYear()) {
			setDateString((date.getMonth()+1) + "월");
		} else {
			setDateString((date.getFullYear()) + "년 " + (date.getMonth()+1) + "월");
		}

		setPaymentHistoryId(props.paymentHistoryId);
		setIsDone(props.isDone);
    }, [props.paymentHistoryId, props.date, props.isDone]);

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.month}>{dateString}</p>
				{ props.from === "student" ?
					<div>
						{ isDone ?
							<Link to={'/subscribe_test'} state={{paymentHistoryId: props.paymentHistoryId, from: props.from}} className={styles.status}>검사결과 보기</Link> :
							<Link to={'/subscribe_test'} state={{paymentHistoryId: props.paymentHistoryId, from: props.from}} className={styles.status}>검사 진행하기</Link>
						}
					</div> : null
				}
				{ props.from === "teacher" || props.from === "group" ?
					<div>
						{ isDone ?
							<Link to={'/subscribe_test'} state={{paymentHistoryId: props.paymentHistoryId, from: props.from}} className={styles.status}>검사결과 보기</Link> :
							<Link to={'/subscribe_test'} state={{paymentHistoryId: props.paymentHistoryId, from: props.from}} className={styles.status}>검사 진행중</Link>
						}
					</div> : null
				}
			</div>
			<hr className={styles.devider} />
		</div>
	);
};

export default TestMonthCard;