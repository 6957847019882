import React, { useState } from 'react';
import styles from "./Header.module.css"
import { useNavigate } from 'react-router-dom';
import SelectAccountTypeModal from './SelectAccountTypeModal';
import { Settings } from '@material-ui/icons';

const Header = (props) => {
    const [isModalShow, setIsModalShow] = useState(false)
    const navigate = useNavigate();

    const showModal = () => {
        return (
            setIsModalShow(!isModalShow)
        )
    }

	function moveToAccountSetting() {
		navigate("/account_setting", {state: {from: props.from}});;
	}

	function moveToHome() {
		if (props.from !== undefined) {
			navigate("/" + props.from, { state: {from: props.from}});
		}
	}

	function logOut() {
		navigate("/");
	}

	return (
		<div className={styles.header_top}>
			<div className={styles.brandGroup} onClick={moveToHome}>
				<img className={styles.logo} src={require('../wisdom_logo_small.png')}></img>
				<p className={styles.brand}>위즈덤센터</p>
			</div>
			{ props.account ?
				<div className={styles.account}>
					<p className={styles.account_option}>{window.localStorage.getItem("wisdom_username")}님</p>
					{ props.account ? <button className={styles.account_btn} onClick={showModal}>{props.account}</button> : null }
					<div className={styles.setting_btn} onClick={moveToAccountSetting}>
						<Settings className={styles.gear} />
						<p className={styles.account_txt}>계정설정</p>
					</div>
				</div> : null
			}
            { isModalShow ? <SelectAccountTypeModal className={styles.modal} modalAction={showModal} /> : "" }
			{ props.isGroup !== undefined ?
				<div className={styles.logout_group}>
					<button className={styles.logout} onClick={logOut}>로그아웃</button>
				</div> : null
			}
		</div>
	);
};

export default Header;