import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./KakaoLogIn.module.css"
import axios from 'axios';

const KakaoLogIn = (props) => {
    const WISDOM_URL = "https://api.wisdomlsi.com/api/v1/check_user_for_kakao/";
    const KAKAO_BASE_URL = "https://kapi.kakao.com";
    // const KAKAO_REDIRECT_URL = "http://localhost:3000/oauth";
    const KAKAO_REDIRECT_URL = "https://www.wisdomlsi.com/oauth";
    const KAKAO_REST_API_KEY = "9a0486d6a9b5f1527e2453efad1f51b6";
    const LOGIN_FOR_KAKAO_URL = "https://api.wisdomlsi.com/api/v1/login_for_kakao/";

    const navigate = useNavigate();

    function wait(sec) {
        let start = Date.now(), now = start;
        while (now - start < sec * 1000) {
            now = Date.now();
        }
    }

    useEffect(() => {
        const code = new URL(window.location.href).searchParams.get("code");
        const url= "https://kauth.kakao.com/oauth/token?grant_type=authorization_code" + "&client_id=" + KAKAO_REST_API_KEY + "&redirect_uri=" + KAKAO_REDIRECT_URL + "&code=" + code;

        console.log(code);

        axios({
            method: "post",
            url: url,
            headers: {
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        }).then((res) => {
            console.log(res);
            fetchKakaoInfo(res.data["access_token"]);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    function fetchKakaoInfo(accessToken) {
        axios({
            method: "get",
            url: `${KAKAO_BASE_URL}/v2/user/me`,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
        }).then((res) => {
            console.log(res);
            checkUserExist(res.data["id"], res.data["kakao_account"]["profile"]["nickname"]);
        })
    }

    function checkUserExist(username, name) {
        const param = {
            "username": username,
            "name": name
        }

        axios({
            method: "get",
            url: WISDOM_URL,
            params: param,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then((res) => {
            console.log(res.data);

            if (res.data["code"] === "not_joined") {
        		navigate('/make_profile', { state: {from: "kakao", username: username, name: name} });
            } else {
                logInForKakao(username);
            }
        })
    }

    function logInForKakao(username) {
        const param = {
            "username": username
        }

        axios({
            method: 'post',
            url: LOGIN_FOR_KAKAO_URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            console.log(response.data);
            const code = response.data["code"];

            if (code === "error") {
                alert(response.data["message"]);
            } else {
                const data = response.data["data"];
                console.log(data);
                
                const accessToken = data["token"]["access"];
                const refreshToken = data["token"]["refresh"];

                window.localStorage.setItem("wisdom_username", data["name"]);
                window.localStorage.setItem("wisdom_access", accessToken);
                window.localStorage.setItem("wisdom_refresh", refreshToken);

                navigate('/student', {state: {"from": "student"}});
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    return (
        <div className={styles.parent}>
        </div>
    );
};

export default KakaoLogIn;