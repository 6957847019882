import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import styles from './TestResult.module.css';
import { Link } from 'react-router-dom';
import Header from './Header'
import axios from 'axios';
import FeedbackCell from './FeedbackCell';
import AddFeedbackModal from './AddFeedbackModal';
import WisdomGraph from './WisdomGraph';

const TestResultExpert = () => {
    const RESULT_URL = "https://api.wisdomlsi.com/api/v1/test_result/"

	const navigate = useNavigate();
	const location = useLocation();

	const [studentName, setStudentName] = useState(null);
	const [testClass, setTestClass] = useState(null);
	const [dateText, setDateText] = useState(null);
	const [testResult, setTestResult] = useState(null);
	const [descList, setDescList] = useState([]);

    useEffect(() => {
		fetchResult();
    }, []);

	function fetchResult() {
        const tempList = window.location.pathname.split('/');
        const paymentHistoryId = tempList[tempList.length-2];
        const testClassId = tempList[tempList.length-1];

		const param = {
			"paymentHistoryId": paymentHistoryId,
			"testClassId": testClassId
		}

		axios({
			method: 'get',
			url: RESULT_URL,
			params: param,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			parseTestResult(response.data);
		})
		.catch(function(error) {
			console.log(error);

			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function parseTestResult(data) {
		setStudentName(data["student_name"]);
		setDateText(data["time_text"]);
		setDescList(data["desc_list"]);

		const testClass = data["test_class"];
		let result = {};

		const questionGroupList = data["question_group"];
		const questionGroups = new Array();
		const pointList = data["result"];
		let points = new Array();

		for (let i=0; i<pointList.length/2; i++) {
			points.push(pointList[i].point);
		}

		questionGroups.push({
			name: questionGroupList[0].name,
			data: points
		})

		points = [];

		for (let i=pointList.length/2; i<pointList.length; i++) {
			points.push(pointList[i].point);
		}

		questionGroups.push({
			name: questionGroupList[1].name,
			data: points
		})

		result["testClass"] = testClass;
		result["questionGroups"] = questionGroups
		result["categories"] = parseCategoryList(data);

		setTestResult(result);
	} 

	function parseCategoryList(data) {
		const pointGroupList = data["result"];
		const wpiCategoryList = new Array();

		if (data["test_class"].code === "wpi") {
			for (let j=0; j<pointGroupList.length/2; j++) {
				const pointGroup = pointGroupList[j];
				const newPointGroup = pointGroupList[j+5];
				const category = pointGroup.question_category.title + "/" + newPointGroup.question_category.title
				wpiCategoryList.push(category);
			}
		}

		const categoryGroupList = new Array();
		let categoryList = new Array();

		for (let j=0; j<pointGroupList.length/2; j++) {
			const pointGroup = pointGroupList[j];
			categoryList.push(pointGroup.question_category.title);
		}

		categoryGroupList.push(categoryList);
		categoryList = [];

		for (let j=pointGroupList.length/2; j<pointGroupList.length; j++) {
			const pointGroup = pointGroupList[j];
			categoryList.push(pointGroup.question_category.title);
		}

		categoryGroupList.push(categoryList);

		return {
			"wpiCategoryList": wpiCategoryList,
			"categoryList": categoryGroupList
		};
	}

	return (
		<div>
			<div className={styles.container}>
				<div className={styles.container_inside}>
					<p className={styles.title}>결과확인</p>
					{ testClass ? <p className={styles.desc}>{studentName}님의 {dateText}<br/>{testClass.name} 검사결과입니다</p> : null }
					{ testResult ?
						<div>
							{ testResult.testClass.code === "wpi" ?
								<div>
									{ testResult ?
										<WisdomGraph 
											dataList={testResult.questionGroups} 
											categoryList={testResult.categories.wpiCategoryList}
											graph={"항목"}
											colors={[ '#ff2945', '#2965ff' ]}
										/> : null
									}
								</div>:
								<div>
									{ testResult ? 
										<div>
											<WisdomGraph 
												dataList={[testResult.questionGroups[0]]} 
												categoryList={testResult.categories.categoryList[0]}
												graph={testResult.questionGroups.name}
												colors={[ '#ff2945' ]}
											/>
											<WisdomGraph 
												dataList={[testResult.questionGroups[1]]} 
												categoryList={testResult.categories.categoryList[1]}
												graph={testResult.questionGroups.name}
												colors={[ '#2965ff' ]}
											/>
										</div> : null
									}
								</div>
							}
							<table>
								<tr>
									<td className={styles.table_title} rowSpan="2">{testResult.questionGroups[0].name}</td>
									{ testResult.categories.categoryList[0].map((category) => (
										<td>{category}</td>
									))}
								</tr>
									{ testResult.questionGroups[0].data.map((point) => (
										<td>{point}</td>
									))}
								<tr>
									<td className={styles.table_title} rowSpan="2">{testResult.questionGroups[1].name}</td>
									{ testResult.categories.categoryList[1].map((category) => (
										<td>{category}</td>
									))}
								</tr>
								<tr>
									{ testResult.questionGroups[1].data.map((point) => (
										<td>{point}</td>
									))}
								</tr>
							</table>
						</div> : null
					}
					<div className={styles.desc_group}>
						<p className={styles.desc_title}>설명</p>
						{ descList && descList.map((desc) => (
							<img className={styles.desc} src={desc} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TestResultExpert;