import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header'
import StudentCell from './StudentCell';
import styles from './Teacher.module.css'
import axios from 'axios';

const Teacher = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/teacher/home/";
	const navigate = useNavigate();
	const [group, setGroup] = useState(null);
	const [studentList, setStudentList] = useState([]);

	useEffect(() => {
		fetchStudentList();
	}, []);

	function fetchStudentList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setGroup(response.data["group"]);
			setStudentList(response.data["student_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}

	function moveToStudent(student) {
		navigate("/student", { state: {student: student.info, from: "teacher"}})
	}

	return (
		<div>
			<Header from={"teacher"} account={"지도자"} />
			<div className={styles.container}>
				<div className={styles.container_inside}>
					<div className={styles.title_box}>
						<p className={styles.group_name}>{group && group.name}</p>
						<p className={styles.student_list}>연결된 회원 {studentList.length}명</p>
					</div>
                    { studentList && studentList.map((student) => (
						<StudentCell key={student.info.id} student={student} action={moveToStudent} />
					))}
					{ studentList.length == 0 ? <p className={styles.empty_msg}>연결된 회원이 없습니다.</p> : null }
				</div>
			</div>
		</div>
	);
};

export default Teacher;