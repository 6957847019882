import React, { useEffect, useState, useRef } from 'react';
import styles from './CoachingHistoryCell.module.css'

const CoachingHistoryCell = (props) => {
    const coaching = props.coaching;
    const expertList = props.expertList;

    function convertUtcToLocal(utcDateString) {
        const utcDate = new Date(utcDateString);
        const localTime = new Date(utcDate.getTime());
        return localTime;
    }

	const getExpert = (coaching) => {
		let expert = null;

		for (let exp of expertList) {
			if (exp.expert_id === coaching.expert) {
				expert = exp;
			}
		}

		return expert;
	}

    return (
        <div className={styles.parent}>
            <div className={styles.status_box}>
                { coaching.is_approved ? 
                    <div className={styles.status_inner}>
                        <p className={styles.approve_status}>승인됨</p>
                        <p className={styles.date}>{convertUtcToLocal(coaching.approve_time).toLocaleString()}</p> 
                    </div> : null
                }
                { coaching.is_rejected ? 
                    <div className={styles.status_inner}>
                        <p className={styles.reject_status}>반려됨</p> 
                        <p className={styles.date}>{convertUtcToLocal(coaching.reject_time).toLocaleString()}</p> 
                    </div> : null
                }
            </div>
            <div className={styles.msg_box}>
                <p className={styles.msg_title}>전문가</p>
                <p className={styles.content}>{getExpert(coaching).name} ({getExpert(coaching).email})</p>
            </div>
            <div className={styles.msg_box}>
                <p className={styles.msg_title}>분석내용</p>
                <p className={styles.content}>{coaching.content}</p>
            </div>
            <div className={styles.msg_box}>
                <p className={styles.msg_title}>회원 메시지</p>
                <p className={styles.content}>{coaching.to_student}</p>
            </div>
            <div className={styles.msg_box}>
                <p className={styles.msg_title}>지도자 메시지</p>
                <p className={styles.content}>{coaching.to_teacher}</p>
            </div>
            <div className={styles.msg_box}>
                <p className={styles.msg_title}>슈퍼바이저 피드백</p>
                <p className={styles.content}>{coaching.reject_text}</p>
            </div>
            <hr className={styles.devider}/>
        </div>
    );
}

export default CoachingHistoryCell;