import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from "./SelectAccountTypeModal.module.css"
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const SelectAccountTypeModal = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/check_user_accounts/";

	const navigate = useNavigate();

	const [username, setUsername] = useState(null);
	const [hasTeacher, setHasTeacher] = useState(null);
	const [hasExpert, setHasExpert] = useState(null);
	const [hasSupervisor, setHasSupervisor] = useState(null);

	useEffect(() => {
		checkUserAccounts();
	}, []);

	function checkUserAccounts() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setUsername(response.data["name"]);
			setHasTeacher(response.data["has_teacher"]);
			setHasExpert(response.data["has_expert"]);
			setHasSupervisor(response.data["has_supervisor"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<div className={styles.title_box}>
					<p className={styles.modal_title}>안녕하세요, { username }님</p>
					<p className={styles.modal_title}>로그인하실 계정을 선택해주세요</p>
				</div>
				<div className={styles.option_container}>
					<Link className={styles.option} to="/student" state={{ from: "student"}}>일반회원</Link>
					{ hasTeacher ? <Link className={styles.option} to="/teacher">지도자</Link> : null }
					{ hasExpert ? <Link className={styles.option} to="/expert">전문가</Link> : null }
					{ hasSupervisor ? <Link className={styles.option} to="/supervisor">슈퍼바이저</Link> : null }
					{ props.from === "login" ? null : 
						<button className={styles.logout} onClick={()=>navigate("/")}>로그아웃</button>
					}
					<button className={styles.cancel} onClick={props.modalAction}>취소</button>
				</div>
			</div>
		</div>
	);
};

export default SelectAccountTypeModal;