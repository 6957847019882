import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./InviteUserModal.module.css"
import axios from 'axios';

const InviteUserModal = (props) => {
    const emailRef = useRef("");
    const STUDENT_URL = "https://api.wisdomlsi.com/api/v1/group/invite_student/";
    const TEACHER_URL = "https://api.wisdomlsi.com/api/v1/group/add_teacher/";

    function invite() {
        if (emailRef.current.value === "") {
            alert("이메일을 입력해주세요.");
        } else {
            const param = {
                "email": emailRef.current.value
            }

            let url = ""

            if (props.for === "student") {
                url = STUDENT_URL
            } else if (props.for === "teacher") {
                url = TEACHER_URL
            }

            axios({
                method: 'post',
                url: url,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                if (response.data["code"] === "error") {
                    alert(response.data["message"]);
                } else if (response.data["code"] === "success") {
                    props.modalControl();
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.actionTitle}하실 {props.memberTitle}의 이메일을 입력해주세요</p>
                <input className={styles.email} type="text" placeholder="OOO@wisdom.com" ref={emailRef}></input>
                <div className={styles.btn_group}>
                    <button className={styles.invite_btn} onClick={invite}>{props.actionTitle}하기</button>
                    <p className={styles.space}></p>
                    <button className={styles.cancel_btn} onClick={()=>props.modalControl()}>취소</button>
                </div>
			</div>
		</div>
	);
};

export default InviteUserModal;