import React, { useEffect, useState } from 'react';
import styles from './OnlyTestList.module.css';
import Header from './Header';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import CouponModal from './CouponModal';
import axios from 'axios';

let selectedTestClassCode = "";
let justTestActivated = false;

const OnlyTestList = (props) => {
    const URL = "https://api.wisdomlsi.com/api/v1/fetch_only_test_list/";
    const ADD_PAY_INFO_URL = "https://api.wisdomlsi.com/api/v1/purchase_only_test/"
    const MY_INFO_URL = "https://api.wisdomlsi.com/api/v1/my_info/";

	const [selectedTestClass, setSelectedTestClass] = useState(null);
    const navigate = useNavigate();

    const [isCouponModalShow, setIsCouponModalShow] = useState(false);
    const [testList, setTestList] = useState(false);
    const [myInfo, setMyInfo] = useState(null);

	useEffect(() => {
        fetchTestList();
        fetchMyInfo();
	}, []);

    function fetchTestList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
            setTestList(response.data["data"]);
            checkJustTestActivated(response.data["data"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function fetchMyInfo () {
		axios({
			method: 'get',
			url: MY_INFO_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
            setMyInfo(response.data);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function requestPay(testClassCode) {
        let testClassName = "";
        let price = 0;

        if (testClassCode === "wpi1") {
            testClassName = "WPI현실 검사";
            price = 11000;
        } else if (testClassCode === "wpi2") {
            testClassName = "WPI이상 검사";
            price = 11000;
        } else if (testClassCode === "study") {
            testClassName = "LSI공부 검사";
            price = 22000;
        } else {
            testClassName = "LSI운동 검사";
            price = 22000;
        }

        console.log(testClassName);
        console.log(price);

        const storeId = "INIpayTest";
        const IMP = window.IMP;
        IMP.init("imp77241360");

        IMP.request_pay({ 
            pg: "html5_inicis." + storeId,
            pay_method: "card",
            merchant_uid: (new Date()).toString(),
            name: testClassName,
            // amount: price,
            amount: 100,
            buyer_email: myInfo.info.email,
            buyer_name: myInfo.info.student.name,
            buyer_tel: myInfo.info.student.phone_number
        }, rsp => { 
            if (rsp.success) {
                addPayInfoToServer(rsp.imp_uid, price, testClassCode);
            } else {
                alert(rsp.error_msg);
            }
        });
    }

    function addPayInfoToServer(portOneId, price, testClassCode) {
		axios({
			method: 'post',
			url: ADD_PAY_INFO_URL,
            data: {
                "port_one_payment_id": portOneId,
                "price": price,
                "test_class_code": testClassCode
            },
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

            selectedTestClassCode = testClassCode;
            justTestActivated = true;
            fetchTestList();
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function checkJustTestActivated(data) {
        if (justTestActivated) {
            justTestActivated = false;

            let test_class_id = 0;

            if (selectedTestClassCode === "wpi1") {
                test_class_id = 1;
            } else if (selectedTestClassCode === "wpi2") {
                test_class_id = 2;
            } else if (selectedTestClassCode === "study") {
                test_class_id = 3;
            } else if (selectedTestClassCode === "exercise") {
                test_class_id = 4;
            }

            moveToTest(data[selectedTestClassCode].payment_history_id, test_class_id);
        }
    }

	function selectTestClass(testClassCode) {
		setSelectedTestClass(testClassCode);
	}

    function goBack() {
		navigate("/student", { state: {from: "student"}});
    }

    function showCouponModal(testClassCode) {
        selectedTestClassCode = testClassCode;
        setIsCouponModalShow(true);
    }

    function couponModalAction() {
        setIsCouponModalShow(false);
    }

    function useCouponDone() {
        setIsCouponModalShow(false);
        justTestActivated = true;
        fetchTestList();
    }

    function moveToTest(paymentHistoryId, testClassId) {
        navigate('/test', { state: {paymentHistoryId: paymentHistoryId, testClassId: testClassId}});
    }

    function moveToMyOnlyTestList() {
        navigate('/my_only_test_list');
    }

	return (
		<div>
            <Header account={"일반회원"} from={"student"} />
            <div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.top_box}>
                        <div className={styles.back} onClick={goBack}>
                            <ArrowBackIos className={styles.back_arrow} />
                            <p>검사하기</p>
                        </div>
                        <button className={styles.my_test_list} onClick={moveToMyOnlyTestList}>내 지난 검사목록</button>
                    </div>
                    <div>
                        <p className={styles.title}>추가검사 목록</p>
						<p className={styles.desc}>원하시는 검사를 선택해주세요</p>
                        <div className={styles.refund_group}>
                            <p className={styles.refund_title}>환불규정</p>
                            <p className={styles.refund_subtitle}>아래와 같은 사유에 대해 환불가능합니다</p>
                            <ul>
                                <p className={styles.refund_content}>1. 각 검사를 구매후 14일 이내</p>
                                <p className={styles.refund_content}>2. 구매 후 검사를 진행하지 않은 경우</p>
                            </ul>
                        </div>
                        <div className={styles.active} onClick={()=>selectTestClass("wpi1")}>
                            <div>
                                <p className={styles.product_title}>WPI현실 검사</p>
                            </div>
                            <p className={styles.product_desc}>자신의 성격과 성향을 알수있는 심리 검사 입니다.</p>
                            <p className={styles.price}>11,000원</p>
                            <div>
                                { testList && testList.wpi1.status === "empty" ?
                                    <button className={styles.do_btn} onClick={()=>requestPay("wpi1")}>결제하기</button> :
                                    <button className={styles.do_btn} onClick={()=>moveToTest(testList.wpi1.payment_history_id, 1)}>바로 진행하기</button>
                                }
                                <button className={styles.coupon_btn} onClick={()=>showCouponModal("wpi1")}>이용권 사용하기</button>
                            </div>
                        </div> 
                        <div className={styles.active} onClick={()=>selectTestClass("wpi2")}>
                            <div>
                                <p className={styles.product_title}>WPI이상 검사</p>
                            </div>
                            <p className={styles.product_desc}>자신이 이상적으로 생각하는 성격과 성향을 알수있는 심리 검사 입니다.</p>
                            <p className={styles.price}>11,000원</p>
                            <div>
                                { testList && testList.wpi2.status === "empty" ?
                                    <button className={styles.do_btn} onClick={()=>requestPay("wpi2")}>결제하기</button> :
                                    <button className={styles.do_btn} onClick={()=>moveToTest(testList.wpi2.payment_history_id, 2)}>바로 진행하기</button>
                                }
                                <button className={styles.coupon_btn} onClick={()=>showCouponModal("wpi2")}>이용권 사용하기</button>
                            </div>
                        </div> 
                        <div className={styles.active} onClick={()=>selectTestClass("study")}>
                            <div>
                                <p className={styles.product_title}>LSI공부 검사</p>
                            </div>
                            <p className={styles.product_desc}>자신의 공부 스타일과 마음을 파악하고, 자신에게 맞는 공부방법을 찾는 심리 검사 입니다.</p>
                            <p className={styles.price}>22,000원</p>
                            <div>
                                { testList && testList.study.status === "empty" ?
                                    <button className={styles.do_btn} onClick={()=>requestPay("study")}>결제하기</button> :
                                    <button className={styles.do_btn} onClick={()=>moveToTest(testList.study.payment_history_id, 3)}>바로 진행하기</button>
                                }
                                <button className={styles.coupon_btn} onClick={()=>showCouponModal("study")}>이용권 사용하기</button>
                            </div>
                        </div>
                        <div className={styles.active} onClick={()=>selectTestClass("exercise")}>
                            <div>
                                <p className={styles.product_title}>LSI운동 검사</p>
                            </div>
                            <p className={styles.product_desc}>자신의 운동 스타일과 마음을 파악하고, 자신에게 맞는 운동방법을 찾는 심리 검사 입니다.</p>
                            <p className={styles.price}>22,000원</p>
                            <div>
                                {/* <button className={styles.do_btn} onClick={()=>requestPay()}>바로 진행하기</button> */}
                                { testList && testList.exercise.status === "empty" ?
                                    <button className={styles.do_btn} onClick={()=>requestPay("exercise")}>결제하기</button> :
                                    <button className={styles.do_btn} onClick={()=>moveToTest(testList.exercise.payment_history_id, 4)}>바로 진행하기</button>
                                }
                                <button className={styles.coupon_btn} onClick={()=>showCouponModal("exercise")}>이용권 사용하기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { isCouponModalShow ? <CouponModal modalAction={couponModalAction} testClassCode={selectedTestClassCode} useCouponDone={useCouponDone} /> : null }
		</div>
	);
};

export default OnlyTestList;