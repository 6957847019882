import React, { useRef } from 'react';
import styles from "./NormalSignUp.module.css"
import Header from "./Header"
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

const NormalSignUp = (props) => {
	const EMAIL_VALID_URL = "https://api.wisdomlsi.com/api/v1/check_email_exist/"

    const emailRef = useRef("");
    const passwordRef = useRef("");
    const passwordConfirmRef = useRef("");
    const navigate = useNavigate();

	function checkEmailExist() {
		axios({
			method: 'get',
			url: EMAIL_VALID_URL,
			params: { "email": emailRef.current.value },
			headers: {
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

			if (response.data["validation"]) {
        		navigate('/make_profile', { state: {from: "normal", email: emailRef.current.value, password: passwordRef.current.value} });
			} else {
				alert("이미 존재하는 계정입니다.");
			}
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}

	function moveToNext() {
		if (emailRef.current.value === "") {
			alert("이메일을 입력해주세요.");
		} else if (passwordRef.current.value === "") {
			alert("비밀번호를 입력해주세요.");
		} else if (passwordConfirmRef.current.value === "") {
			alert("비밀번호를 한번 더 입력해주세요.");
		} else if (passwordRef.current.value != passwordConfirmRef.current.value) {
			alert("입력하신 비밀번호가 일치하지 않습니다.");
		} else {
			checkEmailExist()
		}
	}

	return (
		<div>
				<Header from="" />
				<div className={styles.container}>
					<div className={styles.container_inside}>
							<div>
								<p className={styles.title}>일반 회원가입</p>
								<p className={styles.subtitle}>하단의 정보를 입력해주세요</p>
							</div>
							<div className={styles.input_group}>
								<input className={styles.input_box} type='text' placeholder='이메일' ref={emailRef}></input>
								<input className={styles.input_box} type='password' placeholder='비밀번호' ref={passwordRef}></input>
								<input className={styles.input_box} type='password' placeholder='비밀번호 확인' ref={passwordConfirmRef}></input>
							</div>
							<button className={styles.done_btn} onClick={moveToNext}>회원가입</button>
					</div>
				</div>
		</div>
	);
};

export default NormalSignUp;