import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "./MakeProfile.module.css"
import Header from "./Header"
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import CustomDatePicker from './CustomDatePicker';

const MakeProfile = (props) => {
    const LOGIN_URL = "https://api.wisdomlsi.com/api/v1/login/";
    const SIGNUP_URL = "https://api.wisdomlsi.com/api/v1/signup/";
    const LOGIN_FOR_KAKAO_URL = "https://api.wisdomlsi.com/api/v1/login_for_kakao/";
    const SIGNUP_FOR_KAKAO_URL = "https://api.wisdomlsi.com/api/v1/signup_for_kakao/";

    const emailRef = useRef("");
    const nameRef = useRef("");
    const phonenumberRef = useRef("");
    const addressRef = useRef("");
    const [birth, setBirth] = useState(new Date());
    const [gender, setGender] = useState("");
    
    const location = useLocation();
    const navigate = useNavigate();

    function logIn() {
        const param = {
            "email": location.state.email,
            "password": location.state.password
        }

        axios({
            method: 'post',
            url: LOGIN_URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];

            if (code === "error") {
                alert(response.data["message"]);
            } else {
                const data = response.data["data"];
                console.log(data);
                
                const accessToken = data["token"]["access"];
                const refreshToken = data["token"]["refresh"];

                window.localStorage.setItem("wisdom_username", data["name"]);
                window.localStorage.setItem("wisdom_access", accessToken);
                window.localStorage.setItem("wisdom_refresh", refreshToken);

                navigate('/student', {state: {"from": "student"}});
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function logInForKakao() {
        const param = {
            "username": location.state.username
        }

        axios({
            method: 'post',
            url: LOGIN_FOR_KAKAO_URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];

            if (code === "error") {
                alert(response.data["message"]);
            } else {
                const data = response.data["data"];
                console.log(data);
                
                const accessToken = data["token"]["access"];
                const refreshToken = data["token"]["refresh"];

                window.localStorage.setItem("wisdom_username", data["name"]);
                window.localStorage.setItem("wisdom_access", accessToken);
                window.localStorage.setItem("wisdom_refresh", refreshToken);

                navigate('/student', {state: {"from": "student"}});
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function selectGender(gender) {
        setGender(gender);
    }

    function validate() {
        if (nameRef.current.value === "") {
            alert("이름을 입력해주세요");
            return false;
        } else if (phonenumberRef.current.value === "") {
            alert("전화번호를 입력해주세요");
            return false;
        } else if (addressRef.current.value === "") {
            alert("주소를 입력해주세요");
            return false;
        } else if (gender === "") {
            alert("성별을 선택해주세요");
            return false;
        } else {
            return true;
        }
    }

    function signUp() {
        if (location.state.from === "normal") {
            signUpForNormal();
        } else {
            signUpForKakao();
        }
    }

    function signUpForNormal() {
        if (!validate()) {
            return;
        }

        const param = {
            "email": location.state.email,
            "password": location.state.password,
            "name": nameRef.current.value,
            "phonenumber": phonenumberRef.current.value,
            "birth": getBirth(),
            "gender": gender,
            "address": addressRef.current.value
        }

        axios({
            method: 'post',
            url: SIGNUP_URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];
            console.log(response.data);

            if (code === "success") {
                alert("회원가입이 완료되었습니다!");
                logIn();
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function signUpForKakao() {
        if (!validate()) {
            return;
        }

        const param = {
            "email": emailRef.current.value,
            "username": location.state.username,
            "name": nameRef.current.value,
            "phonenumber": phonenumberRef.current.value,
            "birth": getBirth(),
            "gender": gender,
            "address": addressRef.current.value
        }

        axios({
            method: 'post',
            url: SIGNUP_FOR_KAKAO_URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            console.log(response.data);
            let code = response.data["code"];

            if (code === "success") {
                alert("회원가입이 완료되었습니다!");
                logInForKakao();
            } else if (code === "error") {
                alert(response.data["msg"]);
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function getBirth() {
        const year = birth.getFullYear().toString()
        let month = (birth.getMonth()+1).toString()
        let date = birth.getDate().toString()

        if (birth.getMonth()+1 < 10) {
            month = "0" + month
        }

        if (birth.getDate() < 10) {
            date = "0" + date
        }

        return `${year}-${month}-${date}`;
    }

	return (
		<div>
			<Header from="" />
            <div className={styles.container}>
                <div className={styles.input_container}>
                    <h2 className={styles.title}>환영합니다!</h2>
                    <p className={styles.title_hint}>회원가입을 위해 아래 정보를 입력해주세요</p>
                    { location.state.from === "kakao" ?
                        <div className={styles.input_group}>
                            <p className={styles.input_hint}>이메일</p>
                            <input className={styles.input_box} type="text" ref={emailRef}></input><br/>
                        </div> : null
                    }
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>이름</p>
                        <input className={styles.input_box} type="text" ref={nameRef}></input><br/>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>전화번호</p>
                        <input className={styles.input_box} type="text" ref={phonenumberRef}></input><br/>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>성별</p>
                        <div className={styles.gender_group}>
                            { gender !== "male" ?
                                <button className={styles.gender_btn_deactive} onClick={()=>selectGender("male")}>남</button> :
                                <button className={styles.gender_btn} onClick={()=>selectGender("male")}>남</button>
                            }
                            { gender !== "female" ?
                                <button className={styles.gender_btn_deactive} onClick={()=>selectGender("female")}>여</button> :
                                <button className={styles.gender_btn} onClick={()=>selectGender("female")}>여</button>
                            }
                        </div>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>생년월일</p>
                        <div className={styles.date_picker_container}>
                            <CustomDatePicker dateChanged={(date)=>setBirth(date)} />
                        </div>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>주소</p>
                        <input className={styles.input_box} type="text" ref={addressRef}></input><br/>
                    </div>
                    <button className={styles.signup} onClick={()=>signUp()}>시작하기</button>
                </div>
            </div>
		</div>
	);
};

export default MakeProfile;