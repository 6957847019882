import React from 'react';
import styles from "./Footer.module.css"
import { useNavigate } from 'react-router-dom';

const Footer = (props) => {
      const navigate = useNavigate();

      function moveToPrivacyPolicy() {
            navigate("/privacy_policy");
      }

      function moveToService() {
            navigate("/service");
      }

	return (
		<div className={styles.parent}>
            <p className={styles.text}>WPI검사는 저작권등록이 되어 있는 저작물로, 검사지 일부 또는 전부를 무단 복제하면 저작권법 제 98조에 의해 3년 이하의 징역 또는 3천만원 이하의 벌금이 부과됩니다</p>
            <p className={styles.text}>주식회사 위즈덤센터 | 대표:이은주 | 주소:서울특별시 종로구 창덕궁 3가길 9,지하 1층 | 전화번호: 02-6207-7430 팩스: 02-6207-7431 | 이메일: wisdomcenter@naver.com</p>
            <p className={styles.text}>사업자등록번호: 120-86-65238 | 통신판매신고번호: 제 2020-서울종로-0540 호 | 개인정보보호책임자: 이은주</p>
            <div className={styles.policy}>
                  <p className={styles.privacy} onClick={moveToPrivacyPolicy}>개인정보처리방침</p>
                  <p className={styles.divider}>|</p>
                  <p className={styles.service} onClick={moveToService}>서비스 이용약관</p>
            </div>
            <p className={styles.text}>ⓒ (주)위즈덤센터 All right reserved.</p>
		</div>
	);
};

export default Footer;