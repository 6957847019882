import React from 'react';
import styles from './TeacherStudentCell.module.css'
import { Link } from 'react-router-dom';

const TeacherStudentCell = (props) => {
	return (
        <div className={styles.container}>
            <div className={styles.container_inside}>
                <div className={styles.info_box}>
                    <p className={styles.name}>{props.name}</p>
                    <p className={styles.email}>{props.email}</p>
                </div>
                <div className={styles.status_box}>
                    <button className={styles.action_btn} onClick={()=>props.disconnectAction(props.student)}>연결해제</button>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default TeacherStudentCell;