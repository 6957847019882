import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GroupTeacherCell from './GroupTeacherCell';
import styles from "./GroupTeacherList.module.css"
import InviteUserModal from './InviteUserModal';
import DeleteTeacherModal from './DeleteTeacherModal';
import axios from 'axios';

const GroupTeacherList = (props) => {
    const TEACHER_LIST_URL = "https://api.wisdomlsi.com/api/v1/group/teachers/"
    const [isInviteModalShow, setIsInviteModalShow] = useState(false)
    const [isDeleteTeacherModalShow, setDeleteTeacherModalShow] = useState(false)
    const [willBeDeletedTeacher, setWillBeDeletedTeacher] = useState(null);
    const [teacherList, setTeacherList] = useState([]);

    const navigate = useNavigate()

    const showInviteModal = () => {
        if (isInviteModalShow) {
            fetchTeacherList();
        }

        return (
            setIsInviteModalShow(!isInviteModalShow)
        )
    }

    const showDeleteTeahcerModal = () => {
        if (isDeleteTeacherModalShow) {
            fetchTeacherList();
        }

        return (
            setDeleteTeacherModalShow(!isDeleteTeacherModalShow)
        )
    }

    function deleteTeacher(teacher) {
        setWillBeDeletedTeacher(teacher);
        showDeleteTeahcerModal();
    }

    function fetchTeacherList() {
		axios({
			method: 'get',
			url: TEACHER_LIST_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setTeacherList(response.data["data"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

	useEffect(() => {
        fetchTeacherList();
	}, []);

    function modifyInfo(teacherId) {
        props.teacherAction(teacherId);
        props.indexAction(100);
    }

	return (
        <div className={styles.container}>
            <div className={styles.group_top}>
                <p className={styles.title}>지도자 {teacherList.length}명</p>
                <button className={styles.action_btn} onClick={showInviteModal}>추가하기</button>
            </div>
            <div className={styles.info_box}>
                <div className={styles.info_box_inside}>
                    <p className={styles.info_name}>이름</p>
                    <p className={styles.hint}>매칭된 학생수</p>
                </div>
                <hr className={styles.devider}/>
            </div>
            { teacherList.length == 0 ?
                <p className={styles.default_msg}>아직 그룹에 초대된 지도자가 없어요</p> :
                null
            }
            {teacherList && teacherList.map((teacher) => (
                <GroupTeacherCell key={teacher.info.id} teacher={teacher} modifyAction={modifyInfo} deleteAction={deleteTeacher} />
            ))}
            { isInviteModalShow ? <InviteUserModal modalControl={showInviteModal} for="teacher" memberTitle="지도자" actionTitle="추가" /> : null }
            { isDeleteTeacherModalShow ? <DeleteTeacherModal teacher={willBeDeletedTeacher} modalControl={showDeleteTeahcerModal} /> : null }
        </div>
	);
};

export default GroupTeacherList;