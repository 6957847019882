import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './EditAccountInfo.module.css';
import CustomDatePicker from "./CustomDatePicker";
import Header from "./Header";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from "axios";

const EditAccountInfo = () => {
    const EDIT_ACCOUNT_INFO_URL = "https://api.wisdomlsi.com/api/v1/edit_account_info/";

    const navigate = useNavigate();
    const location = useLocation();

    const nameRef = useRef("");
    const phonenumberRef = useRef("");
    const addressRef = useRef("");
    const [birth, setBirth] = useState(new Date());
    const [gender, setGender] = useState("");

	useEffect(() => {
        nameRef.current.value = location.state.info.student.name;
        phonenumberRef.current.value = location.state.info.student.phone_number;
        addressRef.current.value = location.state.info.student.address;

        setGender(location.state.info.student.gender);

        const birthString = location.state.info.student.birth;
        const userBirth = new Date(birthString);

		setBirth(userBirth);
	}, []);

    const saveEditAccountInfo = () => {
        if (!validate()) {
            return;
        }

        const param = {
            "name": nameRef.current.value,
            "phonenumber": phonenumberRef.current.value,
            "birth": getBirth(),
            "gender": gender,
            "address": addressRef.current.value
        }

        axios({
            method: 'post',
            url: EDIT_ACCOUNT_INFO_URL,
            data: JSON.stringify(param),
            headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];
            console.log(response.data);
            
            window.localStorage.setItem("wisdom_username", nameRef.current.value);

            if (code === "success") {
                moveBack();
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function validate() {
        if (nameRef.current.value === "") {
            alert("이름을 입력해주세요");
            return false;
        } else if (phonenumberRef.current.value === "") {
            alert("전화번호를 입력해주세요");
            return false;
        } else if (addressRef.current.value === "") {
            alert("주소를 입력해주세요");
            return false;
        } else if (gender === "") {
            alert("성별을 선택해주세요");
            return false;
        } else {
            return true;
        }
    }

    function selectGender(gender) {
        setGender(gender);
    }

    function getBirth() {
        const year = birth.getFullYear().toString()
        let month = (birth.getMonth()+1).toString()
        let date = birth.getDate().toString()

        if (birth.getMonth()+1 < 10) {
            month = "0" + month
        }

        if (birth.getDate() < 10) {
            date = "0" + date
        }

        return `${year}-${month}-${date}`;
    }

    const moveBack = () => {
        navigate(-1);
    }

    return (
        <div>
			<Header from={location.state.from} />
            <div className={styles.container}>
                <div className={styles.input_container}>
                    <div className={styles.title_box} onClick={moveBack}>
				        <ArrowBackIosIcon className={styles.back_arrow} />
                        <h2 className={styles.title}>계정정보 수정</h2>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>이름</p>
                        <input className={styles.input_box} type="text" ref={nameRef}></input><br/>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>전화번호</p>
                        <input className={styles.input_box} type="text" ref={phonenumberRef}></input><br/>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>성별</p>
                        <div className={styles.gender_group}>
                            { gender !== "male" ?
                                <button className={styles.gender_btn_deactive} onClick={()=>selectGender("male")}>남</button> :
                                <button className={styles.gender_btn} onClick={()=>selectGender("male")}>남</button>
                            }
                            { gender !== "female" ?
                                <button className={styles.gender_btn_deactive} onClick={()=>selectGender("female")}>여</button> :
                                <button className={styles.gender_btn} onClick={()=>selectGender("female")}>여</button>
                            }
                        </div>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>생년월일</p>
                        <div className={styles.date_picker_container}>
                            <CustomDatePicker dateChanged={(date)=>setBirth(date)} />
                        </div>
                    </div>
                    <div className={styles.input_group}>
                        <p className={styles.input_hint}>주소</p>
                        <input className={styles.input_box} type="text" ref={addressRef}></input><br/>
                    </div>
                    <button className={styles.signup} onClick={()=>saveEditAccountInfo()}>저장하기</button>
                </div>
            </div>
        </div>
    );
}

export default EditAccountInfo;