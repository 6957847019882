import React, { useState, useEffect } from 'react';
import styles from './PaymentHistoryCell.module.css';

const PaymentHistoryCell = (props) => {
	const [time, setTime] = useState(null);
	const [payType, setPayType] = useState(null);

	useEffect(() => {
		setTime(props.history.payment_time.split("T")[0]);

		if (props.history.payment_type === "subscribe") {
			setPayType("구독결제");
		} else {
			setPayType("일반결제");
		}
	}, []);

	return (
		<div className={styles.parent}>
			<div className={styles.title_group}>
            	<p className={styles.title}>{props.history.price}원</p>
            	<p className={styles.type}>{payType}</p>
			</div>
            <p className={styles.time}>{time}</p>
		</div>
	);
};

export default PaymentHistoryCell;