import React, { useEffect, useState } from 'react';
import Header from './Header';
import styles from './AccountSetting.module.css'
import { Home, Settings } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import PaymentHistoryCell from './PaymentHistoryCell';

const AccountSetting = (props) => {
	const [groupList, setGroupList] = useState([]);
	const [info, setInfo] = useState(null);
	const [birth, setBirth] = useState(null);
	const [gender, setGender] = useState(null);
	const [paymentHistory, setPaymentHistory] = useState([]);

	const URL = "https://api.wisdomlsi.com/api/v1/my_info/";
	const location = useLocation();
	const navigate = useNavigate();

	function goBack() {
		navigate(-1);
	}

	useEffect(() => {
		fetchMyInfo();
	}, []);

	function fetchMyInfo() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setInfo(response.data["info"]);

			if (response.data["info"]["student"]["gender"] === "male") {
				setGender("남성");
			} else {
				setGender("여성");
			}

			setGroupList(response.data["group_list"]);
			setBirth(response.data["info"]["student"]["birth"]);
			setPaymentHistory(response.data["payment_history"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}

	const moveToEditAccountInfo = () => {
		navigate("/edit_account_info", { state: {from: location.state.from, info: info }});
	}

	const moveToChangePw = () => {
		navigate("/change_pw", { state: {from: location.state.from, info: info }});
	}

	return (
		<div>
			<Header from={location.state.from} />
			<div className={styles.container}>
				<div className={styles.container_inside}>
					<div className={styles.top_menu}>
						<div className={styles.title_box}>
							<Settings className={styles.gear} />
							<p className={styles.title}>계정설정</p>
						</div>
						<Home className={styles.home} onClick={goBack} />
					</div>
					<div className={styles.section}>
						<div className={styles.subtitle_box}>
							<p className={styles.subtitle}>내 정보</p>
							<button className={styles.edit_btn} onClick={moveToEditAccountInfo}>편집</button>
							<button className={styles.change_pw_btn} onClick={moveToChangePw}>비밀번호 변경</button>
						</div>
						<div className={styles.info_section}>
							<p className={styles.info_title}>이름</p>
							<p className={styles.info}>{info && info.student.name}</p>
						</div>
						<div className={styles.info_section}>
							<p className={styles.info_title}>전화번호</p>
							<p className={styles.info}>{info && info.student.phone_number}</p>
						</div>
						<div className={styles.info_section}>
							<p className={styles.info_title}>이메일</p>
							<p className={styles.info}>{info && info.email}</p>
						</div>
						<div className={styles.info_section}>
							<p className={styles.info_title}>생년월일</p>
							<p className={styles.info}>{birth}</p>
						</div>
						<div className={styles.info_section}>
							<p className={styles.info_title}>성별</p>
							<p className={styles.info}>{gender}</p>
						</div>
						<div className={styles.info_section}>
							<p className={styles.info_title}>주소</p>
							<p className={styles.info}>{info && info.student.address}</p>
						</div>
					</div>
					<hr className={styles.devider} />
					<div className={styles.section}>
						<p className={styles.subtitle}>소속 그룹</p>
						{ groupList.length === 0 ?
							<p className={styles.group_empty}>아직 소속된 그룹이 없어요</p> : 
							<div>
								{ groupList.map((group) => (
									<p className={styles.my_group}>{group.name}</p>
								))}
							</div>
						}
					</div>
					<hr className={styles.devider} />
					<div className={styles.section}>
						<p className={styles.subtitle}>결제정보</p>
						<p className={styles.group_empty}>현재 구독결제 이용중입니다</p>
						{ paymentHistory && paymentHistory.map((history) => (
							<PaymentHistoryCell key={history.id} history={history} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountSetting;