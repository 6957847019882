import React, { useEffect, useState } from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    return (
        <>
            <p className={styles.text}>
                개인정보 취급방침<br/><br/>
                본 개인정보 보호정책은 주식회사 위즈덤센터가 운영하는 www.wisdomlsi.com인터넷 사이트(이하'사이트')서비스를 이용하는 고객의 개인정보 보호를 위해 마련된 것입니다.<br/>
                본 개인정보 보호정책은 정부의 법률 및 지침에 따라 변경 될 수 있으므로 수시로 그 내용을 확인하여 주시기 바랍니다.<br/><br/>
                1. 총 칙 <br/>
                (1) 주식회사 위즈덤센터(www.wisdomlsi.com이하 "센터"라 함)는 회원들의 개인정보보호를 매우 중요시하며, 회원이 센터의 서비스(이하 "서비스"라 함)를 이용함과 동시에 온라인상에서 센터에 제공한 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다. <br/>
                이에 센터는 "통신비밀보호법", "전기통신사업법", "정보통신망 이용촉진 등에 관한 법률" 등 정보통신서비스 제공자가 준수하여야 할 관련 법규 상의 개인정보보호 규정 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다. <br/>
                센터는 개인정보 보호정책을 통하여 회원들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. <br/>
                (2) 센터는 개인정보 보호정책을 홈페이지 첫 화면에 공개함으로써 회원들이 언제나 용이하게 볼 수 있도록 조치하고 있습니다. <br/>
                (3) 센터의 개인정보 보호정책은 정부의 법률 및 지침 변경이나 센터의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 보호정책의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 그리고 개인정보 보호정책을 개정하는 경우 센터는 그 변경사항에 대하여 즉시 홈페이지를 통하여 게시하고 개정된 사항을 회원들이 쉽게 알아볼 수 있도록 하고 있습니다. 회원들께서는 사이트 방문 시 수시로 확인하시기 바랍니다. <br/>
                2. 개인정보 수집에 대한 동의 <br/>
                센터는 회원들이 센터의 이용약관의 내용에 대하여 「동의」버튼 또는 「취소」버튼을 클릭할 수 있는 절차를 마련하여, 「동의」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. <br/><br/>
                3. 개인정보의 수집목적 및 이용목적 <br/>
                "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 이메일 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. <br/>
                일부 서비스는 별도의 사용자 등록이 없이 언제든지 사용할 수 있습니다. 그러나 센터는 회원들에게 맞춤 식 서비스를 비롯한 보다 더 향상된 양질의 서비스를 제공하기 위하여 회원 개인의 정보를 수집하고 있습니다. <br/>
                센터는 회원의 사전 동의 없이는 회원의 개인 정보를 함부로 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.<br/>
                (1) 서비스 제공을 위한 본인 확인을 위하여 <br/>
                (2) 신규 서비스개발이나 콘텐츠 확충을 고려시 회원들이 필요로 할 콘텐츠를 선택하여 제공하기 위하여 <br/>
                (3) 기타 새로운 서비스, 이벤트정보 안내를 위하여 <br/>
                각 수집 정보별 수집목적은 다음과 같습니다.<br/>
                (1) 아이디, 비밀번호, 이름, 성별, 생년월일, 휴대전화번호, 주소 : 서비스이용에 따른 본인식별, 연령제한 서비스의 제공과 본인의사확인, 불만처리 등 원활한 의사소통 경로의 확보, 새로운 서비스나 신상품, 이벤트 정보 등 최신 정보의 안내 <br/> <br/>
                4. 사이트가 수집하는 개인정보 항목 및 수집방법 <br/>
                센터는 회원들이 서비스를 이용하기 위해 회원으로 가입하실 때 서비스 제공을 위한 필수적인 정보들을 온라인 상에서 입력 받고 있습니다. 회원 가입 시에 받는 필수적인 정보는 이름, 주소, 전화번호, 이메일 주소 등입니다. <br/>
                만 14세 미만 어린이의 개인정보수집은 금지되어 있으나, 회원 가입시 최종적으로 부모님 동의가 있는 경우에는 가입 가능합니다. <br/>
                또한 사이트 내에서의 설문조사나 이벤트 행사시 통계분석이나 경품제공 등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다. 그러나, 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지 않습니다. <br/>
                그리고, 어떤 경우에라도 입력하신 정보를 회원들에게 사전에 밝힌 목적 이외에 다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다. <br/> <br/>
                5. 사이트가 수집하는 개인정보의 보유 및 이용기간  <br/>
                사이트의 회원으로서 센터에 제공하는 서비스를 이용하는 동안 센터는 회원들의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해 이용합니다. 다만, 아래의 "7. 회원 자신의 개인정보 관리(열람, 정정 등)에 관한 사항" 에서 설명한 절차와 방법에 따라 회원 본인이 직접 삭제하거나 수정한 정보, 가입 해지를 요청한 경우에는 재생할 수 없는 방법에 의하여 디스크에서 완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로 처리됩니다. <br/>
                그리고 "4. 사이트가 수집하는 개인정보 항목 및 수집방법"에서와 같이 일시적인 목적(설문조사, 이벤트 등)으로 입력 받은 개인정보는 그 목적이 달성된 이후에는 동일한 방법으로 사후 재생이 불가능한 상태로 처리됩니다. <br/>
                회원의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우 회원정보를 보관합니다. 그리고 상법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 센터는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 센터는 센터는 보관하는 정보를 그 보관의 목적으로만 이용합니다. <br/>
                - 계약 또는 청약철회 등에 관한 기록: 5년 <br/>
                - 대금결제 및 재화 등의 공급에 관한 기록: 5년 <br/>
                - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 <br/> <br/>
                6. 사이트가 수집한 개인정보의 공유 및 제공<br/> 
                센터는 회원들의 개인정보를 "3. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다. <br/>
                (1) 회원들이 사전에 공개에 동의한 경우  <br/>
                (2) 홈페이지에 게시한 사이트 서비스 이용 약관이나 기타 회원 서비스 등 이용약관 또는 정책을 위반한 경우 <br/>
                (3) 사이트 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우 <br/>
                (4) 관련법에 의거 적법한 절차에 의한 정부 또는 수사기관의 요청이 있는 경우 <br/>
                (5) 통계작성, 학술연구나 시장조사를 위하여 특정개인을 식별할 수 없는 형태로 협력업체나 연구단체 등에 제공하는 경우 <br/>
                (6) 회원의 서비스 이용에 따른 불만사항 및 문의사항(민원사항)의 처리를 위하여 10항의 고객센터를 운영하는 전문업체에 해당 민원사항의 처리에 필요한 개인정보를 제공하는 경우 <br/>
                그리고 센터는 새로운 기술개발이나 보다 나은 서비스의 제공을 위하여 회원들의 개인정보를 공유할 수 있습니다. 이 경우에도 정보수집 또는 정보제공 이전에 회원들에게 개인정보를 공유할 기관이나 단체가 누구인지, 어떤 정보가 왜 필요한지, 그리고 언제까지 어떻게 보호되고 관리되는지 알리고 동의를 구하는 절차를 거치게 되며, 회원들의 동의가 없는 경우에는 추가적인 정보를 임의로 수집하거나 공유하지 않습니다. <br/> <br/>
                7. 회원 자신의 개인정보 관리(열람, 정정 등)에 관한 사항 <br/>
                (1) 회원은 언제든지 본인의 개인정보 중 아이디, 이메일, 이름을 제외한 모든 입력사항을 조회, 수정할 수 있습니다. 개인정보의 열람과 수정은 서비스 내 [회원정보]에서 직접 변경할 수 있습니다. <br/>
                (2) 아이디나 비밀번호를 잊어버린 경우에는 회원 로그인 메뉴에 있는 "아이디/비밀번호 찾기"를 클릭하여 본인확인에 필요한 사항을 입력하면 해당 페이지에서 확인이 가능합니다. <br/>
                (3) 회원이 자신의 개인정보에 대한 정정을 요구하는 경우 센터는 본인 여부를 확인한 후 지체 없이 필요한 조치를 합니다. <br/> <br/>
                8. 개인정보 수집, 이용, 제공에 대한 동의 철회 <br/>
                (1) 회원 가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 회원이 동의한 내용은 언제든지 철회할 수 있습니다. 동의 철회는 개인정보 내에서 [회원탈퇴]버튼을 통하여 할 수 있습니다. 회원탈퇴를 할 경우, 회원의 개인정보는 모두 삭제됩니다. <br/>
                (2) 센터는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 사이트가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없습니다. <br/>
                (3) 회원탈퇴로 인하여 모든 서비스를 이용 수 없게 되는 것은 아니며, 로그인이 요구되는 일부 서비스에 대해서 그 기능을 이용할 수 없게 됩니다. <br/>
                (4) 회원이 자신의 개인정보에 대해 삭제를 요구하는 경우 센터는 본인 여부를 확인한 후 지체 없이 필요한 조치를 취합니다. 또한 이용약관 제 32조(계약해지 및 이용제한)의 각 호에 해당하는 경우 개인정보 책임자의 판단하에 개인정보를 파기, 회원ID 삭제, 서비스 정지 등을 할 수 있습니다. <br/> <br/>
                9. 쿠키의 사용 <br/>
                (1) 센터는 회원들에게 특화된 서비스를 제공하기 위하여 기술적으로 회원의 식별 정보를 이용할 수 있도록 쿠키를 사용할 수 있습니다. <br/>
                (2) 쿠키를 사용하는 서비스에 접속했을 때 당해 서비스를 운영하는 프로그램은, 자동으로 만들어 내는 쿠키를 회원의 컴퓨터로 전송해서 그의 하드 디스크에 저장해 둡니다. 이 내용은 필요할 때는 언제나 다시 불러서 필요한 만큼 변경한 다음, 다시 저장할 수 있습니다. <br/>
                (3) 쿠키는 주로 개인의 거주 지역이나 환경 등 취향에 맞는 맞춤형 서비스 제공을 위하여 필요합니다. 이런 개인화된 서비스를 이용하려면 항상 쿠키를 허용하도록 브라우저를 처리해 두어야 합니다. <br/>
                그러나 회원은 브라우저 옵션을 설정함으로써 쿠키의 허용을 거부할 수도 있습니다. <br/>
                다만, 쿠키의 허용을 거부할 경우에는 쿠키가 필요한 일부 서비스는 이용할 수 없습니다. <br/> <br/>
                10. 쿠키의 설정 등 <br/>
                센터는 각 개인의 환경에 적절한 서비스를 제공하기 위하여 쿠키를 사용합니다. <br/>
                서비스에서 쿠키를 이용하는 경우는 다음과 같습니다. <br/> <br/>
                (1) 센터는 회원 및 미등록 사용자들의 사용빈도의 차이나 반복 사용정도 등 사용자의 이용행동에 관한 정보를 위하여 쿠키를 사용합니다. <br/>
                (2) 쿠키는 센터가 진행하는 각종 이벤트나 프로모션 행사 등에서 개인정보를 확인하고 참가자의 참여 빈도수나 이후 당첨 등을 위하여 사용됩니다. <br/>
                (3) 쿠키는 회원의 방문 형태 및 총체적인 방문 형태 측정을 위해서도 사용됩니다. 회원의 습관이나 이용방식 등에 대한 정보 수집을 통하여 더욱 나은 서비스를 만들어 가는데 기초 자료로 활용합니다. 또한 이런 정보들은 배너 및 프로모션 행사가 사용자에게 필요한 것들로 구성될 수 있도록 하는데 사용되기도 합니다. <br/><br/>
                11. 개인정보보호를 위한 기술적-관리적 대책  <br/>
                센터는 회원들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다. <br/>
                (1) 기술적 대책 <br/>
                ① 회원의 비밀번호는 암호화 처리되어 운영자도 회원 개개인의 비밀번호를 알 수 없도록 되어 있습니다. <br/>
                회원 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원 여러분께서는 비밀번호를 누구에게도 알려주시면 안됩니다. 이를 위해 센터에서는 기본적으로 PC에서의 사용을 마치신 후 온라인상에서 로그아웃(LOG-OUT) 하시고 웹브라우저를 종료하도록 권장합니다. 특히 다른 사람과 PC를 공유하여 사용하거나 공공장소 (센터나 학교, 도서관, 인터넷 게임방 등)에서 이용한 경우에는 개인정보가 다른 사람에게 알려지는 것을 막기 위해 위와 같은 절차가 더욱 권장합니다.  <br/>
                ② 센터는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 회원들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, SSL 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.  <br/>
                그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다. <br/>
                ③ 센터는 스팸메일로 일어나는 회원의 피해를 막기 위해 스팸메일 차단 기술을 도입하고 있습니다. 이는 일정 수 이상의 동일한 메일이 발송되거나 제목에 "광고", "성인광고" 등의 단어가 포함되면 스팸메일로 자동 인식합니다. <br/>
                이 외에 회원이 직접 스팸메일을 차단할 수 있는 방법은 해당 메일의 내용 중 수신거부 버튼을 이용한 방법이 있을 수 있습니다. 만일 이러한 경우로도 해결이 안 될 경우 해당 아이디에 대한 차단(블랙리스트 추가)을 센터에 요구할 수 있습니다. <br/>
                (2) 관리적 대책 <br/>
                ① 센터의 개인정보관련 취급 직원은 담당자에 한정시켜 개인정보에 대한 접근 권한을 제한하고 있습니다. <br/>
                ② 개인정보를 취급하는 담당자를 대상으로 수시로 사이트 개인정보 보호정책의 준수와 개인정보 보호 의무 등에 관해 수시로 보안교육을 실시하고 있습니다. <br/>
                ③ 개인정보 취급담당자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지고 있으며, 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확하게 하고 있습니다. <br/> <br/>
                센터는 회원의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있습니다. <br/>
                센터는 회원 본인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해서는 책임을 지지 않습니다. 회원은 본인의 개인정보를 보호하기 위해 본인의 회원 ID와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다. <br/> <br/>
                12. 개인정보관련 의견수렴 및 불만처리에 관한 사항 <br/>
                센터는 개인정보보호와 관련하여 회원 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 회원들은 하단에 명시한 "15. 사이트 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처"항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, 센터는 회원들의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다.  <br/>
                또는 정부에서 설치하여 운영 중인 개인정보침해신고센터(http://www.cyberprivacy.or.kr, 전화 02-1336), 정보보호마크인증위원회(www.privacymark.or.kr,02-580-0533), 개인정보분쟁조정위원회(http://www.kopico.or.kr, 전화 02-405-474702-405-4747), 경찰청(www.police.go.kr)에 불만처리를 신청할 수 있습니다. <br/><br/>
                13. 어린이 정보보호에 관한 사항 <br/>
                현행법상 만14세 미만의 어린이들은 온라인으로 타인에게 개인정보를 보내기 전에 반드시 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 법정대리인의 동의를 받아야 합니다. 이에 센터는 서비스 이용규칙 등을 통하여 위 사항을 설명하고 있으며 가입 시에는 반드시 법정대리인 동의를 받아야 가입이 완료됩니다. 센터는 법정대리인에게 전자우편, 전화, fax, e-mail 등을 통하여 동의의사를 구한 후 이를 전자우편으로 회신 토록 하고 있습니다. 수집된 아동의 법정대리인의 개인정보는 동의여부를 확인하는 용도로만 사용할 것입니다. 만14세 미만 어린이의 법정대리인은 어린이의 개인정보 열람, 정정, 동의철회를 요청할 수 있으며, 이러한 요청이 있을 경우 센터는 지체 없이 필요한 조치를 취합니다.  <br/> <br/>
                14. 사이트 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처 <br/>
                사이트는 회원이 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 회원에게 고지한 사항들에 반하는 사고가 발생할 경우 개인정보관리책임자가 책임을 집니다. <br/>
                회원 개인정보와 관련한 아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 회원 자신에게 있습니다. check.wisdomcenter.co.kr에서는 비밀번호에 대해 어떠한 방법으로도 회원에게 직접적으로 질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다. 특히 "9. 개인정보관련 기술적-관리적 대책" 항에서 명시한 것과 같이 공공장소에서 온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다. check.wisdomcenter.co.kr은 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자 및 담당자를 지정하고 있고, 연락처는 아래와 같습니다. <br/>
                개인 정보 관리 책임자 <br/><br/>
                - 이은주 <br/>
                - 전화번호: 02 6207 7430 <br/>
                - E-Mail: wisdomcenter@naver.com <br/> <br/>
                15. 고지의 의무 <br/>
                개인정보보호정책의 내용추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.  <br/> <br/>
                [부칙] <br/>
                제1조 시행일 본 정책은 2023년 5월 15일부터 시행합니다. <br/>
                위즈덤센터 개인정보 취급방침에 동의함(필수) <br/>
            </p>
        </>
    );
}

export default PrivacyPolicy;