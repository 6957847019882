import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LogIn from "./components/LogIn"
import NotFound from "./components/NotFound"
import Footer from "./components/Footer"
import Student from './components/Student';
import Teacher from './components/Teacher';
import Expert from './components/Expert';
import Supervisor from './components/Supervisor';
import SubscribeTest from './components/SubscribeTest';
import RequestConsulting from './components/RequestConsulting';
import Test from './components/Test'
import TestResult from './components/TestResult'
import ScrollToTop from './components/ScrollToTop';
import RequestCoaching from './components/RequestCoaching';
import MakeProfile from './components/MakeProfile';
import NormalSignUp from './components/NormalSignUp';
import GroupSignUp from './components/GroupSignUp';
import Group from './components/Group';
import PayTest from './components/PayTest';
import AccountSetting from './components/AccountSetting';
import ExpertStudent from './components/ExpertStudent';
import KakaoLogIn from './components/KakaoLogIn';
import AddCoaching from './components/AddCoaching'
import Coaching from './components/Coaching';
import PaySubscribe from './components/PaySubscribe';
import OnlyTestList from './components/OnlyTestList';
import MyOnlyTestList from './components/MyOnlyTestList';
import PrivacyPolicy from './components/PrivacyPolicy';
import Service from './components/Service';
import TestResultExpert from './components/TestResultExpert';
import MakePDF from './components/MakePDF';
import EditAccountInfo from './components/EditAccountInfo';
import ChangePw from './components/ChangePw';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/student" element={
            <div><Student /><Footer id="footer" /></div>}></Route>

          <Route path="/teacher" element={
            <div><Teacher /><Footer id="footer" /></div>}></Route>

          <Route path="/expert" element={
            <div><Expert /><Footer id="footer" /></div>}></Route>

          <Route path="/expert/student" element={
            <div><ExpertStudent /><Footer id="footer" /></div>}></Route>

          <Route path="/supervisor" element={
            <div><Supervisor /><Footer id="footer" /></div>}></Route>

          <Route path="/" element={
            <div><LogIn /><Footer id="footer" /></div>}></Route>

          <Route path="/subscribe_test" element={
            <div><SubscribeTest /><Footer id="footer" /></div>}></Route>

          <Route path="/request_consulting" element={
            <div><RequestConsulting /><Footer id="footer" /></div>}></Route>

          <Route path="/test" element={
            <div><Test /><Footer id="footer" /></div>}></Route>

          <Route path="/test_result" element={
            <div><TestResult /><Footer id="footer" /></div>}></Route>

          <Route path="/expert/test_result/:paymentHistoryId/:testClassId" element={
            <div><TestResultExpert /><Footer id="footer" /></div>}></Route>

          <Route path="/add_coaching" element={
            <div><AddCoaching /><Footer id="footer" /></div>}></Route>

          <Route path="/request_coaching" element={
            <div><RequestCoaching /><Footer id="footer" /></div>}></Route>

          <Route path="/make_profile" element={
            <div><MakeProfile /><Footer id="footer" /></div>}></Route>

          <Route path="/normal_signup" element={
            <div><NormalSignUp /><Footer id="footer" /></div>}></Route>

          <Route path="/group_signup" element={
            <div><GroupSignUp /><Footer id="footer" /></div>}></Route>

          <Route path="/group" element={
            <div><Group /><Footer id="footer" /></div>}></Route>

          <Route path="/pay_test" element={
            <div><PayTest /><Footer id="footer" /></div>}></Route>

          <Route path="/account_setting" element={
            <div><AccountSetting /><Footer id="footer" /></div>}></Route>

          <Route path="/edit_account_info" element={
            <div><EditAccountInfo /><Footer id="footer" /></div>}></Route>

          <Route path="/change_pw" element={
            <div><ChangePw /><Footer id="footer" /></div>}></Route>

          <Route path="/oauth" element={
            <div><KakaoLogIn /><Footer id="footer" /></div>}></Route>

          <Route path="/coaching" element={
            <div><Coaching /><Footer id="footer" /></div>}></Route>

          <Route path="/only_test_list" element={
            <div><OnlyTestList /><Footer id="footer" /></div>}></Route>

          <Route path="/pay_subscribe" element={
            <div><PaySubscribe /><Footer id="footer" /></div>}></Route>

          <Route path="/my_only_test_list" element={
            <div><MyOnlyTestList /><Footer id="footer" /></div>}></Route>

          <Route path="/privacy_policy" element={
            <div><PrivacyPolicy /><Footer id="footer" /></div>}></Route>

          <Route path="/service" element={
            <div><Service /><Footer id="footer" /></div>}></Route>

          <Route path="/make_pdf" element={<MakePDF />}></Route>

          <Route path="*" element={
            <div><NotFound /><Footer id="footer" /></div>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
