import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./DeleteInvitationModal.module.css"
import axios from 'axios';

const DeleteInvitationModal= (props) => {
	const DELETE_USER_URL = "https://api.wisdomlsi.com/api/v1/group/delete_user/"
    const navigate = useNavigate();

	function deleteUser() {
		const params = {
			"student_id": props.student.id
		}

		axios({
			method: 'delete',
			url: DELETE_USER_URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
				alert("회원이 그룹에서 제외되었습니다");
                props.modalControl();
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.student.name} 회원님을</p>
				<p className={styles.modal_title}>그룹에서 제외시킬까요?</p>
				<p className={styles.modal_desc}>앞으로 해당 회원의 정보는 열람할 수 없습니다.</p>
                <button className={styles.accept_btn} onClick={deleteUser}>삭제하기</button>
                <button className={styles.cancel_btn} onClick={props.modalControl}>취소</button>
			</div>
		</div>
	);
};

export default DeleteInvitationModal;