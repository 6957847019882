import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./ConnectStudentWithTeacherModal.module.css"
import axios from 'axios';
import StudentConnectCell from './StudentConnectCell';

let studentIdList = [];

const ConnectStudentWithTeacherModal = (props) => {
    const URL = "https://api.wisdomlsi.com/api/v1/group/teacher/connect_student/";

    function connectStudent() {
        if (studentIdList.length === 0) {
            alert("연결하실 회원을 선택해주세요.");
        } else {
            const param = {
                "student_id_list": studentIdList,
                "teacher_id": props.teacher.teacher.id
            }

            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                if (response.data["code"] === "success") {
                    studentIdList = [];
                    props.modalControl();
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

    function selectStudent(student) {
        let isSelected = false;
        let selectedIndex = -1;

        for (let i=0; i<studentIdList.length; i++) {
            if (studentIdList[i] === student.student.id) {
                isSelected = true;
                selectedIndex = i;
                break;
            }
        }

        if (isSelected) {
            studentIdList.splice(selectedIndex, 1);
        } else {
            studentIdList.push(student.student.id);
        }
    }

    function cancel() {
        studentIdList = [];
        props.modalControl();
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.teacher.info.name} 지도자님과<br/>연결할 회원을 선택해주세요</p>
                <div className={styles.scroll}>
                    {props.studentList.map((student) => (
                        <StudentConnectCell key={student.student.id} student={student} selectAction={selectStudent}/>
                    ))}
                </div>
                <div className={styles.btn_group}>
                    <button className={styles.invite_btn} onClick={connectStudent}>연결하기</button>
                    <p className={styles.space}></p>
                    <button className={styles.cancel_btn} onClick={cancel}>취소</button>
                </div>
			</div>
		</div>
	);
};

export default ConnectStudentWithTeacherModal;