import React, { useState, useEffect } from 'react';
import Header from './Header'
import styles from './SubscribeTest.module.css'
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const SubscribeTest = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/subscribe_month/"

    const navigate = useNavigate();
	const location = useLocation();
	const [studentName, setStudentName] = useState(null);
	const [coachingStatus, setCoachingStatus] = useState(null);
	const [coachingHint, setCoachingHint] = useState(null);
	const [coachingBtnText, setCoachingBtnText] = useState(null);
	const [dateString, setDateString] = useState(null);
	const [btnStatus1, setBtnStatus1] = useState(null);
	const [btnStatus2, setBtnStatus2] = useState(null);
	const [btnStatus3, setBtnStatus3] = useState(null);
	const [btnStatus4, setBtnStatus4] = useState(null);
	const [btnString1, setBtnString1] = useState(null);
	const [btnString2, setBtnString2] = useState(null);
	const [btnString3, setBtnString3] = useState(null);
	const [btnString4, setBtnString4] = useState(null);

	useEffect(() => {
		const params = {
			"payment_history_id": location.state.paymentHistoryId,
			"for": location.state.from
		}

		axios({
			method: 'get',
			url: URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setStudentName(response.data["info"]["student_name"]);

			const year = response.data["info"]["year"];
			const now = new Date();

			if (now.getFullYear() === year) {
				setDateString(response.data["info"]["month"] + "월");
			} else {
				setDateString(year + "년 " + response.data["info"]["month"] + "월");
			}

			const testClass = response.data["test_class"];

			setBtnStatus1(testClass["1"]["status"]);
			setBtnString1(testClass["1"]["btn_text"]);
			setBtnStatus2(testClass["2"]["status"]);
			setBtnString2(testClass["2"]["btn_text"]);
			setBtnStatus3(testClass["3"]["status"]);
			setBtnString3(testClass["3"]["btn_text"]);
			setBtnStatus4(testClass["4"]["status"]);
			setBtnString4(testClass["4"]["btn_text"]);

			const coachingStatus = response.data["coaching_status"];
			setCoachingStatus(coachingStatus);

			if (coachingStatus === "test_in_progress") {
				setCoachingHint("<필수검사를 먼저 진행해주세요>\n공부분석 = LSI공부, WPI현실, WPI이상\n운동분석 = LSI운동, WPI현실, WPI이상");
				setCoachingBtnText("분석 신청하기");
			} else if (coachingStatus === "coaching_in_progress" || coachingStatus === "coaching_waiting_approval" || coachingStatus === "coaching_rejected") {
				setCoachingHint("전문가의 분석이 진행중이에요");
				setCoachingBtnText("분석 요청됨");
			} else if (coachingStatus === "coaching_approved") { 
				setCoachingHint("전문가의 분석을 확인해보세요!");
				setCoachingBtnText("분석내용 보기");
			}
		})
		.catch(function(error) {
			if (error.response.status === 401) {
				navigate('/');
			}
		});
	}, [btnString3]);

	function goBack() {
		navigate(-1);
	}

	function moveToCoaching() {
		if (coachingStatus === "test_in_progress") {
			console.log("A")
        	navigate('/request_coaching', { state: {paymentHistoryId: location.state.paymentHistoryId, from: location.state.from, dateString: dateString}});
		} else if (coachingStatus === "coaching_in_progress" || coachingStatus === "coaching_waiting_approval" || coachingStatus === "coaching_approved" || coachingStatus === "coaching_rejected") {
        	navigate('/coaching', { state: {paymentHistoryId: location.state.paymentHistoryId, from: location.state.from, dateString: dateString}});
		}
	}

	return (
		<div className={styles.container}>
			{ location.state.from === "student" ? <Header from={location.state.from} account={"일반회원"} /> : null }
			{ location.state.from === "teacher" ? <Header from={location.state.from} account={"지도자"} /> : null }
			{ location.state.from === "group" ? <Header isGroup={true} /> : null }
			<div className={styles.top_menu} onClick={goBack}>
				<ArrowBackIosIcon className={styles.back_arrow} />
				<p className={styles.month}>{studentName}님 {dateString}</p>
			</div>
			{ coachingStatus && location.state.from === "student" ?
				<div>
					{ coachingStatus !== "no_condition" ?
						<div className={styles.coaching_outside}>
							<div className={styles.coaching_inside}>
								<p className={styles.request_coaching_text}>{coachingHint}</p>
								<div>
									<button className={styles.request_coaching_btn} onClick={moveToCoaching}>{coachingBtnText}</button> 
								</div>
							</div>
						</div> :
						<div className={styles.coaching_outside}>
							<div className={styles.coaching_inside}>
								<p className={styles.request_coaching_text}>{"<필수검사를 먼저 진행해주세요>\n공부분석 = LSI공부, WPI현실, WPI이상\n운동분석 = LSI운동, WPI현실, WPI이상"}</p>
								<div>
									<button className={styles.request_coaching_btn_deactive}>{"분석신청"}</button> 
								</div>
							</div>
						</div>
					}
				</div> : null
			}
			{ coachingStatus && location.state.from === "teacher" || location.state.from === "group" ?
				<div>
					{ coachingStatus !== "no_condition" && coachingStatus !== "test_in_progress" ?
						<div className={styles.coaching_outside}>
							<div className={styles.coaching_inside}>
								<p className={styles.request_coaching_text}>{coachingHint}</p>
								<div>
									<button className={styles.request_coaching_btn} onClick={moveToCoaching}>{coachingBtnText}</button> 
								</div>
							</div>
						</div> : null
					}
				</div> : null
			}
			<div className={styles.top}>
				<div className={styles.title_box}>
					<p className={styles.title}>WPI</p>
					<p className={styles.subtitle}>Whang's Personality Inventory</p>
				</div>
				<div className={styles.card_container}>
					<div className={styles.card}>
						<p className={styles.card_title}>현실</p>
						<p className={styles.card_subtitle}>한 사람의 성격특성과 라이프스타일까지<br/>정확하게 알려주는 마음의 MRI</p>
						{location.state.from === "student" ?
							<div>
								{btnStatus1 == "before_test" ?
									<Link to={'/test'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 1}} className={styles.card_action}>{btnString1}</Link> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 1, from: "student"}} className={styles.card_action}>{btnString1}</Link>
								}
							</div> : null
						} 
						{location.state.from === "teacher" || location.state.from === "group" ?
							<div>
								{btnStatus1 == "before_test" ?
									<p className={styles.card_action_deactive}>{btnString1}</p> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 1, from: location.state.from }} className={styles.card_action}>{btnString1}</Link>
								}
							</div> : null
						}
					</div>
					<div className={styles.card}>
						<p className={styles.card_title}>이상</p>
						<p className={styles.card_subtitle}>내가 되고싶은 사람의 성격특성과<br/>라이프스타일까지 알려주는 MRI</p>
						{location.state.from === "student" ?
							<div>
								{btnStatus2 == "before_test" ?
									<Link to={'/test'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 2}} className={styles.card_action}>{btnString2}</Link> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 2, from: "student"}} className={styles.card_action}>{btnString2}</Link>
								}
							</div> : null
						} 
						{location.state.from === "teacher" || location.state.from === "group" ?
							<div>
								{btnStatus2 == "before_test" ?
									<p className={styles.card_action_deactive}>{btnString2}</p> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 2, from: location.state.from }} className={styles.card_action}>{btnString2}</Link>
								}
							</div> : null
						}

					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.title_box}>
					<p className={styles.title}>LSI</p>
					<p className={styles.subtitle}>Learning Style Indicator</p>
				</div>
				<div className={styles.card_container}>
					<div className={styles.card}>
						<p className={styles.card_title}>공부법</p>
						<p className={styles.card_subtitle}>나의 공부법 유형은<br/>무엇일까?</p>
						{location.state.from === "student" ?
							<div>
								{btnStatus3 == "before_test" ?
									<Link to={'/test'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 3}} className={styles.card_action}>{btnString3}</Link> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 3, from: "student"}} className={styles.card_action}>{btnString3}</Link>
								}
							</div> : null
						} 
						{location.state.from === "teacher" || location.state.from === "group" ?
							<div>
								{btnStatus3 == "before_test" ?
									<p className={styles.card_action_deactive}>{btnString3}</p> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 3, from: location.state.from }} className={styles.card_action}>{btnString3}</Link>
								}
							</div> : null
						}
					</div>
					<div className={styles.card}>
						<p className={styles.card_title}>운동법</p>
						<p className={styles.card_subtitle}>나의 운동법 유형은<br/>무엇일까?</p>
						{location.state.from === "student" ?
							<div>
								{btnStatus4 == "before_test" ?
									<Link to={'/test'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 4}} className={styles.card_action}>{btnString4}</Link> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 4, from: "student"}} className={styles.card_action}>{btnString4}</Link>
								}
							</div> : null
						} 
						{location.state.from === "teacher" || location.state.from === "group" ?
							<div>
								{btnStatus4 == "before_test" ?
									<p className={styles.card_action_deactive}>{btnString4}</p> :
									<Link to={'/test_result'} state={{paymentHistoryId: location.state.paymentHistoryId, testClassId: 4, from: location.state.from }} className={styles.card_action}>{btnString4}</Link>
								}
							</div> : null
						}
					</div>
					{/* <div className={styles.card}>
						<p className={styles.card_title}>예술</p>
						<p className={styles.card_subtitle}>내가 되고싶은 사람의 성격특성과<br/>라이프스타일까지 알려주는 MRI</p>
						<Link to='/test' className={styles.card_action}>검사하기</Link>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default SubscribeTest;