import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./OnlyTestCell.module.css"
import axios from 'axios';

const OnlyTestCell = (props) => {
    const navigate = useNavigate()
    const [testClassName, setTestClassName] = useState(null);
    const [testTime, setTestTime] = useState(null);

	useEffect(() => {
        if (props.test.test_class === 1) {
            setTestClassName("WPI현실");
        } else if (props.test.test_class === 2) {
            setTestClassName("WPI이상");
        } else if (props.test.test_class === 3) {
            setTestClassName("LSI공부");
        } else if (props.test.test_class === 4) {
            setTestClassName("LSI운동");
        }

        setTestTime(props.test.test_time.split("T")[0]);
	}, []);

	return (
        <div>
            <div className={styles.container}>
                <div className={styles.info}>
                    <div className={styles.coupon_info_group}>
                        <p className={styles.test_class}>[{testClassName}]</p>
                        <p className={styles.test_time}>{testTime}</p>
                    </div>
                    <button className={styles.result_btn} onClick={()=>props.clickAction(props.test)}>결과보기</button>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default OnlyTestCell;