import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./AddFeedbackModal.module.css"
import axios from 'axios';

const AddFeedbackModal = (props) => {
    const feedbackRef = useRef("");
    const URL = "https://api.wisdomlsi.com/api/v1/add_feedback/";

    const navigate = useNavigate();

    function addFeedback() {
        if (feedbackRef.current.value === "") {
            alert("피드백을 입력해주세요.");
        } else {
            const param = {
                "feedback": feedbackRef.current.value,
                "payment_history_id": props.paymentHistoryId,
                "from": props.from
            }

            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                console.log(response.data);
                
                if (response.data["code"] === "success") {
                    props.doneAction(props.coachingId);
                    props.dismiss();
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>피드백을 작성해주세요</p>
                <div className={styles.feedback_group}>
                    <textarea className={styles.feedback} ref={feedbackRef}></textarea>
                    <div className={styles.btn_group}>
                        <button className={styles.add_btn} onClick={addFeedback}>추가하기</button>
                        <button className={styles.cancel_btn} onClick={()=>props.modalAction()}>취소</button>
                    </div>
                </div>
			</div>
		</div>
	);
};

export default AddFeedbackModal;