import React, { useEffect, useState } from 'react';
import TestMonthCard from './TestMonthCard';
import styles from './TestList.module.css'

const TestList = (props) => {
	const [monthList, setMonthList] = useState([]);
	const [historyList, setHistoryList] = useState([]);

      useEffect(() => {
            setMonthList(props.monthList);
            setHistoryList(props.historyList);
      }, [props.monthList, props.historyList]);

      function getIsDone(monthId) {
            for (var i=0; i<historyList.length; i++) {
                  if (historyList[i]["payment_history"] === monthId) {
                        return true;
                  }
            }

            return false;
      }

	return (
		<div className={styles.container}>
                  {monthList && monthList.map((month) => (
                        <TestMonthCard key={month.id} paymentHistoryId={month.id}  date={month.payment_time} isDone={getIsDone(month.id)} from={props.from} />
                  ))}
		</div>
	);
};

export default TestList;