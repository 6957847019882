import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "./Header"
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import styles from "./Group.module.css"
import GroupStudentList from './GroupStudentList';
import GroupTeacherList from './GroupTeacherList';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupPref from './GroupPref';
import GroupTeacherStudentList from './GroupTeacherStudentList';
import GroupCouponList from './GroupCouponList';

const Group = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [isModalShow, setIsModalShow] = useState(false)

	return (
		<div>
			<Header isGroup={true} />
            <div className={styles.container}>
                <div className={styles.side_nav}>
                    <hr className={styles.devider} />
                    <div className={styles.tab} onClick={() => setSelectedIndex(0)}>
                        { selectedIndex === 0 ?
                            <div className={styles.active_back}>
                                <PersonIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>일반회원 목록</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <PersonIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>일반회원 목록</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(1)}>
                        { selectedIndex === 1 || selectedIndex === 100 ?
                            <div className={styles.active_back}>
                                <AssignmentIndIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>지도자 목록</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <AssignmentIndIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>지도자 목록</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(2)}>
                        { selectedIndex === 2 ?
                            <div className={styles.active_back}>
                                <AssignmentIndIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>쿠폰 목록</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <AssignmentIndIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>쿠폰 목록</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(3)}>
                        { selectedIndex === 3 ?
                            <div className={styles.active_back}>
                                <SettingsIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>그룹 설정</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <SettingsIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>그룹 설정</p>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.content_container}>
                    { selectedIndex === 0 ? <GroupStudentList /> : null }
                    { selectedIndex === 1 ? <GroupTeacherList indexAction={setSelectedIndex} teacherAction={setSelectedTeacher} /> : null }
                    { selectedIndex === 2 ? <GroupCouponList /> : null }
                    { selectedIndex === 3 ? <GroupPref /> : null }
                    { selectedIndex === 100 ? <GroupTeacherStudentList indexAction={setSelectedIndex} teacher={selectedTeacher} /> : null }
                </div>
            </div>
		</div>
	);
};

export default Group;