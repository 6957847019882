import React, { useEffect, useState } from 'react';
import styles from './PayTest.module.css';
import Header from './Header';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';

const PayTest = (props) => {
	const [selectedTestClass, setSelectedTestClass] = useState(null);
    const storeId = "store-cbc8f9ef-12e2-4212-8361-7d9b2e0520c7";
    const navigate = useNavigate();

    function requestPay() {
		if (!validate()) { return; }

        const IMP = window.IMP;
        IMP.init("imp77241360");

        IMP.request_pay({ 
            pg: "html5_inicis." + storeId,
            pay_method: "card",
            merchant_uid: "ORD20180131-0000011",
            name: "노르웨이 회전 의자",
            amount: 64900,
            buyer_email: "gildong@gmail.com",
            buyer_name: "홍길동",
            buyer_tel: "010-4242-4242",
            buyer_addr: "서울특별시 강남구 신사동",
            buyer_postcode: "01181"
        }, rsp => { 
            if (rsp.success) {
                alert("success");
            } else {
                alert(JSON.stringify(rsp));
            }
        });
    }

	function validate() {
		if (selectedTestClass === null) {
			alert("구매하기 위한 상품을 선택해주세요.");
			return false;
		} else {
			return true;
		}
	}

	function selectTestClass(testClassCode) {
		setSelectedTestClass(testClassCode);
		console.log(testClassCode);
	}

    function goBack() {
        navigate(-1);
    }

	return (
		<div>
            <Header account={"일반회원"} />
            <div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.back} onClick={goBack}>
                        <ArrowBackIos className={styles.back_arrow} />
                        <p>결제하기</p>
                    </div>
                    <div>
                        <p className={styles.title}>구매상품 목록</p>
						<p className={styles.desc}>구매 원하시는 상품을 선택해주세요</p>
						{ selectedTestClass === "wpi1" ?
							<div className={styles.active} onClick={()=>selectTestClass("wpi1")}>
								<div>
									<p className={styles.product_title}>WPI현실 검사</p>
								</div>
								<p className={styles.product_desc}>자신의 성격과 성향을 알수있는 심리 검사 입니다.</p>
								<p className={styles.money}>₩11,000</p>
							</div> :
							<div className={styles.deactive} onClick={()=>selectTestClass("wpi1")}>
								<div>
									<p className={styles.product_title}>WPI현실 검사</p>
								</div>
								<p className={styles.product_desc}>자신의 성격과 성향을 알수있는 심리 검사 입니다.</p>
								<p className={styles.money}>₩11,000</p>
							</div>
						} 
						{ selectedTestClass === "wpi2" ?
							<div className={styles.active} onClick={()=>selectTestClass("wpi2")}>
								<div>
									<p className={styles.product_title}>WPI이상 검사</p>
								</div>
                            	<p className={styles.product_desc}>자신이 이상적으로 생각하는 성격과 성향을 알수있는 심리 검사 입니다.</p>
								<p className={styles.money}>₩11,000</p>
							</div> :
							<div className={styles.deactive} onClick={()=>selectTestClass("wpi2")}>
								<div>
									<p className={styles.product_title}>WPI이상 검사</p>
								</div>
                            	<p className={styles.product_desc}>자신이 이상적으로 생각하는 성격과 성향을 알수있는 심리 검사 입니다.</p>
								<p className={styles.money}>₩11,000</p>
							</div>
						} 
						{ selectedTestClass === "study" ?
							<div className={styles.active} onClick={()=>selectTestClass("study")}>
								<div>
									<p className={styles.product_title}>LSI공부 검사</p>
								</div>
                            	<p className={styles.product_desc}>자신의 공부 스타일과 마음을 파악하고, 자신에게 맞는 공부방법을 찾는 심리 검사 입니다.</p>
								<p className={styles.money}>₩22,000</p>
							</div> :
							<div className={styles.deactive} onClick={()=>selectTestClass("study")}>
								<div>
									<p className={styles.product_title}>LSI공부 검사</p>
								</div>
                            	<p className={styles.product_desc}>자신의 공부 스타일과 마음을 파악하고, 자신에게 맞는 공부방법을 찾는 심리 검사 입니다.</p>
								<p className={styles.money}>₩22,000</p>
							</div>
						} 
						{ selectedTestClass === "exercise" ?
							<div className={styles.active} onClick={()=>selectTestClass("exercise")}>
								<div>
									<p className={styles.product_title}>LSI운동 검사</p>
								</div>
                            	<p className={styles.product_desc}>자신의 운동 스타일과 마음을 파악하고, 자신에게 맞는 운동방법을 찾는 심리 검사 입니다.</p>
								<p className={styles.money}>₩22,000</p>
							</div> :
							<div className={styles.deactive} onClick={()=>selectTestClass("exercise")}>
								<div>
									<p className={styles.product_title}>LSI운동 검사</p>
								</div>
                            	<p className={styles.product_desc}>자신의 운동 스타일과 마음을 파악하고, 자신에게 맞는 운동방법을 찾는 심리 검사 입니다.</p>
								<p className={styles.money}>₩22,000</p>
							</div>
						} 
                        <button className={styles.pay_btn} onClick={()=>requestPay()}>결제하기</button>
                    </div>
                </div>
            </div>
		</div>
	);
};

export default PayTest;