import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SelectAccountTypeModal from './SelectAccountTypeModal';
import SelectSignUpType from './SelectSignUpType';
import styles from "./LogIn.module.css"
import axios from 'axios';
import ChangeGroupPwModal from './ChangeGroupPwModal';

let willChangeGroupPwId = "";

const LogIn = (props) => {
    const EMAIL_EMPTY_MSG = "이메일을 입력해주세요";
    const PW_EMPTY_MSG = "비밀번호를 입력해주세요";

    const URL = "https://api.wisdomlsi.com/api/v1/login/";

    const TEST_URL = "https://api.wisdomlsi.com/api/v1/my_test/";
    const KAKAO_BASE_URL = "https://kauth.kakao.com/oauth/authorize";
    const KAKAO_REDIRECT_URL = "https://www.wisdomlsi.com/oauth";
    // const KAKAO_REDIRECT_URL = "http://localhost:3000/oauth";
    const KAKAO_REST_API_KEY = "9a0486d6a9b5f1527e2453efad1f51b6";
    const KAKAO_URL = KAKAO_BASE_URL + "?client_id=" + KAKAO_REST_API_KEY + "&redirect_uri=" + KAKAO_REDIRECT_URL + "&response_type=code";

    const navigate = useNavigate();

    const [username, setUsername] = useState(null);
    const [hasTeacher, setHasTeacher] = useState(false);
    const [hasExpert, setHasExpert] = useState(false);
    const [hasSupervisor, setHasSupervisor] = useState(false);
    const [isChangePwModalShow, setIsChangePwModalShow] = useState(false);
    const [isSignInModalShow, setIsSignInModalShow] = useState(false);
    const [isSignUpModalShow, setIsSignUpModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const showSignInModal = () => {
        return (
            setIsSignInModalShow(!isSignInModalShow)
        )
    }

    const showSignUpModal = () => {
        return (
            setIsSignUpModalShow(!isSignUpModalShow)
        )
    }

    function logIn() {
        if (!validate()) {
            return;
        }

        const param = {
            "email": emailRef.current.value,
            "password": passwordRef.current.value
        }

        axios({
            method: 'post',
            url: URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];

            if (code === "error") {
                setErrorMessage(response.data["message"]);
            } else {
                const data = response.data["data"];
                console.log(response.data);

                if (response.data["type"] === "group") {
                    if (response.data["group"]["status"] === "requested") {
                        alert("가입 승인대기중입니다.");
                    } else {
                        const accessToken = data["token"]["access"];
                        const refreshToken = data["token"]["refresh"];
                        window.localStorage.setItem("wisdom_access", accessToken);
                        window.localStorage.setItem("wisdom_refresh", refreshToken);

                        if (response.data["group"]["init_pw_changed"]) {
                            navigate('/group');
                        } else {
                            willChangeGroupPwId = response.data["group"]["id"];
                            setIsChangePwModalShow(true);
                        }
                    }
                } else {
                    const accessToken = data["token"]["access"];
                    const refreshToken = data["token"]["refresh"];
                    const hTeacher = data["has_teacher"];
                    const hExpert = data["has_expert"];
                    const hSupervisor = data["has_supervisor"];

                    window.localStorage.setItem("wisdom_username", data["name"]);
                    window.localStorage.setItem("wisdom_access", accessToken);
                    window.localStorage.setItem("wisdom_refresh", refreshToken);
                    
                    setUsername(data["name"]);
                    setHasTeacher(hTeacher);
                    setHasExpert(hExpert);
                    setHasSupervisor(hSupervisor);

                    if (!hTeacher && !hExpert && !hSupervisor) {
                        navigate('/student', {state: {"from": "student"}});
                    } else {
                        setIsSignInModalShow(true);
                    }
                }
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function validate() {
        if (emailRef.current.value === "") {
            setErrorMessage(EMAIL_EMPTY_MSG);
            return false;
        } else if (passwordRef.current.value === "") {
            setErrorMessage(PW_EMPTY_MSG);
            return false;
        } else {
            return true;
        }
    }

    function changeGroupPwDone(newPassword) {
        setIsChangePwModalShow(false);

        const param = {
            "email": emailRef.current.value,
            "password": newPassword
        }

        axios({
            method: 'post',
            url: URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            let code = response.data["code"];

            if (code === "error") {
                setErrorMessage(response.data["message"]);
            } else {
                const data = response.data["data"];
                console.log(response.data);

                const accessToken = data["token"]["access"];
                const refreshToken = data["token"]["refresh"];
                window.localStorage.setItem("wisdom_access", accessToken);
                window.localStorage.setItem("wisdom_refresh", refreshToken);

                navigate('/group');
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function test() {
        axios({
            method: 'post',
            url: TEST_URL,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            console.log(response.data);
        })
        .catch(function(error) {
            console.log(error);
        });
    }

	return (
        <div>
            {/* <button onClick={test}>test</button> */}
            <div className={styles.parent}>
                <div className={styles.left}>
                    <div className={styles.left_inside}>
                        <div className={styles.top}>
                            <h3 className={styles.title_1}>로그인</h3>
                            <p className={styles.title_2}>환영합니다!</p>
                        </div>
                        <div className={styles.middle}>
                            <input className={styles.main_input} type="email" placeholder='이메일' ref={emailRef}></input>
                            <input className={styles.main_input} type="password" placeholder='비밀번호' ref={passwordRef}></input>
                            <button className={styles.main_button} onClick={() => logIn()}>로그인</button>
                            { errorMessage != null ? <p className={styles.error_msg}>{errorMessage}</p> : null }
                        </div>
                        <div className={styles.bottom}>
                            <p className={styles.sign_up_hint}>아직 회원이 아니신가요?</p>
                            <button className={styles.sign_up_btn} onClick={showSignUpModal}>회원가입</button>
                        </div>
                        <hr className={styles.devider} />
                        <a href={KAKAO_URL} className={styles.social_btn}><img src="kakao_login.png"></img></a>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.right_inside}>
                        <img className={styles.logo} src={require('../wisdom_logo_big.png')}></img>
                        <h3 className={styles.brand_1}>위즈덤센터</h3>
                    </div>
                </div>
                { isSignInModalShow ? <SelectAccountTypeModal className={styles.modal} from="login" modalAction={showSignInModal} /> : "" }
                { isSignUpModalShow ? <SelectSignUpType className={styles.modal} /> : "" }
                { isChangePwModalShow ? <ChangeGroupPwModal groupId={willChangeGroupPwId} changePwDoneAction={changeGroupPwDone} /> : null }
            </div>
            <a target='_blank' href='http://wisdomcenter.co.kr/ ' className={styles.brand_info}>위스덤센터 소개</a>
        </div>
	);
};

export default LogIn;