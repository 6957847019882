import React, { useState, useEffect } from 'react';
import styles from './PaySubscribe.module.css';
import Header from './Header';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import axios from 'axios';


const PaySubscribe = (props) => {
    const ADD_PAY_INFO_URL = "https://api.wisdomlsi.com/api/v1/purchase_subscribe/"
    const URL = "https://api.wisdomlsi.com/api/v1/my_info/";
    const navigate = useNavigate();

    const [myInfo, setMyInfo] = useState(null);

	useEffect(() => {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
            setMyInfo(response.data);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
	}, []);

    function requestPay() {
        const price = 100;

        const storeId = "INIpayTest"; // TODO
        const IMP = window.IMP;
        IMP.init("imp77241360");

        IMP.request_pay({ 
            pg: "html5_inicis." + storeId,
            pay_method: "card",
            merchant_uid: (new Date()).toString(),
            name: "위즈덤센터 월간 심리코칭",
            amount: price,
            buyer_email: myInfo.info.email,
            buyer_name: myInfo.info.student.name,
            buyer_tel: myInfo.info.student.phone_number
        }, rsp => { 
            if (rsp.success) {
                addPayInfoToServer(rsp.imp_uid, price);
            } else {
                alert(rsp.error_msg);
            }
        });
    }

    function addPayInfoToServer(portOneId, price) {
		axios({
			method: 'post',
			url: ADD_PAY_INFO_URL,
            data: {
                "port_one_payment_id": portOneId,
                "price": price
            },
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
            navigate('/student', {state: {"from": "student"}});
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function goBack() {
		navigate("/student", { state: {from: "student"}});
    }

	return (
		<div>
            <Header from={"student"} account={"일반회원"} />
            <div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.back} onClick={goBack}>
                        <ArrowBackIos className={styles.back_arrow} />
                        <p>결제하기</p>
                    </div>
                    <div>
                        <p className={styles.title}>구매상품</p>
                        <div className={styles.product_group}>
                            <div>
                                <p className={styles.product_title}>월간 심리 코칭</p>
                            </div>
                            <p className={styles.product_desc}>공부, 운동, 일상 등 관심사와 각자의 고민 목표에 대해 자신을 점검해 볼수 있습니다.<br/>모든 심리 검사를 매달 할수 있습니다.<br/>심리상담 코칭 전문가가 해당 검사와 고민에 대해 분석지를 작성해드립니다.<br/>자신의 고민의 해법과 희망하는 목표를 이루는 좋은 방법과 코칭을 제공합니다.</p>
                        </div>
                        <div>
                            <p className={styles.pay_title}>월간 구독결제 금액</p>
                            <p className={styles.money}>₩50,000</p>
                        </div>
                        <button className={styles.pay_btn} onClick={()=>requestPay()}>결제하기</button>
                        <div className={styles.refund_group}>
                            <p className={styles.refund_title}>환불규정</p>
                            <p className={styles.refund_subtitle}>아래와 같은 사유에 대해 환불가능합니다</p>
                            <ul>
                                <p className={styles.refund_content}>1. 결제 해당월을 넘기지 않은 경우</p>
                                <p className={styles.refund_content}>2. 해당월의 검사를 사용하지 않은 경우</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
};

export default PaySubscribe;