import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import styles from './Coaching.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import FeedbackCell from '../components/FeedbackCell';
import AddFeedbackModal from '../components/AddFeedbackModal';
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import 'react-dropdown/style.css';
import WisdomGraph from './WisdomGraph';
import CoachingHistoryCell from './CoachingHistoryCell';

const Coaching = (props) => {
	const FETCH_TEST_RESULT_URL = "https://api.wisdomlsi.com/api/v1/expert/fetch_test_result/";
	const COACHING_URL = "https://api.wisdomlsi.com/api/v1/coaching/";
	const COACHING_HISTORY_URL = "https://api.wisdomlsi.com/api/v1/fetch_coaching_history/";
	const APPROVE_COACHING_URL = "https://api.wisdomlsi.com/api/v1/supervisor/approve_coaching/"
	const REJECT_COACHING_URL = "https://api.wisdomlsi.com/api/v1/supervisor/reject_coaching/"
	const FEEDBACK_URL = "https://api.wisdomlsi.com/api/v1/feedback/"
	const USER_INFO_URL = "https://api.wisdomlsi.com/api/v1/user_info/"

    const location = useLocation();
	const navigate = useNavigate();

    const rejectRef = useRef("");

	const [userInfo, setUserInfo] = useState(null);
	const [testResultList, setTestResultList] = useState(null);
	const [isCoachingProcessing, setIsCoachingProcessing] = useState(true);
	const [mainCoaching, setMainCoaching] = useState(null);
	const [coachingList, setCoachingList] = useState([]);
	const [coachingHistory, setCoachingHistory] = useState([]);
	const [expertListForHistory, setExpertListForHistory] = useState([]);
	const [story, setStory] = useState(null);
    const [isFeedbackModalShow, setIsFeedbackModalShow] = useState(false)
	const [feedbackList, setFeedbackList] = useState([]);
	const [latestCoachingTime, setLatestCoachingTime] = useState(null);
	const [resultTextList, setResultTextList] = useState([]);

	// Only for Supervisor 
	const [isConfirmShow, setIsConfirmShow] = useState(false);

    const showFeedbackModal= () => {
        return (
            setIsFeedbackModalShow(!isFeedbackModalShow)
        )
    }

    useEffect(() => {
		fetchCoaching();
		fetchUserInfo();
		fetchTestResult();
		fetchFeedbackList();
		fetchCoachingHistory();
    }, []);

    function fetchCoaching() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: COACHING_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

			setStory(response.data["story"]);
			setResultTextList(response.data["result_text_list"]);

			const coachings = response.data["coaching_list"];
			setCoachingList(coachings)

			if (coachings === undefined) {
				setIsCoachingProcessing(true);
			} else {
				// let mCoachingExist = false;
				// let mCoaching = null;

				// for (let coaching of coachings) {
				// 	if (coaching.is_approved || (!coaching.is_approved && !coaching.is_rejected)) {
				// 		mCoachingExist = true;
				// 		mCoaching = coaching;
				// 		setMainCoaching(coaching);
				// 	}
				// }

				// if (location.state.from === "student" || location.state.from === "teacher" || location.state.from === "group") {
				// 	console.log("newnewnew");
				// 	console.log(mCoachingExist);
				// 	console.log("newnewnew");
				// 	if (mCoachingExist) {
				// 		setIsCoachingProcessing(!mCoaching.is_approved);
				// 	} else {
				// 		setIsCoachingProcessing(false);
				// 	}
				// } else {
				// 	setIsCoachingProcessing(!mCoachingExist);
				// }

				let recentCoaching = coachings[coachings.length-1];
				setMainCoaching(recentCoaching);

				if (location.state.from === "student" || location.state.from === "teacher" || location.state.from === "group") {
					setIsCoachingProcessing(!recentCoaching.is_approved);
				} else {
					if (recentCoaching.is_approved) {
						setIsCoachingProcessing(false);
					} else {
						if (!recentCoaching.is_approved && !recentCoaching.is_rejected) {
							setIsCoachingProcessing(false);
						} else {
							setIsCoachingProcessing(true);
						}
					}
				}
			}

		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
    }

	function fetchUserInfo() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: USER_INFO_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setUserInfo(response.data);
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function fetchTestResult() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: FETCH_TEST_RESULT_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

			const tempList = new Array();

			for (let i=0; i<response.data["test_result"].length; i++) {
				tempList.push(parseTestResult(response.data["test_result"][i]));
			}

			setTestResultList(tempList);
			console.log(tempList);
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function fetchCoachingHistory() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: COACHING_HISTORY_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setCoachingHistory(response.data["coaching_list"]);
			setExpertListForHistory(response.data["expert_list"]);

			let latestCoaching = null;

			for (let co of response.data["coaching_list"]) {
				if (latestCoaching == null) {
					latestCoaching = co;
					continue;
				}

				if (latestCoaching.id < co.id) {
					latestCoaching = co;
				}
			}

			if (latestCoaching !== null) {
				setLatestCoachingTime(latestCoaching["created_time"]);
			}
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function parseTestResult(data) {
		let result = {};

		const questionGroupList = data["question_group"];
		const questionGroups = new Array();
		const pointList = data["result"];
		let points = new Array();

		for (let i=0; i<pointList.length/2; i++) {
			points.push(pointList[i].point);
		}

		questionGroups.push({
			name: questionGroupList[0].name,
			data: points
		})

		points = [];

		for (let i=pointList.length/2; i<pointList.length; i++) {
			points.push(pointList[i].point);
		}

		questionGroups.push({
			name: questionGroupList[1].name,
			data: points
		})

		result["testClass"] = data["test_class"];
		result["questionGroups"] = questionGroups
		result["categories"] = parseCategoryList(data);

		return result;
	} 

	function parseCategoryList(data) {
		const pointGroupList = data["result"];
		const wpiCategoryList = new Array();

		if (data["test_class"].code === "wpi") {
			for (let j=0; j<pointGroupList.length/2; j++) {
				const pointGroup = pointGroupList[j];
				const newPointGroup = pointGroupList[j+5];
				const category = pointGroup.question_category.title + "/" + newPointGroup.question_category.title
				wpiCategoryList.push(category);
			}
		}

		const categoryGroupList = new Array();
		let categoryList = new Array();

		for (let j=0; j<pointGroupList.length/2; j++) {
			const pointGroup = pointGroupList[j];
			categoryList.push(pointGroup.question_category.title);
		}

		categoryGroupList.push(categoryList);
		categoryList = [];

		for (let j=pointGroupList.length/2; j<pointGroupList.length; j++) {
			const pointGroup = pointGroupList[j];
			categoryList.push(pointGroup.question_category.title);
		}

		categoryGroupList.push(categoryList);

		return {
			"wpiCategoryList": wpiCategoryList,
			"categoryList": categoryGroupList
		};
	}

	function approveCoaching() {
		const params = {
			"coaching_id": mainCoaching.id,
			"reject_text": rejectRef.current.value
		}

		axios({
			method: 'post',
			url: APPROVE_COACHING_URL,
			data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			const code = response.data["code"];

			if (code === "success") {
				alert("분석이 승인되었습니다.");
			}

			navigate('/supervisor');
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function rejectCoaching() {
		if (rejectRef.current.value === "") {
			alert("반려 사유를 작성해주세요.");
			return;
		}

		const params = {
			"coaching_id": mainCoaching.id,
			"reject_text": rejectRef.current.value
		}

		axios({
			method: 'post',
			url: REJECT_COACHING_URL,
			data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			const code = response.data["code"];

			if (code === "success") {
				alert("분석이 반려되었습니다.");
			}

			navigate('/supervisor');
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function fetchFeedbackList() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: FEEDBACK_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

			if (response.data["code"] === "success") {
				setFeedbackList(response.data["feedback_list"]);
			}
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function goBack() {
		navigate(-1);
	}

	function savePDF() {
        navigate('/make_pdf', { state: { coaching: mainCoaching, testResultList: testResultList, resultTextList: resultTextList, userInfo: userInfo, coachingTime: latestCoachingTime, from: location.state.from }});
	}

	return (
		<div>
            { location.state.from === "student" ? <Header from={"student"} account={"일반회원"} /> : null }
            { location.state.from === "teacher" ? <Header from={"teacher"} account={"지도자"} /> : null }
            { location.state.from === "expert" ? <Header from={"expert"} account={"전문가"} /> : null }
            { location.state.from === "supervisor" ? <Header from={"supervisor"} account={"슈퍼바이저"} /> : null }
			<div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.coaching_box}>
						<div className={styles.top_menu} onClick={goBack}>
							<ArrowBackIosIcon className={styles.back_arrow} />
                        	<p className={styles.coaching_title}>{userInfo ? userInfo.student.name : null}{userInfo ? "(" + userInfo.email + ")" : null}님 {location.state.dateString} 분석결과</p>
						</div>
						{ mainCoaching && (location.state.from === "student" || location.state.from === "teacher" || location.state.from === "group") && mainCoaching.is_approved ?
							<div className={styles.save_pdf_box}>
								<button className={styles.save_pdf_button} onClick={savePDF}>분석결과 다운로드</button>
							</div> : null
						}
						<div className={styles.story_box}>
							<p className={styles.story_title}>작성된 사연</p>
							<p className={styles.story}>{story}</p>
						</div>
						<div>
							<p className={styles.test_title}>검사결과</p>
							{ testResultList && testResultList.map((testResult) => (
								<div className={styles.graph}>
									<p className={styles.test_result_title}>{testResult.testClass.name}</p>
									{ testResult.testClass && testResult.testClass.code === "wpi" ?
										<WisdomGraph 
											dataList={testResult.questionGroups} 
											categoryList={testResult.categories.wpiCategoryList}
											graph={"항목"}
											colors={[ '#ff2945', '#2965ff' ]}
										/> :
										<div>
											<WisdomGraph 
												dataList={[testResult.questionGroups[0]]} 
												categoryList={testResult.categories.categoryList[0]}
												graph={testResult.questionGroups.name}
												colors={[ '#ff2945' ]}
											/>
											<WisdomGraph 
												dataList={[testResult.questionGroups[1]]} 
												categoryList={testResult.categories.categoryList[1]}
												graph={testResult.questionGroups.name}
												colors={[ '#2965ff' ]}
											/>
										</div>
									}
								</div>
							))}
						</div>
						<div>
							{ isCoachingProcessing ?
								<p className={styles.wait_coaching}>아직 분석이 진행중이에요</p> :
								<div>
									<p className={styles.coaching_subtitle}>검사결과(그래프) 설명이에요</p>
									<p className={styles.coaching}>{mainCoaching && mainCoaching.content}</p>
									<p className={styles.coaching_subtitle}>회원님을 위한 코칭이에요</p>
									<p className={styles.coaching}>{mainCoaching && mainCoaching.to_student}</p>
									{ location.state.from !== "student" && !isCoachingProcessing ?
										<div>
											<p className={styles.coaching_subtitle}>지도자님께 남기는 말</p>
											<p className={styles.coaching}>{mainCoaching && mainCoaching.to_teacher}</p>
										</div> : null
									}
								</div>
							}
						</div>
                    </div>
                    { location.state.from === "supervisor" ?
                        <div>
                            { isCoachingProcessing ? null :
								<div>
									{ mainCoaching && mainCoaching.is_approved ? null :
										<div>
											<textarea ref={rejectRef} className={styles.coaching_input} placeholder='전문가님께 전달할 메시지를 작성해주세요.'></textarea>
											<div>
												<button className={styles.approve} onClick={approveCoaching}>승인하기</button>
												<button className={styles.reject} onClick={rejectCoaching}>반려하기</button>
											</div>
										</div>
									}
								</div>
                            }
                        </div> : null
                    }
                    { location.state.from === "teacher" || location.state.from === "expert" ?
                        <div>
                            <div className={styles.feedback_group}>
                                <button className={styles.feedback_btn} onClick={()=>showFeedbackModal()}>피드백 추가</button>
                                { feedbackList && feedbackList.map((feedback) => (
                                    <FeedbackCell key={feedback.info.id} feedback={feedback} />
                                ))}
                            </div>
                        </div> : null
                    }
                    { isFeedbackModalShow ? <AddFeedbackModal modalAction={()=>setIsFeedbackModalShow(false)} from={location.state.from} paymentHistoryId={location.state.paymentHistoryId} doneAction={fetchFeedbackList} dismiss={setIsFeedbackModalShow}/> : null }
                </div>
			</div>
			{ location.state.from === "expert" || location.state.from === "supervisor" ?
				<div className={styles.container}>
					<div className={styles.container_inside} id={styles.coachingHistoryBox}>
						<p className={styles.coaching_feedback}>분석요청 기록</p>
						{ coachingHistory.length > 0 && expertListForHistory.length > 0 && coachingHistory.map((coaching) => (
							<CoachingHistoryCell coaching={coaching} expertList={expertListForHistory} />
						))}
					</div>
				</div> : null
			}
		</div>
	);
};

export default Coaching;