import React, { useState } from 'react';
import styles from './TestOption.module.css';

const TestOption = (props) => {
	return (
		<div className={styles.container}>
            <div className={styles.question_box}>
                <p className={styles.quetion_text}>{props.order+1}.</p>
                <p className={styles.quetion_text}>{props.question.content}</p>
            </div>
            <div className={styles.check_container}>
                { props.isDeactive1 ?
                    <input className={styles.check_box} type="checkbox" onClick={() => props.check(props.question, 0)} disabled="disabled"></input> :
                    <input className={styles.check_box} type="checkbox" onClick={() => props.check(props.question, 0)}></input>
                 }
                { props.isDeactive2 ?
                    <input className={styles.check_box} type="checkbox" onClick={() => props.check(props.question, 1)} disabled="disabled"></input> :
                    <input className={styles.check_box} type="checkbox" onClick={() => props.check(props.question, 1)}></input>
                 }
                { props.isDeactive3 ?
                    <input className={styles.check_box} type="checkbox" onClick={() => props.check(props.question, 2)} disabled="disabled"></input> :
                    <input className={styles.check_box} type="checkbox" onClick={() => props.check(props.question, 2)}></input>
                 }
            </div>
		</div>
	);
};

export default TestOption;