import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./DisconnectStudentWithTeacherModal.module.css"
import axios from 'axios';

const DisconnectStudentWithTeacherModal = (props) => {
	const DELETE_URL = "https://api.wisdomlsi.com/api/v1/group/teacher/disconnect_student/"
    const navigate = useNavigate();

	console.log(props);

	function disconnectStudent() {
		const params = {
			"student_id": props.student.info.id,
			"teacher_id": props.teacher.teacher.id
		}

		axios({
			method: 'delete',
			url: DELETE_URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
                props.modalControl();
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>[{props.student.info.name} 회원님] == [{props.teacher.info.name} 지도자님]</p>
				<p className={styles.modal_title}>이 연결을 해제할까요?</p>
				<p className={styles.modal_desc}>지도자는 더이상 이 회원의 정보를 열람할 수 없습니다.</p>
                <button className={styles.accept_btn} onClick={disconnectStudent}>연결해제</button>
                <button className={styles.cancel_btn} onClick={props.modalControl}>취소</button>
			</div>
		</div>
	);
};

export default DisconnectStudentWithTeacherModal;