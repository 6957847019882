import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./GroupPref.module.css"
import axios from 'axios';

const GroupPref = (props) => {
        const URL = "https://api.wisdomlsi.com/api/v1/group/info/";
        const navigate = useNavigate();

        const [email, setEmail] = useState(null);
        const [group, setGroup] = useState(null);

	useEffect(() => {
                fetchInfo();
	}, []);
        
        function fetchInfo() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
                        console.log(response.data);
                        setEmail(response.data["data"]["email"]);
                        setGroup(response.data["data"]["group"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
        }

        return (
                <div className={styles.container}>
                        <p className={styles.title}>그룹정보</p>
                        <div className={styles.section}>
                                <p className={styles.option_title}>업체명</p>
                                <p className={styles.option_content}>{group && group.name}</p>
                        </div>
                        <div className={styles.section}>
                                <p className={styles.option_title}>이메일</p>
                                <p className={styles.option_content}>{email}</p>
                        </div>
                        <div className={styles.section}>
                                <p className={styles.option_title}>전화번호</p>
                                <p className={styles.option_content}>{group && group.phone_number}</p>
                        </div>
                        <div className={styles.section}>
                                <p className={styles.option_title}>주소</p>
                                <p className={styles.option_content}>{group && group.address}</p>
                        </div>
                        <div className={styles.section}>
                                <p className={styles.option_title}>지도과목</p>
                                <p className={styles.option_content}>{group && group.subjects}</p>
                        </div>
                        <div className={styles.section}>
                                <p className={styles.option_title}>정원</p>
                                <p className={styles.option_content}>{group && group.num_people}</p>
                        </div>
                </div>
        );
};

export default GroupPref;