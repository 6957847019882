import React from 'react';
import styles from './NotFound.module.css';

const NotFound = (props) => {
	return (
		<div>
			<h3 className={styles.not_found_msg}>요청하신 페이지를 찾을 수 없습니다</h3>
		</div>
	);
};

export default NotFound;