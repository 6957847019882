import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import styles from './AddCoaching.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import WisdomGraph from './WisdomGraph'
import axios from 'axios';
import 'react-dropdown/style.css';
import CoachingHistoryCell from './CoachingHistoryCell';

const AddCoaching = (props) => {
	const FETCH_TEST_RESULT_URL = "https://api.wisdomlsi.com/api/v1/expert/fetch_test_result/";
	const ADD_COACHING_URL = "https://api.wisdomlsi.com/api/v1/expert/add_coaching/";
	const COACHING_HISTORY_URL = "https://api.wisdomlsi.com/api/v1/fetch_coaching_history/";

    const location = useLocation();
	const navigate = useNavigate();

	const [testResultList, setTestResultList] = useState([]);
	const [coachingHistory, setCoachingHistory] = useState([]);
	const [expertList, setExpertList] = useState([]);
    const coachingContentRef = useRef("");
    const coachingToStudentRef = useRef("");
    const coachingToTeacherRef = useRef("");

	function validateCoaching() {
		if (coachingContentRef.current.value === "") {
			alert("분석내용을 입력해주세요");
			return false;
		} else if (coachingToStudentRef.current.value === "") {
			alert("유저에게 전달하실 내용을 입력해주세요");
			return false;
		} else if (coachingToTeacherRef.current.value === "") {
			alert("지도자에게 전달하실 내용을 입력해주세요");
			return false;
		}

		return true;
	}

    useEffect(() => {
		fetchTestResult();
		fetchCoachingHistory();
    }, []);

	function fetchTestResult() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: FETCH_TEST_RESULT_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);

			const tempList = new Array();

			for (let i=0; i<response.data["test_result"].length; i++) {
				tempList.push(parseTestResult(response.data["test_result"][i]));
			}

			setTestResultList(tempList);
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function fetchCoachingHistory() {
		const params = {
			"payment_history_id": location.state.paymentHistoryId
		}

		axios({
			method: 'get',
			url: COACHING_HISTORY_URL,
			params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
			setCoachingHistory(response.data["coaching_list"]);
			setExpertList(response.data["expert_list"]);
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function sendCoaching() {
		if (!validateCoaching()) {
			return;
		}

		const param = {
			"payment_history_id": location.state.paymentHistoryId,
			"content": coachingContentRef.current.value,
			"to_student": coachingToStudentRef.current.value,
			"to_teacher": coachingToTeacherRef.current.value
		}

		axios({
			method: 'post',
			url: ADD_COACHING_URL,
			data: JSON.stringify(param),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			const code = response.data["code"];

			if (code === "already_requested") {
				alert("이미 처리된 요청입니다.");
			} else if (code === "success") {
				alert("분석요청이 완료되었습니다.");
			}

			navigate('/expert');
		})
		.catch(function(error) {
			if (error.response.status === "401") {
				navigate('/');
			}
		});
	}

	function parseTestResult(data) {
		let result = {};

		const questionGroupList = data["question_group"];
		const questionGroups = new Array();
		const pointList = data["result"];
		let points = new Array();

		for (let i=0; i<pointList.length/2; i++) {
			points.push(pointList[i].point);
		}

		questionGroups.push({
			name: questionGroupList[0].name,
			data: points
		})

		points = [];

		for (let i=pointList.length/2; i<pointList.length; i++) {
			points.push(pointList[i].point);
		}

		questionGroups.push({
			name: questionGroupList[1].name,
			data: points
		})

		result["testClass"] = data["test_class"];
		result["questionGroups"] = questionGroups
		result["categories"] = parseCategoryList(data);

		return result;
	} 

	function parseCategoryList(data) {
		const pointGroupList = data["result"];
		const wpiCategoryList = new Array();

		if (data["test_class"].code === "wpi") {
			for (let j=0; j<pointGroupList.length/2; j++) {
				const pointGroup = pointGroupList[j];
				const newPointGroup = pointGroupList[j+5];
				const category = pointGroup.question_category.title + "/" + newPointGroup.question_category.title
				wpiCategoryList.push(category);
			}
		}

		const categoryGroupList = new Array();
		let categoryList = new Array();

		for (let j=0; j<pointGroupList.length/2; j++) {
			const pointGroup = pointGroupList[j];
			categoryList.push(pointGroup.question_category.title);
		}

		categoryGroupList.push(categoryList);
		categoryList = [];

		for (let j=pointGroupList.length/2; j<pointGroupList.length; j++) {
			const pointGroup = pointGroupList[j];
			categoryList.push(pointGroup.question_category.title);
		}

		categoryGroupList.push(categoryList);

		return {
			"wpiCategoryList": wpiCategoryList,
			"categoryList": categoryGroupList
		};
	}

	return (
		<div>
            { location.state.from === "expert" ? <Header from={"expert"} account={"전문가"} /> : null }
            { location.state.from === "supervisor" ? <Header from={"supervisor"} account={"슈퍼바이저"} /> : null }
			<div className={styles.container}>
				<div className={styles.container_inside}>
                    <div className={styles.coaching_title_group}>
                        <p className={styles.coaching_hint}>{location.state.studentName}님이 작성한 사연입니다.</p>
                        <p className={styles.student_story}>{location.state.story}</p>
                    </div>
                    <div className={styles.coaching_title_group}>
                        <p className={styles.coaching_hint}>{location.state.studentName}님의 검사결과 입니다.</p>
						{ testResultList && testResultList.map((testResult) => (
							<div className={styles.graph}>
								<p className={styles.test_result_title}>{testResult.testClass.name}</p>
								{ testResult.testClass && testResult.testClass.code === "wpi" ?
									<WisdomGraph 
										dataList={testResult.questionGroups} 
										categoryList={testResult.categories.wpiCategoryList}
										graph={"항목"}
										colors={[ '#ff2945', '#2965ff' ]}
									/> :
									<div>
										<WisdomGraph 
											dataList={[testResult.questionGroups[0]]} 
											categoryList={testResult.categories.categoryList[0]}
											graph={testResult.questionGroups.name}
											colors={[ '#ff2945' ]}
										/>
										<WisdomGraph 
											dataList={[testResult.questionGroups[1]]} 
											categoryList={testResult.categories.categoryList[1]}
											graph={testResult.questionGroups.name}
											colors={[ '#2965ff' ]}
										/>
									</div>
								}
							</div>
						))}
                    </div>
					{ location.state.from === "expert" ?
						<div>
							<p className={styles.coaching_write_hint}>(LSI, WPI) 프로파일 해석 작성</p>
							<textarea ref={coachingContentRef} className={styles.coaching_input}></textarea>
							<p className={styles.coaching_write_hint}>회원을 위한 코칭 작성</p>
							<textarea ref={coachingToStudentRef} className={styles.coaching_input}></textarea>
							<p className={styles.coaching_write_hint}>지도자(보호자)님께 전달할 메시지 작성</p>
							<textarea ref={coachingToTeacherRef} className={styles.coaching_input}></textarea>
							<div className={styles.done_box}>
								<button className={styles.done_btn} onClick={()=>sendCoaching()}>분석 제출</button>
							</div>
						</div> : null
					}
				</div>
			</div>
			{ coachingHistory.length > 0 ?
				<div className={styles.container}>
					<div className={styles.container_inside} id={styles.coachingHistoryBox}>
						<p className={styles.coaching_feedback}>분석요청 기록</p>
						{ coachingHistory.length > 0 && coachingHistory.map((coaching) => (
							<CoachingHistoryCell coaching={coaching} expertList={expertList} />
						))}
					</div>
				</div> : null
			}
		</div>
	);
};

export default AddCoaching;