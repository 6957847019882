import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./CouponCell.module.css"
import axios from 'axios';

const CouponCell = (props) => {
    const navigate = useNavigate()
    const [testClassName, setTestClassName] = useState(null);

	useEffect(() => {
        if (props.coupon.test_class_code === "wpi1") {
            setTestClassName("WPI현실");
        } else if (props.coupon.test_class_code === "wpi2") {
            setTestClassName("WPI이상");
        } else if (props.coupon.test_class_code === "study") {
            setTestClassName("LSI공부");
        } else if (props.coupon.test_class_code === "exercise") {
            setTestClassName("LSI운동");
        }
	}, []);

	return (
        <div>
            <div className={styles.container}>
                <div className={styles.info}>
                    <div className={styles.coupon_info_group}>
                        <p className={styles.test_class}>[{testClassName}]</p>
                        { props.coupon.status === "prepare" ?
                            <p className={styles.deactive}>미사용</p> :
                            <p className={styles.active}>사용됨</p>
                        }
                    </div>
                    <p className={styles.code}>{props.coupon.code}</p>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default CouponCell;