import React, { useState, useEffect } from 'react';
import styles from './Test.module.css'
import TestOption from './TestOption';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header'
import axios from 'axios';

const Test = (props) => {
    let EMPTY_MSG = "선택없음";
    let LESS_CHOICE_MSG = "최소\n1순위(3개), 2순위(4개), 3순위(5개)를\n선택해주세요";
    let HIDE_SCROLL_Y_POS = 500

    const [testClassName, setTestClassName] = useState(null);
    const [questionGroup1, setQuestionGroup1] = useState({"name": ""});
    const [questionGroup2, setQuestionGroup2] = useState(null);
    const [questionList1, setQuestionList1] = useState([]);
    const [questionList2, setQuestionList2] = useState([]);
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const [choiceString1, setChoiceString1] = useState(EMPTY_MSG);
    const [choiceString2, setChoiceString2] = useState(EMPTY_MSG);
    const [choiceString3, setChoiceString3] = useState(EMPTY_MSG);
    const [choiceString4, setChoiceString4] = useState(EMPTY_MSG);
    const [choiceString5, setChoiceString5] = useState(EMPTY_MSG);
    const [choiceString6, setChoiceString6] = useState(EMPTY_MSG);
    const [choiceList1, setChoiceList1] = useState([]);
    const [choiceList2, setChoiceList2] = useState([]);
    const [choiceList3, setChoiceList3] = useState([]);
    const [choiceList4, setChoiceList4] = useState([]);
    const [choiceList5, setChoiceList5] = useState([]);
    const [choiceList6, setChoiceList6] = useState([]);
    const [isBoardHide, setIsBoardHide] = useState(true);

	const location = useLocation();
    const navigate = useNavigate();
    const TEST_URL = "https://api.wisdomlsi.com/api/v1/test/"
    const QUESTIONS_URL = "https://api.wisdomlsi.com/api/v1/questions/"

    const checkQuestion1 = (question, priority) => {
        if (priority == 0) {
            var list = choiceList1

            if (list.includes(question)) {
                const index = list.indexOf(question);
                if (index > -1) { list.splice(index, 1); }
            } else {
                list.push(question);
            }

            setChoiceList1(list)

            if (list.length == 0) {
                setChoiceString1(EMPTY_MSG)
            } else {
                var result = ""

                for (var i=0; i<list.length; i++) {
                    result += list[i].order+1 + "번.  "
                }

                setChoiceString1(result)
            }
        }

        if (priority == 1) {
            var list = choiceList2

            if (list.includes(question)) {
                const index = list.indexOf(question);
                if (index > -1) { list.splice(index, 1); }
            } else {
                list.push(question);
            }

            setChoiceList2(list)

            if (list.length == 0) {
                setChoiceString2(EMPTY_MSG)
            } else {
                var result = ""

                for (var i=0; i<list.length; i++) {
                    result += list[i].order+1 + "번.  "
                }

                setChoiceString2(result)
            }
        }
        
        if (priority == 2) {
            var list = choiceList3

            if (list.includes(question)) {
                const index = list.indexOf(question);
                if (index > -1) { list.splice(index, 1); }
            } else {
                list.push(question);
            }

            setChoiceList3(list)

            if (list.length == 0) {
                setChoiceString3(EMPTY_MSG)
            } else {
                var result = ""

                for (var i=0; i<list.length; i++) {
                    result += list[i].order+1 + "번.  "
                }

                setChoiceString3(result)
            }
        }
    };

    const checkQuestion2 = (question, priority) => {
        if (priority == 0) {
            var list = choiceList4

            if (list.includes(question)) {
                const index = list.indexOf(question);
                if (index > -1) { list.splice(index, 1); }
            } else {
                list.push(question);
            }

            setChoiceList4(list)

            if (list.length == 0) {
                setChoiceString4(EMPTY_MSG)
            } else {
                var result = ""

                for (var i=0; i<list.length; i++) {
                    result += list[i].order+1 + "번.  "
                }

                setChoiceString4(result)
            }
        }

        if (priority == 1) {
            var list = choiceList5

            if (list.includes(question)) {
                const index = list.indexOf(question);
                if (index > -1) { list.splice(index, 1); }
            } else {
                list.push(question);
            }

            setChoiceList5(list)

            if (list.length == 0) {
                setChoiceString5(EMPTY_MSG)
            } else {
                var result = ""

                for (var i=0; i<list.length; i++) {
                    result += list[i].order+1 + "번.  "
                }

                setChoiceString5(result)
            }
        }
        
        if (priority == 2) {
            var list = choiceList6

            if (list.includes(question)) {
                const index = list.indexOf(question);
                if (index > -1) { list.splice(index, 1); }
            } else {
                list.push(question);
            }

            setChoiceList6(list)

            if (list.length == 0) {
                setChoiceString6(EMPTY_MSG)
            } else {
                var result = ""

                for (var i=0; i<list.length; i++) {
                    result += list[i].order+1 + "번.  "
                }

                setChoiceString6(result)
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll);};
    })

    const handleScroll = () => {
        setIsBoardHide(window.scrollY < HIDE_SCROLL_Y_POS)
    };

    useEffect(() => {
        fetchQuestionList();
	}, []);

    function fetchQuestionList() {
        const param = {
            "testClassId": location.state.testClassId
        }

		axios({
			method: 'get',
			url: QUESTIONS_URL,
            params: param,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
			console.log(response.data);
            setTestClassName(response.data["test_class"]["name"]);

            const questionGroups = response.data["question_groups"];
            setQuestionGroup1(questionGroups[0]);
            setQuestionGroup2(questionGroups[1]);

            for (var idx=0; idx<questionGroups.length; idx++) {
                const id = questionGroups[idx].id;

                if (idx == 0) {
                    setQuestionList1(response.data[id]);
                } else {
                    setQuestionList2(response.data[id]);
                }
            }

            setCurrentGroupIndex(0);
		})
		.catch(function(error) {
			if (error.response.status === 401) {
				navigate('/');
			}
		});
    }

    function changeToNextGroup() {
        if (currentGroupIndex == 0) {
            if (choiceList1.length < 3 || choiceList2.length < 4 || choiceList3.length < 5) {
                alert(LESS_CHOICE_MSG)
            } else {
                setCurrentGroupIndex(1)
                window.scroll(0, 0)
            }
        } else {
            if (choiceList4.length < 3 || choiceList5.length < 4 || choiceList6.length < 5) {
                alert(LESS_CHOICE_MSG)
            } else {
                const group1Id = questionGroup1.id
                const group2Id = questionGroup2.id

                const param = {
                    "payment_history_id": location.state.paymentHistoryId,
                    "test_class": questionGroup1.test_class,
                    "priority_7": choiceList1.concat(choiceList4),
                    "priority_5": choiceList2.concat(choiceList5),
                    "priority_3": choiceList3.concat(choiceList6)
                }

                axios({
                    method: 'post',
                    url: TEST_URL,
                    data: JSON.stringify(param),
                    headers: {
                        "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                        "Content-Type": "application/json; charset=utf-8"
                    }
                })
                .then(function(response) {
			        console.log(response.data);
                    const code = response.data["code"];

                    if (code === "already_tested") {
                        alert("이미 완료된 검사입니다.");
                        navigate('/subscribe_test', { state: {paymentHistoryId: location.state.paymentHistoryId}});
                    } else if (code === "success") {
                        navigate('/test_result', { state: {paymentHistoryId: location.state.paymentHistoryId, testClassId: location.state.testClassId, from: "student"}});
                    }
                })
                .catch(function(error) {
                    if (error.response.status === 401) {
                        alert("세션이 만료되었습니다.\n" + "로그인후 다시 이용해주세요.");
                    }
                });
            }
        }
    }

    function checkPriority(groupIndex, priority) {
        if (groupIndex === 0) {
            if (priority === 2) {
                return (choiceList1.length === 3)
            } else { // priority === 3
                return (choiceList1.length === 3) && (choiceList2.length === 4)
            }
        } else { // groupIndex === 1
            if (priority === 2) {
                return (choiceList4.length === 3)
            } else { // priority === 3
                return (choiceList4.length === 3) && (choiceList5.length === 4)
            }
        }
   }

	return (
		<div>
            <Header from={"student"} account={"일반회원"} />
            <div className={styles.container}>
                <div className={styles.container_inside}>
                    <div className={styles.title_box}>
                        <p className={styles.title}>{testClassName}</p>
                        <div className={styles.subtitle_box}>
                            { currentGroupIndex == 0 ?
                                <div className={styles.title_group}>
                                    <p className={styles.step}>Step 1.</p>
                                    <p className={styles.subtitle}>{questionGroup1["name"]}</p>
                                </div> :
                                <div className={styles.title_group}>
                                    <p className={styles.step}>Step 2.</p>
                                    <p className={styles.subtitle}>{questionGroup2["name"]}</p>
                                </div>
                            }
                        </div>
                        { currentGroupIndex == 0 ?
                            <div className={styles.desc_group}>
                                <p className={styles.desc}>{questionGroup1["desc"]}<br/><span>선택완료 후에는 뒤로가기가 불가능하오니 신중하게 응답해주시기 바랍니다.</span></p>
                                <p className={styles.desc_detail}>{questionGroup1.hint_1}<br/><br/>1순위 : 3가지 선택<br/>2순위 : 4가지 선택<br/>3순위 : 5가지 선택</p>
                            </div> :
                            <div className={styles.desc_group}>
                                <p className={styles.desc}>{questionGroup2["desc"]}<br/><span>선택완료 후에는 뒤로가기가 불가능하오니 신중하게 응답해주시기 바랍니다.</span></p>
                                <p className={styles.desc_detail}>{questionGroup2.hint_1}<br/><br/>1순위 : 3가지 선택<br/>2순위 : 4가지 선택<br/>3순위 : 5가지 선택</p>
                            </div>
                         }
                    </div>
                    <div className={styles.option_title}>
                        <p className={styles.level}>1순위</p>
                        <p className={styles.level}>2순위</p>
                        <p className={styles.level}>3순위</p>
                    </div>
                    <div className={styles.option_box}>
                        { currentGroupIndex === 0 ?
                            questionList1 && questionList1.map((question, idx) => (
                                <TestOption key={question.order} order={idx} question={question} check={checkQuestion1}
                                    isDeactive1={(choiceList1.length>=3 && !choiceList1.includes(question)) || choiceList2.includes(question) || choiceList3.includes(question)} 
                                    isDeactive2={(choiceList2.length>=4 && !choiceList2.includes(question)) || choiceList1.includes(question) || choiceList3.includes(question) || !checkPriority(0, 2)} 
                                    isDeactive3={(choiceList3.length>=5 && !choiceList3.includes(question)) || choiceList1.includes(question) || choiceList2.includes(question) || !checkPriority(0, 3)} />
                            ))  :
                            questionList2 && questionList2.map((question, idx) => (
                                <TestOption key={100+question.order} order={idx} question={question}  check={checkQuestion2}
                                    isDeactive1={(choiceList4.length>=3 && !choiceList4.includes(question)) || choiceList5.includes(question) || choiceList6.includes(question)} 
                                    isDeactive2={(choiceList5.length>=4 && !choiceList5.includes(question)) || choiceList4.includes(question) || choiceList6.includes(question) || !checkPriority(1, 2)} 
                                    isDeactive3={(choiceList6.length>=5 && !choiceList6.includes(question)) || choiceList4.includes(question) || choiceList5.includes(question) || !checkPriority(1, 3)} />
                            ))
                        }
                    </div>
                    <div className={styles.done_box}>
                        <button className={styles.done_btn} onClick={() => changeToNextGroup()}>선택완료</button>
                    </div>
                </div>
            </div>
            { !isBoardHide ?
                <div className={styles.float_board}>
                    <div className={styles.board_inside}>
                        <div>
                            { currentGroupIndex === 0 ?
                                <p className={styles.choice_title}>{questionGroup1.hint_2}</p> :
                                <p className={styles.choice_title}>{questionGroup2.hint_2}</p>
                            }
                        </div>
                        <hr className={styles.devider} />
                        <div className={styles.choice_group}>
                            <div className={styles.choice_group_inside}>
                                <p className={styles.choice_level}>1순위</p>
                                <p className={styles.choice_hint}>3개</p>
                            </div>
                            { currentGroupIndex == 0 ?
                                <p className={styles.choice}>{choiceString1}</p> :
                                <p className={styles.choice}>{choiceString4}</p>
                            }
                        </div>
                        <div className={styles.choice_group}>
                            <div className={styles.choice_group_inside}>
                                <p className={styles.choice_level}>2순위</p>
                                <p className={styles.choice_hint}>4개</p>
                            </div>
                            { currentGroupIndex == 0 ?
                                <p className={styles.choice}>{choiceString2}</p> :
                                <p className={styles.choice}>{choiceString5}</p>
                            }
                        </div>
                        <div className={styles.choice_group}>
                            <div className={styles.choice_group_inside}>
                                <p className={styles.choice_level}>3순위</p>
                                <p className={styles.choice_hint}>5개</p>
                            </div>
                            { currentGroupIndex == 0 ?
                                <p className={styles.choice}>{choiceString3}</p> :
                                <p className={styles.choice}>{choiceString6}</p>
                            }
                        </div>
                    </div>
                </div> : null
            }
		</div>
	);
};

export default Test;