import React from 'react';
import { Link } from 'react-router-dom';
import styles from './FeedbackCell.module.css'

const FeedbackCell = (props) => {
    const student = props.student

	return (
		<div>
            <div className={styles.container}>
                <div className={styles.main_group}>
                    <div className={styles.tag_box}>
                        <p className={styles.main_tag}>{props.feedback.writer.type}</p>
                    </div>
                    <div className={styles.info_group}>
                        <p className={styles.name}>{props.feedback.writer.name}</p>
                        <p className={styles.email}>{props.feedback.writer.email}</p>
                    </div>
                </div>
                <p className={styles.comment}>{props.feedback.info.content}</p>
            </div>
			<hr className={styles.devider} />
		</div>
	);
};

export default FeedbackCell;