import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import styles from './MakePDF.module.css';
import TopBanner from '../top_banner.png';
import TitleBanner from '../title_banner.png';
import WisdomGraph from './WisdomGraph';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ResultTemplate from '..//result_template.png';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const MakePDF = () => {
    const location = useLocation();

    const [age, setAge] = useState(null);

    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // const { print } = defaultLayoutPluginInstance.toolbarPluginInstance.printPluginInstance;
    // console.log(location.state);

    // function download() {
    //     const target = document.getElementById('page_pdf');

    //     html2canvas(target).then((canvas) => {
    //         const link = document.createElement('a');
    //         document.body.appendChild(link);
    //         link.href = canvas.toDataURL('image/png');
    //         link.download = 'result.png';
    //         link.click();
    //         document.body.removeChild(link);
    //     });
    // }

    // function download() {
    //     html2canvas(document.querySelector("#page_2")).then(canvas => {
    //         var imgData = canvas.toDataURL('image/jpeg');
    //         var imgWidth = 210; // 이미지 가로 길이(mm) A4 기준
    //         var pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
    //         var imgHeight = canvas.height * imgWidth / canvas.width;
    //         var heightLeft = imgHeight;
    //         var margin = 0;
          
    //         var doc = new jsPDF('p', 'mm', 'a4');
    //         var position = 0;
          
    //         // 첫 페이지 출력
    //         doc.addImage(imgData, 'jpeg', margin, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;
          
    //         // 한 페이지 이상일 경우 루프 돌면서 출력
    //         while (heightLeft >= 20) {
    //           position = heightLeft - imgHeight;
    //           doc.addPage();
    //           doc.addImage(imgData, 'jpeg', margin, position, imgWidth, imgHeight);
    //           heightLeft -= pageHeight;
    //         }
          
    //         // 파일 저장
    //         doc.save('sample.pdf');
    //       });
    // }

    useEffect(() => {
        const birth = new Date(location.state.userInfo.student.birth);
        setAge(new Date().getFullYear() - birth.getFullYear() + 1);

        setTimeout(() => {
            print();
        }, 1000);
    }, []);


    const getTextWPI = (testClassId) => {
        for (let resultText of location.state.resultTextList) {
            const result = resultText[testClassId];

            if (result !== undefined) {
                return result;
            }
        }
    }

    const getTextLSI = (testClassId, order) => {
        let check = false;

        for (let resultText of location.state.resultTextList) {
            const result = resultText[testClassId];

            if (result !== undefined) {
                if (order === 0) {
                    return result;
                } else {
                    if (check) {
                        return result;
                    } else {
                        check = true;
                        continue;
                    }
                }
            }
        }
    }

    return (
        <div className={styles.parent}>
            <div className={styles.parent_inner} id={"page_pdf"}>
                <div className={styles.cover}>
                    <img className={styles.cover_img} src={ResultTemplate} />

                    <div className={styles.student_info}>
                        <div className={styles.student_1}>
                            <p>{location.state.userInfo.student.name}님</p>
                            <p>({location.state.userInfo.student.gender === "male" ? "남" : "여"}/{age}세)</p>
                        </div>
                        <div className={styles.student_2}>
                            <p>소속: {location.state.userInfo.group === "empty" ? "없음" : location.state.userInfo.group}</p>
                            <p>분석: {location.state.coachingTime.split("T")[0]}</p>
                        </div>
                        { location.state.from === "teacher" || location.state.from === "group" ?
                            <div>
                                <p>1. 맞춤설명(LSI, WPI)</p>
                                <p>2. 회원을 위한 코칭</p>
                                <p>3. 지도를 위한 코칭</p>
                                <p>4. 기본설명(LSI, WPI)</p>
                            </div> :
                            <div>
                                <p>1. 맞춤설명(LSI, WPI)</p>
                                <p>2. 회원을 위한 코칭</p>
                                <p>3. 기본설명(LSI, WPI)</p>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.page} id={"page_2"}>
                    <div className={styles.top_banner_box}>
                        <img className={styles.top_banner} src={TopBanner} />
                        <p className={styles.top_title}>맞춤설명 (LSI, WPI)</p>
                    </div>
                    <div className={styles.title_banner_box}>
                        <img className={styles.title_banner} src={TitleBanner} />
                        <p className={styles.title}>LSI, WPI 프로파일</p>
                    </div>
                    { location.state.testResultList.map((testResult) => (
                        <div>
                            <div className={styles.graph_box}>
                                { testResult.testClass && testResult.testClass.code === "wpi" ?
                                    <div>
                                        <p className={styles.profile_title}>{testResult.testClass.id === 1 ? "WPI 현실" : "WPI 이상"}</p>
                                        <div className={styles.profile}>
                                            <WisdomGraph 
                                                className={styles.graph}
                                                dataList={testResult.questionGroups} 
                                                categoryList={testResult.categories.wpiCategoryList}
                                                graph={"항목"}
                                                colors={[ '#ff2945', '#2965ff' ]}
                                            />
                                        </div>
                                    </div> :
                                    <div className={styles.lsi_box}>
                                        <p className={styles.profile_title}>{testResult.testClass.id === 3 ? "LSI 공부법" : "LSI 운동법"}</p>
                                        <div className={styles.profile}>
                                            <WisdomGraph 
                                                className={styles.graph}
                                                dataList={[testResult.questionGroups[0]]} 
                                                categoryList={testResult.categories.categoryList[0]}
                                                graph={testResult.questionGroups.name}
                                                colors={[ '#ff2945' ]}
                                            />
                                        </div>
                                        <div className={styles.profile}>
                                            <WisdomGraph 
                                                className={styles.graph}
                                                dataList={[testResult.questionGroups[1]]} 
                                                categoryList={testResult.categories.categoryList[1]}
                                                graph={testResult.questionGroups.name}
                                                colors={[ '#2965ff' ]}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                    <div className={styles.title_banner_box}>
                        <img className={styles.title_banner} src={TitleBanner} />
                        <p className={styles.title}>맞춤설명</p>
                    </div>
                    <div className={styles.text_box}>
                        <p className={styles.coaching_text}>{location.state.coaching.content}</p>
                    </div>
                </div>
                <div className={styles.space}></div>
                <div className={styles.page} id={"page_3"}>
                    <div className={styles.top_banner_box}>
                        <img className={styles.top_banner} src={TopBanner} />
                        <p className={styles.top_title}>회원을 위한 코칭</p>
                    </div>
                    <div className={styles.title_banner_box}>
                        <img className={styles.title_banner} src={TitleBanner} />
                        <p className={styles.title}>나만을 위한 맞춤 코칭</p>
                    </div>
                    <div className={styles.text_box}>
                        <p className={styles.coaching_text}>{location.state.coaching.to_student}</p>
                    </div>
                </div>
                { location.state.from === "teacher" || location.state.from === "group" ?
                    <div>
                        <div className={styles.space}></div>
                        <div className={styles.page} id={"page_4"}>
                            <div className={styles.top_banner_box}>
                                <img className={styles.top_banner} src={TopBanner} />
                                <p className={styles.top_title}>지도를 위한 코칭</p>
                            </div>
                            <div className={styles.title_banner_box}>
                                <img className={styles.title_banner} src={TitleBanner} />
                                <p className={styles.title}>이렇게 지도해 주세요</p>
                            </div>
                            <div className={styles.text_box}>
                                <p className={styles.coaching_text}>{location.state.coaching.to_teacher}</p>
                            </div>
                        </div>
                    </div> : null
                }
                <div className={styles.space}></div>
                <div className={styles.page} id={"page_1"}>
                    <div className={styles.top_banner_box}>
                        <img className={styles.top_banner} src={TopBanner} />
                        <p className={styles.top_title}>기본설명 (LSI, WPI)</p>
                    </div>
                    { location.state.testResultList.map((testResult) => (
                        <div>
                            { testResult.testClass.id === 2 ? null :
                                <div>
                                    <div className={styles.title_banner_box}>
                                        <img className={styles.title_banner} src={TitleBanner} />
                                        <p className={styles.title}>{testResult.testClass.name}</p>
                                    </div>
                                    <div className={styles.graph_box}>
                                        { testResult.testClass && testResult.testClass.code === "wpi" ?
                                            <div>
                                                <div className={styles.only_graph_box}>
                                                    <WisdomGraph 
                                                        className={styles.graph}
                                                        dataList={testResult.questionGroups} 
                                                        categoryList={testResult.categories.wpiCategoryList}
                                                        graph={"항목"}
                                                        colors={[ '#ff2945', '#2965ff' ]}
                                                    />
                                                </div>
                                                <p className={styles.resultText}>{getTextWPI(1)}</p>
                                            </div> :
                                            <div>
                                                <div className={styles.only_graph_box}>
                                                    <WisdomGraph 
                                                        className={styles.graph}
                                                        dataList={[testResult.questionGroups[0]]} 
                                                        categoryList={testResult.categories.categoryList[0]}
                                                        graph={testResult.questionGroups.name}
                                                        colors={[ '#ff2945' ]}
                                                    />
                                                </div>
                                                <p className={styles.resultText}>{getTextLSI(testResult.testClass.id, 0)}</p>
                                                <div className={styles.only_graph_box}>
                                                    <WisdomGraph 
                                                        className={styles.graph}
                                                        dataList={[testResult.questionGroups[1]]} 
                                                        categoryList={testResult.categories.categoryList[1]}
                                                        graph={testResult.questionGroups.name}
                                                        colors={[ '#2965ff' ]}
                                                    />
                                                </div>
                                                <p className={styles.resultText}>{getTextLSI(testResult.testClass.id, 1)}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MakePDF;